import React from 'react'
import { Layout, ConfigProvider } from 'antd'
import ruRU from 'antd/lib/locale/ru_RU'

class AuthLayout extends React.Component<{}, {}> {
  render(): React.ReactElement {
    const { children } = this.props

    return (
      <ConfigProvider locale={ruRU}>
        <Layout
          style={{
            minHeight: '100vh',
          }}
        >
          {children}
        </Layout>
      </ConfigProvider>
    )
  }
}

export default AuthLayout
