import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { FranchiseeItemType } from './types'

export type InitialStateType = {
  isFetching: boolean
  items: FranchiseeItemType[]
  error: string | null
}

export type FetchFranchiseeSelectorPayload = string

export type FetchFranchiseeSelectorSuccessPayload = FranchiseeItemType

export type FetchFranchiseeSelectorFailurePayload = string | null

export type FetchFranchiseesListSelectorPayload = {
  search: string
}

export type FetchFranchiseesListSelectorSuccessPayload = {
  data: FranchiseeItemType[]
}

export type FetchFranchiseesListSelectorFailurePayload = string | null

const initialState: InitialStateType = {
  isFetching: false,
  items: [],
  error: null,
}

export const franchiseeSelectorSlice = createSlice({
  name: 'franchiseeSelector',
  initialState,
  reducers: {
    fetch(
      state: InitialStateType,
      _action: PayloadAction<FetchFranchiseesListSelectorPayload>,
    ): void {
      state.isFetching = true
    },
    fetchSuccess(
      state: InitialStateType,
      action: PayloadAction<FetchFranchiseesListSelectorSuccessPayload>,
    ): void {
      state.isFetching = false
      state.items = action.payload.data
    },
    fetchFailure(
      state: InitialStateType,
      action: PayloadAction<FetchFranchiseesListSelectorFailurePayload>,
    ): void {
      state.isFetching = false
      state.error = action.payload
    },
    fetchFranchisee(
      state: InitialStateType,
      _action: PayloadAction<FetchFranchiseeSelectorPayload>,
    ): void {
      state.isFetching = true
    },
    fetchFranchiseeSuccess(
      state: InitialStateType,
      action: PayloadAction<FetchFranchiseeSelectorSuccessPayload>,
    ): void {
      state.isFetching = false
      state.items = [action.payload]
    },
    fetchFranchiseeFailure(
      state: InitialStateType,
      action: PayloadAction<FetchFranchiseeSelectorFailurePayload>,
    ): void {
      state.isFetching = false
      state.error = action.payload
    },
    destroy(state: InitialStateType): void {
      state.items = []
    },
  },
})

export const actions = franchiseeSelectorSlice.actions

export default franchiseeSelectorSlice.reducer
