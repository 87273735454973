import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { Category } from '../../types/category'

export type InitialStateType = {
  isFetching: boolean
  items: Category[]
  error: string | null
}

export type FetchCategoriesSelectorSuccessPayload = Category[]
export type FetchCategoriesSelectorFailurePayload = string | null

const initialState: InitialStateType = {
  isFetching: false,
  items: [],
  error: null,
}

export const categorySelectorSlice = createSlice({
  name: 'categorySelector',
  initialState,
  reducers: {
    fetch(state: InitialStateType): void {
      state.isFetching = true
    },
    fetchSuccess(
      state: InitialStateType,
      action: PayloadAction<FetchCategoriesSelectorSuccessPayload>,
    ): void {
      state.isFetching = false
      state.items = action.payload
    },
    fetchFailure(
      state: InitialStateType,
      action: PayloadAction<FetchCategoriesSelectorFailurePayload>,
    ): void {
      state.isFetching = false
      state.error = action.payload
    },
  },
})

export const actions = categorySelectorSlice.actions

export default categorySelectorSlice.reducer
