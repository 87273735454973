import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import {
  InitialStateType,
  FetchAppVersionsPayload,
  FetchAppVersionsSuccessPayload,
  FetchAppVersionsFailurePayload,
  CreateVersionPayload,
  CreateVersionSuccessPayload,
  CreateVersionFailurePayload,
  SetModalPayload,
  EditVersionPayload,
  EditVersionSuccessPayload,
  EditVersionFailurePayload,
  RemoveVersionPayload,
  RemoveVersionSuccessPayload,
  RemoveVersionFailurePayload,
} from './types'
import { buildFieldErrors } from '../../utils/helpers'

const initialState: InitialStateType = {
  versions: [],
  meta: null,
  isFetching: false,
  isModal: false,
  selectedVersion: '',
  isProcessCreate: false,
  isProcessEdit: false,
  isProcessRemove: false,
  error: null,
  errorForm: null,
}

export const appVersionsSlice = createSlice({
  name: 'appVersions',
  initialState,
  reducers: {
    fetch(
      state: InitialStateType,
      _action: PayloadAction<FetchAppVersionsPayload>,
    ): void {
      state.isFetching = true
      state.error = null
    },
    fetchSuccess(
      state: InitialStateType,
      action: PayloadAction<FetchAppVersionsSuccessPayload>,
    ): void {
      state.versions = action.payload.data
      state.meta = action.payload.meta
      state.isFetching = false
    },
    fetchFailure(
      state: InitialStateType,
      action: PayloadAction<FetchAppVersionsFailurePayload>,
    ): void {
      state.isFetching = false
      state.error = action.payload
    },
    setModal(
      state: InitialStateType,
      action: PayloadAction<SetModalPayload>,
    ): void {
      state.isModal = action.payload.isModal
      state.selectedVersion = action.payload.selectedVersion || ''
    },
    create(
      state: InitialStateType,
      _action: PayloadAction<CreateVersionPayload>,
    ): void {
      state.isProcessCreate = true
      state.errorForm = null
    },
    createSuccess(
      state: InitialStateType,
      _action: PayloadAction<CreateVersionSuccessPayload>,
    ): void {
      state.isModal = false
      state.isProcessCreate = false
    },
    createFailure(
      state: InitialStateType,
      action: PayloadAction<CreateVersionFailurePayload>,
    ): void {
      state.isProcessCreate = false
      state.errorForm = buildFieldErrors(action.payload)
    },
    edit(
      state: InitialStateType,
      _action: PayloadAction<EditVersionPayload>,
    ): void {
      state.isProcessEdit = true
      state.errorForm = null
    },
    editSuccess(
      state: InitialStateType,
      _action: PayloadAction<EditVersionSuccessPayload>,
    ): void {
      state.isModal = false
      state.isProcessEdit = false
    },
    editFailure(
      state: InitialStateType,
      action: PayloadAction<EditVersionFailurePayload>,
    ): void {
      state.isProcessEdit = false
      state.errorForm = buildFieldErrors(action.payload)
    },
    remove(
      state: InitialStateType,
      _action: PayloadAction<RemoveVersionPayload>,
    ): void {
      state.isProcessRemove = true
    },
    removeSuccess(
      state: InitialStateType,
      _action: PayloadAction<RemoveVersionSuccessPayload>,
    ): void {
      state.isProcessRemove = false
    },
    removeFailure(
      state: InitialStateType,
      action: PayloadAction<RemoveVersionFailurePayload>,
    ): void {
      state.isProcessRemove = false
      state.error = action.payload
    },
  },
})

export const actions = appVersionsSlice.actions

export default appVersionsSlice.reducer
