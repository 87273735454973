import React, { CSSProperties } from 'react'
import { Menu } from 'antd'
import {
  AppstoreOutlined,
  BarChartOutlined,
  BarcodeOutlined,
  BranchesOutlined,
  CaretRightOutlined,
  CheckCircleOutlined,
  CompassOutlined,
  DashboardOutlined,
  FolderOpenOutlined,
  HighlightOutlined,
  InsertRowLeftOutlined,
  MailOutlined,
  PlusOutlined,
  QuestionCircleOutlined,
  ReadOutlined,
  SettingOutlined,
  StarOutlined,
  StopOutlined,
  SwapOutlined,
  UserOutlined,
  TagsOutlined,
} from '@ant-design/icons'
import { useRouter } from 'next/router'
import { Router } from 'src/routes'
import { useDispatch, useSelector } from 'react-redux'
import { InitialStateType } from '../../containers/profile/slice'
import { actions as actionsSearch } from '../../containers/companySearchForm/slice'
import { actions as actionsCompanies } from '../../containers/companies/slice'

const { SubMenu } = Menu

const routes = [
  {
    title: 'Дашборд',
    path: '/',
    icon: <DashboardOutlined />,
    role: ['franchisee'],
  },
  { title: 'Пользователи', path: '/users', icon: <UserOutlined /> },
  { title: 'Компании', path: '/companies', icon: <CompassOutlined /> },
  {
    title: 'Предложения',
    path: 'https://bo-new.mamado.su/events',
    icon: <TagsOutlined />,
  },
  {
    title: 'Категории',
    path: '/categories',
    icon: <FolderOpenOutlined />,
    role: ['admin', 'admin_ro'],
  },
  {
    title: 'Особенности',
    path: '/features',
    icon: <StarOutlined />,
    role: ['admin', 'admin_ro'],
  },
  {
    title: 'Франчайзи',
    path: '/franchisee',
    icon: <BarChartOutlined />,
    role: ['admin', 'admin_ro'],
  },
  {
    title: 'Промокоды',
    path: '/promocodes',
    icon: <BarcodeOutlined />,
    role: ['admin'],
  },
  {
    title: 'Отчеты',
    path: '/reports',
    icon: <ReadOutlined />,
    role: ['admin', 'admin_ro'],
    routes: [
      {
        title: 'по франчайзи',
        path: '/reports/franchisee',
        icon: <CaretRightOutlined />,
        disabled: false,
      },
    ],
  },
  {
    title: 'Интеграции',
    path: '/integrations',
    icon: <SwapOutlined />,
    role: ['franchisee'],
  },
  {
    title: 'Настройки приложения',
    path: '/settings-app',
    icon: <SettingOutlined />,
    role: ['admin', 'admin_ro'],
    routes: [
      {
        title: 'Блоки',
        path: '/settings-app/blocks',
        icon: <AppstoreOutlined />,
        disabled: false,
      },
      {
        title: 'Баннеры',
        path: '/settings-app/banners',
        icon: <InsertRowLeftOutlined />,
        disabled: false,
      },
      {
        title: 'Контент',
        path: '/settings-app/content',
        icon: <HighlightOutlined />,
        role: ['admin', 'admin_ro'],
        disabled: false,
      },
      {
        title: 'Версии приложения',
        path: '/settings-app/versions',
        icon: <BranchesOutlined />,
        disabled: false,
      },
    ],
  },
  {
    title: 'Рассылки',
    path: '/newsletters',
    icon: <MailOutlined />,
    role: ['admin', 'admin_ro'],
    routes: [
      {
        title: 'Создать',
        path: '/newsletters/create',
        icon: <PlusOutlined />,
        disabled: false,
      },
      {
        title: 'Активные',
        path: '/newsletters/active',
        icon: <CheckCircleOutlined />,
        disabled: false,
      },
      {
        title: 'Неактивные',
        path: '/newsletters/inactive',
        icon: <StopOutlined />,
        disabled: false,
      },
      {
        title: 'Задания',
        path: '/newsletters/tasks',
        icon: <QuestionCircleOutlined />,
        disabled: false,
      },
    ],
  },
]

type MenuPropsType = {
  style?: CSSProperties
  closeDrawer: () => void
}
const MenuComponent: React.FC<MenuPropsType> = ({
  style = null,
  closeDrawer,
}) => {
  const router = useRouter()
  const dispatch = useDispatch()
  const { profile } = useSelector(
    (state: { profile: InitialStateType }) => state.profile,
  )

  if (!profile) return null

  const currentPath = router.asPath
  const selectedKeys: string[] = [
    ...routes
      .filter((route) => currentPath.startsWith(route.path))
      .map((route) => {
        if (currentPath !== route.path) {
          if (route.routes) {
            const subPath = route.routes.find((sub) =>
              currentPath.includes(sub.path),
            )?.path
            return subPath || route.path
          }
        }
        return route.path
      })
      .filter((route) => currentPath === '/' || route !== '/'),
  ]

  const refreshPageCompanies = (path: string): void => {
    if (path === '/companies' && router.route === '/companies') {
      dispatch(actionsSearch.destroy())
      dispatch(actionsCompanies.fetchCompanies())
    }
    Router.pushRoute(path)
  }

  return (
    <Menu
      theme={'dark'}
      mode={'inline'}
      selectedKeys={selectedKeys}
      style={{ ...style, padding: '16px 0' }}
      onClick={({ key }): void => {
        closeDrawer()
        refreshPageCompanies(key.toString())
      }}
    >
      {routes
        .filter((route) => {
          if (route.role) {
            return profile.roles.some((role) => route.role.includes(role))
          } else {
            return true
          }
        })
        .map((route) => {
          if (route.routes) {
            return (
              <SubMenu key={route.path} icon={route.icon} title={route.title}>
                {route.routes.map(
                  (sub): JSX.Element => (
                    <Menu.Item
                      disabled={sub.disabled}
                      icon={sub.icon}
                      key={sub.path}
                    >
                      {sub.title}
                    </Menu.Item>
                  ),
                )}
              </SubMenu>
            )
          } else {
            return (
              <Menu.Item key={route.path} icon={route.icon}>
                {route.title}
              </Menu.Item>
            )
          }
        })}
    </Menu>
  )
}
export default MenuComponent
