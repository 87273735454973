import { AxiosResponse } from 'axios'
import { get, post, patch, del } from '../../infrastructure/api'
import { FormItemsType } from './types'

export const fetchPromocodes = (page: number): Promise<AxiosResponse> =>
  get('/admin/promocodes', {
    page,
    include: 'businessAccount',
  })

export const createPromocode = (data: FormItemsType): Promise<AxiosResponse> =>
  post('/admin/promocodes', {}, data)

export const editPromocode = (
  promocodeId: string,
  data: FormItemsType,
): Promise<AxiosResponse> => patch(`/admin/promocodes/${promocodeId}`, {}, data)

export const removePromocode = (promocodeId: string): Promise<AxiosResponse> =>
  del(`/admin/promocodes/${promocodeId}`)
