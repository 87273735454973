import React, { useEffect } from 'react'
import rtl from 'jss-rtl'
import Head from 'next/head'
import { create, Plugin } from 'jss'
import Error from 'next/error'
import { Provider, useDispatch } from 'react-redux'
import { jssPreset, StylesProvider } from '@material-ui/core/styles'
import { PersistGate } from 'redux-persist/integration/react'
import Router, { withRouter } from 'next/router'
import NProgress from 'nprogress'

import '../styles.css'
import { persistor, store } from '../store'
import '../../public/static/index.css'
import Layout from '../components/Layout'
import AuthLayout from '../components/AuthLayout'
import { actions } from '../containers/profile/slice'
import { getToken } from '../services/Token.service'
import { SWRConfig } from 'swr'
import api from '../utils/api'

const plugins: ReadonlyArray<Plugin> = [...jssPreset().plugins, rtl()]
const jss = create({ plugins })

const config = {
  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  fetcher: (url: string) => api.get(url).then((res) => res.data),
}

// UI loading top bar
NProgress.configure({ showSpinner: false })
Router.events.on('routeChangeStart', () => NProgress.start())
Router.events.on('routeChangeComplete', () => NProgress.done())
Router.events.on('routeChangeError', () => NProgress.done())

function Redirect(): JSX.Element {
  useEffect(() => {
    Router.replace('/login')
  }, [])
  return <></>
}

// TODO: Types!
function RouterBrowser({
  Component,
  pageProps,
  collapsed,
  router,
}: {
  Component: any
  pageProps: any
  collapsed: boolean
  router: any
}): JSX.Element {
  const dispatch = useDispatch()
  const token = getToken()

  useEffect(() => {
    if (token) {
      dispatch(actions.fetchProfile())
    }
  }, [])

  switch (router.pathname) {
    case '/_error':
      return <Error statusCode={404} />
    case '/login':
      return (
        <AuthLayout>
          <Component {...pageProps} />
        </AuthLayout>
      )
    default:
      return token ? (
        <Layout collapsed={collapsed}>
          <SWRConfig value={config}>
            <Component {...pageProps} />
          </SWRConfig>
        </Layout>
      ) : (
        <Redirect />
      )
  }
}

function App({ Component, pageProps, collapsed, router }: any): JSX.Element {
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <StylesProvider jss={jss}>
          <Head>
            <title>{process.env.NEXT_PUBLIC_APP_NAME}</title>
          </Head>
          <RouterBrowser
            Component={Component}
            pageProps={pageProps}
            collapsed={collapsed}
            router={router}
          />
        </StylesProvider>
      </PersistGate>
    </Provider>
  )
}

export default withRouter(App)
