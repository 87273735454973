import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { InitialStateType, FranchiseeDashboardItemType } from './types'

export type FetchFranchiseeDashboardPayload = string

export type FetchFranchiseeDashboardSuccessPayload = FranchiseeDashboardItemType

export type FetchFranchiseeDashboardFailurePayload = string | null

const initialState: InitialStateType = {
  data: null,
  isFetching: false,
  error: null,
}

export const franchiseeDashboardSlice = createSlice({
  name: 'franchiseeDashboard',
  initialState,
  reducers: {
    fetch(
      state: InitialStateType,
      _action: PayloadAction<FetchFranchiseeDashboardPayload>,
    ): void {
      state.isFetching = true
    },
    fetchSuccess(
      state: InitialStateType,
      action: PayloadAction<FetchFranchiseeDashboardSuccessPayload>,
    ): void {
      state.data = action.payload
      state.isFetching = false
    },
    fetchFailure(
      state: InitialStateType,
      action: PayloadAction<FetchFranchiseeDashboardFailurePayload>,
    ): void {
      state.isFetching = false
      state.error = action.payload
    },
  },
})

export const actions = franchiseeDashboardSlice.actions

export default franchiseeDashboardSlice.reducer
