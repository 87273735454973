import { get, del, post } from '../../infrastructure/api'
import {
  FetchSubscriptionsSuccessPayload,
  RemoveSubscriptionSuccessPayload,
  FetchPlansSuccessPayload,
  CreateSubscriptionPayload,
} from './types'

export const fetchSubscriptions = (
  page: number,
  companyId: string,
): Promise<FetchSubscriptionsSuccessPayload> =>
  get(`/admin/companies/${companyId}/plan-subscriptions`, {
    page,
    include: 'plan',
  })

export const removeSubscription = (
  companyId: string,
  planSubscriptionId: string,
): Promise<RemoveSubscriptionSuccessPayload> =>
  del(`/admin/companies/${companyId}/plan-subscriptions/${planSubscriptionId}`)

export const fetchPlans = (): Promise<FetchPlansSuccessPayload> =>
  get(`/admin/plans`)

export const createSubscription = (
  data: CreateSubscriptionPayload,
): Promise<FetchPlansSuccessPayload> =>
  post(`/admin/companies/${data.companyId}/plan-subscriptions`, data)
