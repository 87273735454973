import { createMigrate } from 'redux-persist'
import { RootState } from './reducers'

type PersistedRootStateV2 = RootState

type PersistedRootStateV1 = PersistedRootStateV2

type MigrationState = PersistedRootStateV1 | PersistedRootStateV2

const persistMigrations = {
  2: (state: PersistedRootStateV1): PersistedRootStateV2 => ({
    ...state,
    banners: {
      ...state.banners,
      blocks: [],
      banners: [],
    },
    blocks: {
      ...state.blocks,
      blocks: [],
      banners: [],
    },
  }),
}

export const persistMigrate = createMigrate<MigrationState>(persistMigrations)

export const persistVersion = 2
