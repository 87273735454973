/* eslint-disable @typescript-eslint/camelcase */
import { Moment } from 'moment'
import * as DB from '../../infrastructure/DB'
import { post } from '../../infrastructure/api'
import { FormFields } from '../companySearchForm/types'
import { AxiosResponse } from 'axios'

const includeArray: string[] = [
  'eventsCount',
  'businessAccount',
  'mainImage',
  'companyCategories',
]

type ParseSearchFieldPayload = {
  value: string
}

type ParseAnyFieldPayload = PickedDateRange & ParseSearchFieldPayload

export type PickedDateRange = {
  dateEnd: Moment | null
  dateStart: Moment | null
}

const include = includeArray.join(',')
const parseSearchField = (value: ParseSearchFieldPayload): string => value.value

const parseFieldValue = (
  key: string,
  value: ParseAnyFieldPayload,
): string | ParseAnyFieldPayload => {
  switch (key) {
    case FormFields.owner_id:
      return parseSearchField(value)
    case FormFields.business_account_id:
      return parseSearchField(value)
    case FormFields.area_id:
      return parseSearchField(value)
    case FormFields.franchisee_id:
      return parseSearchField(value)
    case FormFields.check_status:
      return parseSearchField(value)
    case FormFields.formalization_status:
      return parseSearchField(value)
    default:
      return value
  }
}

const getFiltersInParams = (filters: any): any => {
  const filterFields: any = Object.entries(filters)

  const optionKeys = ['check_status', 'formalization_status']

  let params = {}

  for (let i = 0; i < filterFields.length; i++) {
    const item = filterFields[i]
    const key = item[0]
    const value = item[1]?.value

    if (value !== null && typeof value !== 'undefined') {
      const parsedValue = parseFieldValue(key, value)
      params = {
        ...params,
        [optionKeys.includes(key)
          ? 'filter[property_option]'
          : `filter[${
              key === 'area_id' ? 'nested_area_id' : key
            }]`]: optionKeys.includes(key)
          ? `${key}:${parsedValue}`
          : parsedValue,
      }
    }
  }

  return params
}

export const remove = async (company_ids: string): Promise<void> => {
  const url = '/admin/companies/multiply/delete'
  const data = { company_ids }

  const successMessage = 'Успешно удалено'
  const errorMessage = 'Не удалось удалить'

  await DB.remove(url, data, successMessage, errorMessage)
}

export const get = async (data: any): Promise<any> => {
  const url = `/admin/companies`

  const { filters, pagination } = data
  const filterParams = getFiltersInParams(filters)
  const params = {
    page_size: pagination.perPage,
    page: pagination.currentPage,
    include,
    ...filterParams,
  }

  const response = await DB.get(url, params)

  return response
}

export const publish = async (
  company_ids: string,
  published: boolean,
): Promise<void> => {
  const url = `/admin/companies/multiply/publish`
  const data = { company_ids, published }

  let successMessage, errorMessage

  if (published) {
    successMessage = 'Успешно опубликовано'
    errorMessage = 'Не удалось опубликовать'
  } else {
    successMessage = 'Успешно снято с публикации'
    errorMessage = 'Не удалось снять с публикации'
  }

  await DB.post(url, data, successMessage, errorMessage)
}

export const uploadExcelFile = (file: File): Promise<AxiosResponse> =>
  post('/admin/companies/import-excel', {}, {}, [
    {
      field: 'file',
      item: file,
    },
  ])
