import { all, fork, put, takeEvery, call, take } from 'redux-saga/effects'
import { SagaIterator } from '@redux-saga/core'
import { actions } from './slice'
import * as api from './api'
import { formatErrorMessage } from '../../utils/helpers'
import {
  CreateFormType,
  SendGalleryPayload,
  SendImagePayload,
  SendVideosPayload,
} from './types'
import { Router } from '../../routes'

function* createContentSaga(action: { payload: CreateFormType }): SagaIterator {
  try {
    const form = action.payload
    const content = yield call(api.createContent, form)
    const pageId = content.page_id
    if (form.image && form.image.length && typeof form.image[0] !== 'string') {
      yield put(actions.sendImage({ pageId, image: form.image[0] }))
      yield take(actions.sendImageSuccess)
    }
    if (form.gallery && form.gallery.length) {
      yield put(
        actions.sendGallery({
          pageId,
          gallery: form.gallery.map((image, _, arr) => ({
            field: arr.length > 1 ? 'gallery' : 'gallery[0]',
            item: image,
          })),
        }),
      )
      yield take(actions.sendGallerySuccess)
    }
    if (form.videos && form.videos.length) {
      yield put(
        actions.sendVideos({
          pageId,
          videos: form.videos,
        }),
      )
      yield take(actions.sendVideosSuccess)
    }
    yield put(actions.createSuccess())
    Router.pushRoute(`/content`)
  } catch (error) {
    console.error('Create page failed', error)
    yield put(
      actions.createFailure({
        message: formatErrorMessage(error),
        fields: error.response.data?.errors,
      }),
    )
  }
}

function* sendContentImageSaga(action: {
  payload: SendImagePayload
}): SagaIterator {
  try {
    yield call(api.sendContentImage, action.payload)
    yield put(actions.sendImageSuccess())
  } catch (error) {
    console.error('Cannot send content image failed', error)
    yield put(actions.sendImageFailure(formatErrorMessage(error)))
  }
}

function* sendContentGallerySaga(action: {
  payload: SendGalleryPayload
}): SagaIterator {
  try {
    yield call(api.sendContentGallery, action.payload)
    yield put(actions.sendGallerySuccess())
  } catch (error) {
    console.error('Cannot send content gallery failed', error)
    yield put(actions.sendGalleryFailure(formatErrorMessage(error)))
  }
}

function* sendContentVideosSaga(action: {
  payload: SendVideosPayload
}): SagaIterator {
  try {
    yield call(api.sendContentVideos, action.payload)
    yield put(actions.sendVideosSuccess())
  } catch (error) {
    console.error('Cannot send content videos failed', error)
    yield put(actions.sendVideosFailure(formatErrorMessage(error)))
  }
}

export function* watchCreateContentSaga(): SagaIterator {
  yield takeEvery(actions.create, createContentSaga)
}

export function* watchSendContentImageSaga(): SagaIterator {
  yield takeEvery(actions.sendImage, sendContentImageSaga)
}

export function* watchSendContentGallerySaga(): SagaIterator {
  yield takeEvery(actions.sendGallery, sendContentGallerySaga)
}

export function* watchSendContentVideosSaga(): SagaIterator {
  yield takeEvery(actions.sendVideos, sendContentVideosSaga)
}

export default function* watchCreateContent(): SagaIterator {
  yield all([
    fork(watchCreateContentSaga),
    fork(watchSendContentImageSaga),
    fork(watchSendContentGallerySaga),
    fork(watchSendContentVideosSaga),
  ])
}
