import { get } from './../../infrastructure/api'
import { FetchAreaSelectorSuccessPayload } from './slice'

export const fetchAreas = (
  search?: string,
): Promise<FetchAreaSelectorSuccessPayload> => {
  return get('/admin/areas', {
    'filter[name]': search ?? '',
    count: 100,
    sort: 'name_length',
  })
}
