export default {
  amoCrm: {
    name: 'amoCRM',
    description:
      'Онлайн CRM система. Управление клиентами в современной облачной CRM',
    logo: '/images/services/amoCRM.jpeg',
    isIntegrated: false,
    isFetching: false,
    statusURL: '/admin/amo/status',
    error: null,
  },
}
