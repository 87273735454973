import { all, fork, put, takeEvery, call, select } from 'redux-saga/effects'
import { SagaIterator } from '@redux-saga/core'
import { actions, FetchCompanyPropertySelectorSuccessPayload } from './slice'
import * as api from './api'
import { formatErrorMessage } from '../../utils/helpers'
import { PropertySectorItem } from './types'

const getProperties = (state: {
  companyPropertySelector: { items: PropertySectorItem[] }
}): PropertySectorItem[] => state.companyPropertySelector.items

function* fetchCompanyPropertySelectorSaga(): SagaIterator {
  try {
    const companyProperties: FetchCompanyPropertySelectorSuccessPayload = yield call(
      api.fetchCompanyPropertySelector,
    )
    yield put(actions.fetchSuccess(companyProperties))
  } catch (error) {
    console.error('Fetch company properties failed', error)
    yield put(actions.fetchFailure(formatErrorMessage(error)))
  }
}

function* fetchOptionsSaga(action: { payload: string }): SagaIterator {
  try {
    const properties = yield select(getProperties)
    const option: PropertySectorItem = properties.find(
      (o: PropertySectorItem) => o.key === action.payload,
    )

    if (option) {
      const companyOptions = yield call(
        api.fetchOptions,
        option.company_property_id,
      )
      yield put(
        actions.fetchOptionsSuccess({
          key: action.payload,
          data: companyOptions,
        }),
      )
    }
  } catch (error) {
    console.error('Fetch company property selector options failed', error)
    yield put(actions.fetchOptionsFailure(formatErrorMessage(error)))
  }
}

function* watchCompanyPropertySelectorSaga(): SagaIterator {
  yield takeEvery(actions.fetch, fetchCompanyPropertySelectorSaga)
}

function* watchFetchOptionsSaga(): SagaIterator {
  yield takeEvery(actions.fetchOptions, fetchOptionsSaga)
}

export default function* watchCompanyPropertySelector(): SagaIterator {
  yield all([
    fork(watchCompanyPropertySelectorSaga),
    fork(watchFetchOptionsSaga),
  ])
}
