import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import {
  InitialStateType,
  FetchContentPayload,
  FetchContentSuccessPayload,
  FetchContentFailurePayload,
  RemoveContentPayload,
  RemoveContentSuccessPayload,
  RemoveContentFailurePayload,
} from './types'

const initialState: InitialStateType = {
  pages: [],
  meta: null,
  isFetching: false,
  isRemoving: false,
  error: null,
}

export const contentSlice = createSlice({
  name: 'content',
  initialState,
  reducers: {
    fetch(
      state: InitialStateType,
      _action: PayloadAction<FetchContentPayload>,
    ): void {
      state.isFetching = true
      state.error = null
    },
    fetchSuccess(
      state: InitialStateType,
      action: PayloadAction<FetchContentSuccessPayload>,
    ): void {
      state.pages = action.payload.data
      state.meta = action.payload.meta
      state.isFetching = false
    },
    fetchFailure(
      state: InitialStateType,
      action: PayloadAction<FetchContentFailurePayload>,
    ): void {
      state.isFetching = false
      state.error = action.payload
    },
    remove(
      state: InitialStateType,
      _action: PayloadAction<RemoveContentPayload>,
    ): void {
      state.isRemoving = true
      state.error = null
    },
    removeSuccess(
      state: InitialStateType,
      _action: PayloadAction<RemoveContentSuccessPayload>,
    ): void {
      state.isRemoving = false
    },
    removeFailure(
      state: InitialStateType,
      action: PayloadAction<RemoveContentFailurePayload>,
    ): void {
      state.isRemoving = false
      state.error = action.payload
    },
  },
})

export const actions = contentSlice.actions

export default contentSlice.reducer
