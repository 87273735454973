import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import {
  InitialState,
  FetchAnalyticsPayload,
  FetchAnalyticsSuccessPayload,
  FetchAnalyticsFailurePayload,
} from './types'

const initialState: InitialState = {
  analytics: [],
  isFetching: false,
  error: null,
}

export const companyAnalytics = createSlice({
  name: 'companyAnalytics',
  initialState,
  reducers: {
    fetch(
      state: InitialState,
      _action: PayloadAction<FetchAnalyticsPayload>,
    ): void {
      state.isFetching = true
      state.error = null
    },
    fetchSuccess(
      state: InitialState,
      action: PayloadAction<FetchAnalyticsSuccessPayload>,
    ): void {
      state.analytics = action.payload.data
      state.isFetching = false
    },
    fetchFailure(
      state: InitialState,
      action: PayloadAction<FetchAnalyticsFailurePayload>,
    ): void {
      state.error = action.payload.error
      state.isFetching = false
    },
    destroy(): InitialState {
      return initialState
    },
  },
})

export const actions = companyAnalytics.actions

export default companyAnalytics.reducer
