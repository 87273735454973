import Axios from 'axios'
import Cookies from 'universal-cookie'

const cookies = new Cookies()

const axios = Axios.create({
  baseURL: process.env.NEXT_PUBLIC_API_URL,
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json',
  },
})

axios.interceptors.request.use(
  (config) => {
    const token = cookies.get('token')

    return {
      ...config,
      headers: {
        ...config.headers,
        Authorization: `Bearer ${token}`,
      },
    }
  },
  (err) => {
    console.warn('error: ', err.message)
  },
)

export default axios
