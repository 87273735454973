import {
  all,
  fork,
  put,
  takeEvery,
  call,
  take,
  select,
} from 'redux-saga/effects'
import { SagaIterator } from '@redux-saga/core'
import { actions } from './slice'
import * as api from './api'
import { formatErrorMessage } from '../../utils/helpers'
import {
  EditBannerPayload,
  SendImagePayload,
  CreateBannerPayload,
  RemoveImagePayload,
  FetchBlocksPayload,
  InitialStateType,
} from './types'

const getPageSelector = (state: { banners: InitialStateType }): number =>
  state.banners.meta ? state.banners.meta.currentPage : 1

function* fetchBannersSaga(): SagaIterator {
  try {
    const banners = yield call(api.fetchBanners)
    yield put(actions.fetchSuccess(banners.data))
  } catch (error) {
    console.error('Fetch banners failed', error)
    yield put(actions.fetchFailure(formatErrorMessage(error)))
  }
}

function* fetchBlocksSaga(action: {
  payload: FetchBlocksPayload
}): SagaIterator {
  try {
    const blocks = yield call(api.fetchBlocks, action.payload)
    yield put(actions.fetchBlocksSuccess(blocks))
  } catch (error) {
    console.error('Fetch blocks failed', error)
    yield put(actions.fetchBlocksFailure(formatErrorMessage(error)))
  }
}

function* fetchAllBlocksSaga(): SagaIterator {
  try {
    const blocks = yield call(api.fetchAllBlocks)
    yield put(actions.fetchAllBlocksSuccess(blocks.data))
  } catch (error) {
    console.error('Fetch all blocks failed', error)
    yield put(actions.fetchAllBlocksFailure(formatErrorMessage(error)))
  }
}

function* fetchCategoriesSaga(): SagaIterator {
  try {
    const categories = yield call(api.fetchCategories)
    yield put(actions.fetchCategoriesSuccess(categories))
  } catch (error) {
    console.error('Fetch categories failed', error)
    yield put(actions.fetchCategoriesFailure(formatErrorMessage(error)))
  }
}

function* fetchPagesSaga(): SagaIterator {
  try {
    const pages = yield call(api.fetchPages)
    yield put(actions.fetchPagesSuccess(pages.data))
  } catch (error) {
    console.error('Fetch pages failed', error)
    yield put(actions.fetchPagesFailure(formatErrorMessage(error)))
  }
}

function* createBannerSaga(action: {
  payload: CreateBannerPayload
}): SagaIterator {
  try {
    const { data, image } = action.payload
    const banner = yield call(api.createBanner, data)
    if (image) {
      yield put(actions.sendImage({ bannerId: banner.banner_id, image }))
      yield take([actions.sendImageSuccess, actions.sendImageFailure])
    }
    yield put(actions.createSuccess())
    yield put(actions.fetch())
    const page = yield select(getPageSelector)
    yield put(actions.fetchBlocks(page))
  } catch (error) {
    console.error('Create banner failed', error)
    yield put(
      actions.createFailure({
        message: formatErrorMessage(error),
        fields: error.response.data?.errors,
      }),
    )
  }
}

function* editBannerSaga(action: { payload: EditBannerPayload }): SagaIterator {
  try {
    const { bannerId, data, image, isImageRemoved } = action.payload
    yield call(api.editBanner, bannerId, data)
    if (image) {
      yield put(actions.sendImage({ bannerId: bannerId, image }))
      yield take([actions.sendImageSuccess, actions.sendImageFailure])
    } else if (isImageRemoved) {
      yield put(actions.removeImage(bannerId))
      yield take([actions.removeImageSuccess, actions.removeImageFailure])
    }
    yield put(actions.editSuccess())
    yield put(actions.fetch())
    const page = yield select(getPageSelector)
    yield put(actions.fetchBlocks(page))
  } catch (error) {
    console.error('Edit banner failed', error)
    yield put(
      actions.editFailure({
        message: formatErrorMessage(error),
        fields: error.response.data?.errors,
      }),
    )
  }
}

function* removeBlockSaga(action: { payload: string }): SagaIterator {
  try {
    yield call(api.removeBanner, action.payload)
    yield put(actions.removeSuccess())
    yield put(actions.fetch())
  } catch (error) {
    console.error('Remove banner failed', error)
    yield put(actions.removeFailure(formatErrorMessage(error)))
  }
}

function* sendImageSaga(action: { payload: SendImagePayload }): SagaIterator {
  try {
    const { bannerId, image } = action.payload
    yield call(api.sendImage, bannerId, image)
    yield put(actions.sendImageSuccess())
  } catch (error) {
    console.error('Send banner image failed', error)
    yield put(actions.sendImageFailure(formatErrorMessage(error)))
  }
}

function* removeImageSaga(action: {
  payload: RemoveImagePayload
}): SagaIterator {
  try {
    yield call(api.removeImage, action.payload)
    yield put(actions.removeImageSuccess())
  } catch (error) {
    console.error('Remove banner image failed', error)
    yield put(actions.removeImageFailure(formatErrorMessage(error)))
  }
}

export function* watchFetchBannersSaga(): SagaIterator {
  yield takeEvery(actions.fetch, fetchBannersSaga)
}

export function* watchFetchBlocksSaga(): SagaIterator {
  yield takeEvery(actions.fetchBlocks, fetchBlocksSaga)
}

export function* watchFetchAllBlocksSaga(): SagaIterator {
  yield takeEvery(actions.fetchAllBlocks, fetchAllBlocksSaga)
}

export function* watchFetchCategoriesSaga(): SagaIterator {
  yield takeEvery(actions.fetchCategories, fetchCategoriesSaga)
}

export function* watchFetchPagesSaga(): SagaIterator {
  yield takeEvery(actions.fetchPages, fetchPagesSaga)
}

export function* watchCreateBannerSaga(): SagaIterator {
  yield takeEvery(actions.create, createBannerSaga)
}

export function* watchEditBannerSaga(): SagaIterator {
  yield takeEvery(actions.edit, editBannerSaga)
}

export function* watchRemoveBlockSaga(): SagaIterator {
  yield takeEvery(actions.remove, removeBlockSaga)
}

export function* watchSendImageSaga(): SagaIterator {
  yield takeEvery(actions.sendImage, sendImageSaga)
}

export function* watchRemoveImageSaga(): SagaIterator {
  yield takeEvery(actions.removeImage, removeImageSaga)
}

export default function* watchBanners(): SagaIterator {
  yield all([
    fork(watchFetchBannersSaga),
    fork(watchFetchBlocksSaga),
    fork(watchFetchAllBlocksSaga),
    fork(watchFetchCategoriesSaga),
    fork(watchFetchPagesSaga),
    fork(watchCreateBannerSaga),
    fork(watchEditBannerSaga),
    fork(watchRemoveBlockSaga),
    fork(watchSendImageSaga),
    fork(watchRemoveImageSaga),
  ])
}
