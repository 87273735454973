import { all, fork, put, takeEvery, call } from 'redux-saga/effects'
import { SagaIterator } from '@redux-saga/core'
import { actions } from './slice'
import * as api from './api'
import { FranchiseeValueType } from './types'
import { createHumps } from 'lodash-humps-ts'
import { snakeCase } from 'lodash'
import { formatErrorMessage } from '../../utils/helpers'
import { Router } from '../../routes'

function* fetchFranchiseeSaga(action: { payload: string }): SagaIterator {
  try {
    const franchisee = yield call(api.fetchFranchisee, action.payload)
    yield put(actions.fetchSuccess(franchisee))
  } catch (error) {
    console.error('Fetch franchisee failed', error)
    yield put(actions.fetchFailure(formatErrorMessage(error)))
  }
}

function* editFranchiseeSaga(action: {
  payload: {
    id: string
    fields: FranchiseeValueType
  }
}): SagaIterator {
  try {
    const { id, fields } = action.payload

    const snakes = createHumps(snakeCase)
    const snakesFields = snakes(fields)

    snakesFields.area_ids =
      Array.isArray(snakesFields.area_ids) && snakesFields.area_ids.length
        ? snakesFields.area_ids.map((i: { value: string }) => i.value)
        : []

    yield call(api.editFranchisee, id, snakesFields)
    yield put(actions.editSuccess())
    yield put(actions.fetch(id))
  } catch (error) {
    console.error('Edit franchisee failed', error)
    // Sentry.captureException(error);
    yield put(actions.editFailure(error?.response?.data?.errors))
  }
}

function* updateCompaniesSaga(action: { payload: string }): SagaIterator {
  try {
    yield call(api.updateCompanies, action.payload)
    yield put(actions.updateCompaniesSuccess())
    yield put(actions.fetch(action.payload))
  } catch (error) {
    console.error('Update companies failed', error)
    yield put(actions.updateCompaniesFailure(formatErrorMessage(error)))
  }
}

function* deleteFranchiseeSaga(action: { payload: string }): SagaIterator {
  try {
    yield call(api.deleteFranchisee, action.payload)
    yield put(actions.deleteSuccess())
    Router.pushRoute(`/franchisee`)
  } catch (error) {
    console.error('Delete franchisee failed', error)
    yield put(actions.deleteFailure(formatErrorMessage(error)))
  }
}

export function* watchFetchFranchiseeSaga(): SagaIterator {
  yield takeEvery(actions.fetch, fetchFranchiseeSaga)
}

export function* watchEditFranchiseeSaga(): SagaIterator {
  yield takeEvery(actions.edit, editFranchiseeSaga)
}

export function* watchUpdateCompaniesSaga(): SagaIterator {
  yield takeEvery(actions.updateCompanies, updateCompaniesSaga)
}

export function* watchDeleteFranchiseeSaga(): SagaIterator {
  yield takeEvery(actions.delete, deleteFranchiseeSaga)
}

export default function* watchEditFranchisee(): SagaIterator {
  yield all([
    fork(watchFetchFranchiseeSaga),
    fork(watchEditFranchiseeSaga),
    fork(watchUpdateCompaniesSaga),
    fork(watchDeleteFranchiseeSaga),
  ])
}
