import { all, fork, put, takeEvery, call } from 'redux-saga/effects'
import { SagaIterator } from '@redux-saga/core'
import {
  actions,
  FetchIntegrationPayload,
  SendAuthorizationPayload,
} from './slice'
import * as api from './api'
import Services from './services'
import { formatErrorMessage } from '../../utils/helpers'
import { IntegrationKeys } from './types'
import { Router } from 'src/routes'

function* fetchStatusIntegrationSaga(action: {
  payload: FetchIntegrationPayload
}): SagaIterator {
  try {
    const url = Services[action.payload].statusURL
    const { status } = yield call(api.fetchStatusIntegration, url)
    yield put(
      actions.fetchSuccess({
        key: action.payload,
        status,
      }),
    )
  } catch (error) {
    console.error('Fetch integration failed', error)
    yield put(
      actions.fetchFailure({
        key: action.payload,
        error: formatErrorMessage(error),
      }),
    )
  }
}

function* fetchAllStatusIntegrationSaga(): SagaIterator {
  let service: IntegrationKeys
  for (service in Services) {
    yield put(actions.fetch(service))
  }
}

function* sendAuthorizationSaga(action: {
  payload: SendAuthorizationPayload
}): SagaIterator {
  try {
    const { key, clientId, baseDomain, code } = action.payload
    yield call(api.sendAuthorization, { clientId, baseDomain, code })
    yield put(actions.sendAuthorizationSuccess({ key }))
    yield put(actions.fetch(key))
    Router.replace('/integrations')
  } catch (error) {
    console.error('Send authorization failed', error)
    yield put(
      actions.sendAuthorizationFailure({
        key: action.payload.key,
        error: formatErrorMessage(error),
      }),
    )
  }
}

export function* watchFetchStatusIntegrationSaga(): SagaIterator {
  yield takeEvery(actions.fetch, fetchStatusIntegrationSaga)
}

export function* watchFetchAllStatusIntegrationSaga(): SagaIterator {
  yield takeEvery(actions.fetchAll, fetchAllStatusIntegrationSaga)
}

export function* watchSendAuthorizationSaga(): SagaIterator {
  yield takeEvery(actions.sendAuthorization, sendAuthorizationSaga)
}

export default function* watchIntegrations(): SagaIterator {
  yield all([
    fork(watchFetchStatusIntegrationSaga),
    fork(watchFetchAllStatusIntegrationSaga),
    fork(watchSendAuthorizationSaga),
  ])
}
