import { all, fork, put, takeEvery, call, select } from 'redux-saga/effects'
import { SagaIterator } from '@redux-saga/core'
import { actions } from './slice'
import * as api from './api'
import { formatErrorMessage } from '../../utils/helpers'
import {
  InitialStateType,
  FormItemsType,
  EditBlockPayload,
  FetchBlocksPayload,
  BlockItemType,
} from './types'

const getPageSelector = (state: { blocks: InitialStateType }): number =>
  state.blocks.meta ? state.blocks.meta.currentPage : 1

const getBlocksSelector = (state: {
  blocks: InitialStateType
}): BlockItemType[] => state.blocks.blocks

function* fetchBlocksSaga(action: {
  payload: FetchBlocksPayload
}): SagaIterator {
  try {
    const blocks = yield call(api.fetchBlocks, action.payload)
    yield put(actions.fetchSuccess(blocks))
  } catch (error) {
    console.error('Fetch blocks failed', error)
    yield put(actions.fetchFailure(formatErrorMessage(error)))
  }
}

function* fetchBannersSaga(): SagaIterator {
  try {
    const banners = yield call(api.fetchBanners)
    yield put(actions.fetchBannersSuccess(banners.data))
  } catch (error) {
    console.error('Fetch banners failed', error)
    yield put(actions.fetchBannersFailure(formatErrorMessage(error)))
  }
}

function* createBlockSaga(action: { payload: FormItemsType }): SagaIterator {
  try {
    yield call(api.createBlock, action.payload)
    yield put(actions.createSuccess())
    const page = yield select(getPageSelector)
    yield put(actions.fetch(page))
  } catch (error) {
    console.error('Create block failed', error)
    yield put(
      actions.createFailure({
        message: formatErrorMessage(error),
        fields: error.response.data?.errors,
      }),
    )
  }
}

function* editBlockSaga(action: { payload: EditBlockPayload }): SagaIterator {
  try {
    const { blockId, data } = action.payload
    yield call(api.editBlock, blockId, data)
    yield put(actions.editSuccess())
    const page = yield select(getPageSelector)
    yield put(actions.fetch(page))
  } catch (error) {
    console.error('Edit block failed', error)
    yield put(
      actions.editFailure({
        message: formatErrorMessage(error),
        fields: error.response.data?.errors,
      }),
    )
  }
}

function* removeBlockSaga(action: { payload: string }): SagaIterator {
  try {
    yield call(api.removeBlock, action.payload)
    yield put(actions.removeSuccess())
    const page = yield select(getPageSelector)
    const blocks = yield select(getBlocksSelector)
    if (blocks.length === 1 && page > 1) {
      yield put(actions.fetch(page - 1))
    } else {
      yield put(actions.fetch(page))
    }
  } catch (error) {
    console.error('Remove block failed', error)
    yield put(actions.removeFailure(formatErrorMessage(error)))
  }
}

export function* watchFetchBlocksSaga(): SagaIterator {
  yield takeEvery(actions.fetch, fetchBlocksSaga)
}

export function* watchFetchBannersSaga(): SagaIterator {
  yield takeEvery(actions.fetchBanners, fetchBannersSaga)
}

export function* watchCreateBlockSaga(): SagaIterator {
  yield takeEvery(actions.create, createBlockSaga)
}

export function* watchEditBlockSaga(): SagaIterator {
  yield takeEvery(actions.edit, editBlockSaga)
}

export function* watchRemoveBlockSaga(): SagaIterator {
  yield takeEvery(actions.remove, removeBlockSaga)
}

export default function* watchBlocks(): SagaIterator {
  yield all([
    fork(watchFetchBlocksSaga),
    fork(watchFetchBannersSaga),
    fork(watchCreateBlockSaga),
    fork(watchEditBlockSaga),
    fork(watchRemoveBlockSaga),
  ])
}
