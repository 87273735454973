import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { PropertySectorItem, OptionItemType } from './types'

export type InitialStateType = {
  isFetching: boolean
  items: PropertySectorItem[]
  options: { [key: string]: OptionItemType[] }
  error: string | null
}

export type FetchEventPropertySelectorPayload = undefined

export type FetchEventPropertySelectorSuccessPayload = PropertySectorItem[]

export type FetchEventPropertySelectorFailurePayload = string | null

export type FetchOptionsPayload = string

export type FetchOptionsSuccessPayload = { key: string; data: OptionItemType[] }

export type FetchOptionsFailurePayload = string | null

const initialState: InitialStateType = {
  isFetching: false,
  items: [],
  options: {},
  error: null,
}

export const eventPropertySelector = createSlice({
  name: 'eventPropertySelector',
  initialState,
  reducers: {
    fetch(
      state: InitialStateType,
      _action: PayloadAction<FetchEventPropertySelectorPayload>,
    ): void {
      state.isFetching = true
    },
    fetchSuccess(
      state: InitialStateType,
      action: PayloadAction<FetchEventPropertySelectorSuccessPayload>,
    ): void {
      state.isFetching = false
      state.items = action.payload
    },
    fetchFailure(
      state: InitialStateType,
      action: PayloadAction<FetchEventPropertySelectorFailurePayload>,
    ): void {
      state.isFetching = false
      state.error = action.payload
    },
    fetchOptions(
      state: InitialStateType,
      _action: PayloadAction<FetchOptionsPayload>,
    ): void {
      state.isFetching = true
    },
    fetchOptionsSuccess(
      state: InitialStateType,
      action: PayloadAction<FetchOptionsSuccessPayload>,
    ): void {
      state.isFetching = false
      state.options = {
        ...state.options,
        [action.payload.key]: action.payload.data,
      }
    },
    fetchOptionsFailure(
      state: InitialStateType,
      action: PayloadAction<FetchOptionsFailurePayload>,
    ): void {
      state.isFetching = false
      state.error = action.payload
    },
    destroy(): InitialStateType {
      return initialState
    },
  },
})

export const actions = eventPropertySelector.actions

export default eventPropertySelector.reducer
