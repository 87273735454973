import { combineReducers } from '@reduxjs/toolkit'
import { AnyAction } from '@reduxjs/toolkit'

import profile from '../../containers/profile/slice'
import createCompany from './createCompany'
import userSelector from '../../containers/userSelector/slice'
import businessAccountSelector from '../../containers/businessAccountSelector/slice'
import companyFormFilter from '../../containers/companySearchForm/slice'
import companiesTable from '../../containers/companies/slice'
import areaSelector from '../../containers/areaSelector/slice'
import categoriesSelector from '../../containers/categoriesSelector/slice'
import featuresSelector from '../../containers/featuresSelector/slice'
import companyMainInfo from '../../containers/companyMainInfo/slice'
import franchiseeSelector from '../../containers/franchiseeSelector/slice'
import payments from '../../containers/payment/slice'
import franchisee from '../../containers/franchisee/slice'
import createFranchisee from '../../containers/createFranchisee/slice'
import editFranchisee from '../../containers/editFranchisee/slice'
import franchiseeReport from '../../containers/franchiseeReport/slice'
import dashboardFranchisee from '../../containers/dashboardFranchisee/slice'
import integrations from '../../containers/integrations/slice'
import companySubscriptions from '../../containers/companySubscriptions/slice'
import companyAnalytics from '../../containers/companyAnalytics/slice'
import content from '../../containers/content/slice'
import createContent from '../../containers/createContent/slice'
import editContent from '../../containers/editContent/slice'
import banners from '../../containers/banners/slice'
import blocks from '../../containers/blocks/slice'
import appVersions from '../../containers/appVersions/slice'
import promocodes from '../../containers/promocodes/slice'
import companyPropertySelector from '../../containers/companyPropertySelector/slice'
import eventPropertySelector from '../../containers/eventPropertySelector/slice'
import newsletters from '../../containers/newsletters/slice'
import newsletterWizard from '../../containers/newsletterWizard/slice'
import newslettersTasks from '../../containers/newslettersTasks/slice'
import areaSelectorTree from '../../containers/areaSelectorTree/slice'
import newAreaSelectorTree from '../../containers/newAreaSelectorTree/slice'
import plans from '../../containers/plans/slice'

const appReducer = combineReducers({
  profile,
  createCompany,
  userSelector,
  businessAccountSelector,
  companyFormFilter,
  companiesTable,
  areaSelector,
  categoriesSelector,
  featuresSelector,
  companyMainInfo,
  franchiseeSelector,
  payments,
  franchisee,
  createFranchisee,
  editFranchisee,
  franchiseeReport,
  dashboardFranchisee,
  integrations,
  companySubscriptions,
  companyAnalytics,
  content,
  createContent,
  editContent,
  banners,
  blocks,
  appVersions,
  promocodes,
  companyPropertySelector,
  eventPropertySelector,
  newsletters,
  newsletterWizard,
  newslettersTasks,
  areaSelectorTree,
  newAreaSelectorTree,
  plans,
})

export type RootState = ReturnType<typeof appReducer>

const rootReducer = (state: RootState, action: AnyAction): RootState => {
  if (action.type === 'profile/signOutSuccess') {
    localStorage.removeItem('persist:root')
    return appReducer(undefined, action)
  }
  return appReducer(state, action)
}

export default rootReducer
