export const CLEAR_STATE = 'CLEAR_STATE'
export const HANDLE_CHANGE_USER_INPUT = 'HANDLE_CHANGE_USER_INPUT'

export interface ActionType<T> {
  type: string
  payload?: T
}

export type ChangeInputPayload = {
  key: string
  value: string | boolean
  page: 'main' | 'requisites'
}

export const changeInput = (
  key: string,
  value: string | boolean,
  page: string,
): ActionType<ChangeInputPayload> => ({
  type: HANDLE_CHANGE_USER_INPUT,
  payload: { key, page, value } as ChangeInputPayload,
})

export const clearState = (): ActionType<undefined> => ({
  type: CLEAR_STATE,
})
