import { SagaIterator } from '@redux-saga/core'
import { all, call, fork, put, takeEvery } from 'redux-saga/effects'
import * as api from './api'
import { actions } from './slice'
import { formatErrorMessage } from '../../utils/helpers'
import { notification } from 'antd'

function* fetchTasks(action: { payload: { page: number } }): SagaIterator {
  try {
    const { data, meta } = yield call(api.fetchTasks, action.payload.page)
    yield put(actions.fetchTasksSuccess({ data, meta }))
  } catch (error) {
    console.error('Fetch tasks failed', error)
    const formatError = formatErrorMessage(error)
    notification.warning({
      message: 'Произошла ошибка, при запросе заданий',
      description: formatError,
    })
    yield put(actions.fetchTasksFailure(formatError))
  }
}

export function* watchFetchTasksSaga(): SagaIterator {
  yield takeEvery(actions.fetchTasks, fetchTasks)
}

export default function* watchNewsletterTasks(): SagaIterator {
  yield all([fork(watchFetchTasksSaga)])
}
