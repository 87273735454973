import { ResponseType } from 'axios'
import { del, get, post } from '../../infrastructure/api'
import { LoginDataType } from './types'

export const login = (data: LoginDataType): Promise<ResponseType> =>
  post('/auth/login', {}, data)

export const fetchProfile = (): Promise<ResponseType> => get('/auth/me')

export const signOut = (): Promise<ResponseType> => del('/auth/jwt/token')
