import { AxiosResponse } from 'axios'
import { post } from '../../infrastructure/api'
import {
  CreateFormType,
  SendGalleryPayload,
  SendImagePayload,
  SendVideosPayload,
} from './types'

export const createContent = (data: CreateFormType): Promise<AxiosResponse> =>
  post('/admin/pages', {}, data)

export const sendContentImage = (
  data: SendImagePayload,
): Promise<AxiosResponse> =>
  post(`/admin/pages/${data.pageId}/image`, {}, {}, [
    {
      field: 'image',
      item: data.image,
    },
  ])

export const sendContentGallery = (
  data: SendGalleryPayload,
): Promise<AxiosResponse> =>
  post(`/admin/pages/${data.pageId}/gallery`, {}, {}, data.gallery)

export const sendContentVideos = (
  data: SendVideosPayload,
): Promise<AxiosResponse> =>
  post(`/admin/pages/${data.pageId}/videos`, {}, { videos: data.videos })
