import axios, { Method } from 'axios'
import { getToken } from '../services/Token.service'
import { RcFile } from 'antd/lib/upload/interface'

type ParamValueType = string | string[] | number | boolean | undefined
type FileDataType = { field: string; item: File | RcFile }[] | undefined

export function request<T, D>(
  endpoint: string,
  method: Method = 'GET',
  params: Record<string, ParamValueType> = {},
  data?: D,
  fileData?: FileDataType,
): Promise<T> {
  const token = getToken()

  const isDataMethod = ['POST', 'PATCH', 'PUT'].includes(method)
  const formData = new FormData()

  if (fileData) {
    fileData.forEach((file, index, array) => {
      if (array.length > 1) {
        formData.append(`${file.field}[${index}]`, file.item)
      } else {
        formData.append(file.field, file.item)
      }
    })
  }

  return axios(endpoint, {
    baseURL: process.env.NEXT_PUBLIC_API_URL,
    method,
    headers: {
      Accept: 'application/json',
      'Content-Type': fileData ? 'multipart/form-data' : 'application/json',
      Authorization: `Bearer ${token}`,
    },
    params,
    data: isDataMethod ? (fileData ? formData : data) : null,
  }).then((response) => response.data as Promise<T>)
}

export function get<T>(
  url: string,
  params?: Record<string, ParamValueType>,
): Promise<T> {
  return request(url, 'GET', params)
}

export function post<T, D = unknown>(
  url: string,
  params?: Record<string, ParamValueType>,
  data?: D,
  fileData?: FileDataType,
): Promise<T> {
  return request(url, 'POST', params, data, fileData)
}

export function put<T, D>(
  url: string,
  params?: Record<string, ParamValueType>,
  data?: D,
): Promise<T> {
  return request(url, 'PUT', params, data)
}

export function patch<T, D>(
  url: string,
  params?: Record<string, ParamValueType>,
  data?: D,
): Promise<T> {
  return request(url, 'PATCH', params, data)
}

export function del<T>(
  url: string,
  params?: Record<string, ParamValueType>,
): Promise<T> {
  return request(url, 'DELETE', params)
}
