import { AxiosResponse } from 'axios'
import { get } from '../../infrastructure/api'
import moment from 'moment'

export const fetchFranchisee = (
  franchiseeId: string,
): Promise<AxiosResponse> => {
  return get(`/admin/franchisees/${franchiseeId}`, {
    include: ['companiesCount', 'premiumCompaniesCount'],
  })
}

export const fetchOrdersSummary = (
  franchiseeId: string,
): Promise<AxiosResponse> => {
  return get(`/admin/franchisees/${franchiseeId}/order-summary`, {
    'filter[from]': moment().subtract(1, 'months').format('YMMDD'),
    'filter[to]': moment().format('YMMDD'),
  })
}
