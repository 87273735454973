import { get } from './../../infrastructure/api'
import { FetchBusinessAccountSelectorSuccessPayload } from './slice'

export const fetchBusinessAccount = (
  search?: string,
): Promise<FetchBusinessAccountSelectorSuccessPayload> => {
  return get('/admin/businessAccounts', {
    'filter[search]': search ?? '',
    count: 10,
  })
}
