import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import {
  InitialStateType,
  FetchPromocodesPayload,
  FetchPromocodesSuccessPayload,
  FetchPromocodesFailurePayload,
  SetModalPayload,
  CreatePromocodePayload,
  CreatePromocodeSuccessPayload,
  CreatePromocodeFailurePayload,
  EditPromocodePayload,
  EditPromocodeSuccessPayload,
  EditPromocodeFailurePayload,
  RemovePromocodePayload,
  RemovePromocodeSuccessPayload,
  RemovePromocodeFailurePayload,
} from './types'
import { buildFieldErrors } from '../../utils/helpers'

const initialState: InitialStateType = {
  promocodes: [],
  meta: null,
  isFetching: false,
  isModal: false,
  selectedVersion: '',
  isProcessCreate: false,
  isProcessEdit: false,
  isProcessRemove: false,
  error: null,
  errorForm: null,
}

export const promocodesSlice = createSlice({
  name: 'promocodesSlice',
  initialState,
  reducers: {
    fetch(
      state: InitialStateType,
      _action: PayloadAction<FetchPromocodesPayload>,
    ): void {
      state.isFetching = true
      state.error = null
    },
    fetchSuccess(
      state: InitialStateType,
      action: PayloadAction<FetchPromocodesSuccessPayload>,
    ): void {
      state.promocodes = action.payload.data
      state.meta = action.payload.meta
      state.isFetching = false
    },
    fetchFailure(
      state: InitialStateType,
      action: PayloadAction<FetchPromocodesFailurePayload>,
    ): void {
      state.isFetching = false
      state.error = action.payload
    },
    create(
      state: InitialStateType,
      _action: PayloadAction<CreatePromocodePayload>,
    ): void {
      state.isProcessCreate = true
    },
    createSuccess(
      state: InitialStateType,
      _action: PayloadAction<CreatePromocodeSuccessPayload>,
    ): void {
      state.isModal = false
      state.isProcessCreate = false
    },
    createFailure(
      state: InitialStateType,
      action: PayloadAction<CreatePromocodeFailurePayload>,
    ): void {
      state.isProcessCreate = false
      state.errorForm = buildFieldErrors(action.payload)
    },
    edit(
      state: InitialStateType,
      _action: PayloadAction<EditPromocodePayload>,
    ): void {
      state.isProcessEdit = true
    },
    editSuccess(
      state: InitialStateType,
      _action: PayloadAction<EditPromocodeSuccessPayload>,
    ): void {
      state.isModal = false
      state.isProcessEdit = false
    },
    editFailure(
      state: InitialStateType,
      action: PayloadAction<EditPromocodeFailurePayload>,
    ): void {
      state.isProcessEdit = false
      state.errorForm = buildFieldErrors(action.payload)
    },
    remove(
      state: InitialStateType,
      _action: PayloadAction<RemovePromocodePayload>,
    ): void {
      state.isProcessRemove = true
    },
    removeSuccess(
      state: InitialStateType,
      _action: PayloadAction<RemovePromocodeSuccessPayload>,
    ): void {
      state.isProcessRemove = false
    },
    removeFailure(
      state: InitialStateType,
      action: PayloadAction<RemovePromocodeFailurePayload>,
    ): void {
      state.isProcessRemove = false
      state.error = action.payload
    },
    setModal(
      state: InitialStateType,
      action: PayloadAction<SetModalPayload>,
    ): void {
      state.isModal = action.payload.isModal
      state.selectedVersion = action.payload.selectedVersion || ''
    },
    clearErrorForm(state: InitialStateType): void {
      state.errorForm = null
    },
  },
})

export const actions = promocodesSlice.actions

export default promocodesSlice.reducer
