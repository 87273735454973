import { all, fork, put, takeEvery, call, select } from 'redux-saga/effects'
import { SagaIterator } from '@redux-saga/core'
import { actions } from './slice'
import * as api from './api'
import {
  InitialState,
  SubscriptionItemType,
  FetchSubscriptionsPayload,
  RemoveSubscriptionPayload,
  CreateSubscriptionPayload,
} from './types'
import { PayloadAction } from '@reduxjs/toolkit'
import { formatErrorMessage } from '../../utils/helpers'
import { actions as companyActions } from '../companyMainInfo/slice'

const getPageSelector = (state: {
  companySubscriptions: InitialState
}): number =>
  state.companySubscriptions.meta
    ? state.companySubscriptions.meta.currentPage
    : 1

const getSubscriptionsSelector = (state: {
  companySubscriptions: InitialState
}): SubscriptionItemType[] => state.companySubscriptions.subscriptions

function* fetchSubscriptionsSaga(
  action: PayloadAction<FetchSubscriptionsPayload>,
): SagaIterator {
  try {
    const { page, companyId } = action.payload
    const subscriptions = yield call(api.fetchSubscriptions, page, companyId)
    yield put(actions.fetchSuccess(subscriptions))
  } catch (error) {
    console.error('Fetch subscriptions failed', error)
    yield put(actions.fetchFailure(formatErrorMessage(error)))
  }
}

function* removeSubscriptionSaga(
  action: PayloadAction<RemoveSubscriptionPayload>,
): SagaIterator {
  const { companyId, planSubscriptionId } = action.payload
  try {
    yield call(api.removeSubscription, companyId, planSubscriptionId)
    yield put(actions.removeSuccess())
    yield put(companyActions.refreshCompany(action.payload.companyId))
    const page = yield select(getPageSelector)
    const subscriptions = yield select(getSubscriptionsSelector)
    if (subscriptions.length === 1 && page > 1) {
      yield put(actions.fetch({ page: page - 1, companyId }))
    } else {
      yield put(actions.fetch({ page, companyId }))
    }
  } catch (error) {
    console.error('Remove subscription failed', error)
    yield put(actions.removeFailure(formatErrorMessage(error)))
  }
}

function* fetchPlansSaga(): SagaIterator {
  try {
    const plans = yield call(api.fetchPlans)
    yield put(actions.fetchPlansSuccess(plans.data))
  } catch (error) {
    console.error('Fetch plans failed', error)
    yield put(actions.fetchPlansFailure(formatErrorMessage(error)))
  }
}

function* createSubscriptionsSaga(
  action: PayloadAction<CreateSubscriptionPayload>,
): SagaIterator {
  try {
    yield call(api.createSubscription, action.payload)
    yield put(actions.createSuccess())
    yield put(companyActions.refreshCompany(action.payload.companyId))
    const page = yield select(getPageSelector)
    yield put(actions.fetch({ page, companyId: action.payload.companyId }))
  } catch (error) {
    console.error('Create subscription failed', error)
    yield put(actions.setErrorFields(error.response?.data?.errors))
    yield put(actions.createFailure(formatErrorMessage(error)))
  }
}

export function* watchFetchSubscriptionsSaga(): SagaIterator {
  yield takeEvery(actions.fetch, fetchSubscriptionsSaga)
}

export function* watchRemoveSubscriptionSaga(): SagaIterator {
  yield takeEvery(actions.remove, removeSubscriptionSaga)
}

export function* watchFetchPlansSaga(): SagaIterator {
  yield takeEvery(actions.fetchPlans, fetchPlansSaga)
}

export function* watchCreateSubscriptionsSaga(): SagaIterator {
  yield takeEvery(actions.create, createSubscriptionsSaga)
}

export default function* watchCompanySubscriptions(): SagaIterator {
  yield all([
    fork(watchFetchSubscriptionsSaga),
    fork(watchRemoveSubscriptionSaga),
    fork(watchFetchPlansSaga),
    fork(watchCreateSubscriptionsSaga),
  ])
}
