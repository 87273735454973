import React from 'react'
import { Layout, ConfigProvider } from 'antd'
import ruRU from 'antd/lib/locale/ru_RU'
import nookies from 'nookies'

import FixedSider from './layout-components/Sider'
import MainLayout from './layout-components/Main'
import Header from './layout-components/Header'
import LogoTitle from './layout-components/LogoTitle'
import Drawer from './layout-components/Drawer'
import Menu from './layout-components/Menu'

const { Content } = Layout

interface IMyLayoutProps {
  collapsed: boolean
}

interface IMyLayoutState {
  collapsed: boolean
  drawerVisible: boolean
}

class MyLayout extends React.Component<IMyLayoutProps, IMyLayoutState> {
  state = {
    collapsed: this.props.collapsed,
    drawerVisible: false,
  }

  toggle = (): void => {
    if (window.innerWidth >= 576) {
      this.setState(
        (state) => ({
          collapsed: !state.collapsed,
        }),
        () => {
          nookies.set({}, 'collapsed', JSON.stringify(this.state.collapsed), {
            path: '/',
          })
        },
      )
    } else {
      this.setState((state) => ({
        drawerVisible: !state.drawerVisible,
      }))
    }
  }

  render(): React.ReactNode {
    const { collapsed, drawerVisible } = this.state
    const { children } = this.props

    return (
      <ConfigProvider locale={ruRU}>
        <Layout
          style={{
            minHeight: '100vh',
          }}
        >
          <FixedSider
            collapsed={collapsed}
            setCollapsed={(collapsed: boolean): void => {
              this.setState({ collapsed } as Pick<
                IMyLayoutState,
                keyof IMyLayoutState
              >)
              nookies.set({}, 'collapsed', JSON.stringify(collapsed), {
                path: '/',
              })
            }}
          >
            <LogoTitle collapsed={collapsed} handleToggle={this.toggle} />
            <Menu
              closeDrawer={(): void =>
                this.setState({ drawerVisible: false } as Pick<
                  IMyLayoutState,
                  keyof IMyLayoutState
                >)
              }
            />
          </FixedSider>

          <MainLayout collapsed={collapsed}>
            <Header />
            <Content
              style={{
                margin: '20px 16px 15px 16px',
              }}
            >
              {children}
            </Content>
          </MainLayout>

          <Drawer
            drawerVisible={drawerVisible}
            closeDrawer={(): void =>
              this.setState({ drawerVisible: false } as Pick<
                IMyLayoutState,
                keyof IMyLayoutState
              >)
            }
          >
            <LogoTitle collapsed={collapsed} handleToggle={this.toggle} />
            <Menu
              style={{ minHeight: '100vh' }}
              closeDrawer={(): void =>
                this.setState({ drawerVisible: false } as Pick<
                  IMyLayoutState,
                  keyof IMyLayoutState
                >)
              }
            />
          </Drawer>
        </Layout>
      </ConfigProvider>
    )
  }
}

export default MyLayout
