import React from 'react'
import styled from 'styled-components'
import { MenuFoldOutlined, MenuUnfoldOutlined } from '@ant-design/icons/lib'
import { Link } from 'src/routes'

const TriggerBlock = styled.div`
  display: inline-block;
  height: 100%;
`

const Title = styled.div`
  display: inline-block;
  color: white;
  font-weight: 600;
  font-size: 20px;
  font-family: 'Arial', serif;
  vertical-align: middle;
`

const TitleWrapper = styled.div`
  position: relative;
  height: 64px;
  overflow: hidden;
  line-height: 64px;
  transition: all 0.3s;
  background: #001529;
`

interface IProps {
  collapsed: boolean
  handleToggle: () => void
}

const LogoTitle: React.FC<IProps> = ({ collapsed, handleToggle }) => (
  <TitleWrapper>
    <TriggerBlock
      className="trigger"
      onClick={handleToggle}
      style={{ fontSize: 20, verticalAlign: 'middle' }}
    >
      {collapsed ? (
        <MenuUnfoldOutlined style={{ color: '#fff' }} />
      ) : (
        <MenuFoldOutlined style={{ color: '#fff' }} />
      )}
    </TriggerBlock>

    <Link route="/companies">
      <a style={{ display: 'inline-block' }}>
        <Title>{process.env.NEXT_PUBLIC_APP_NAME}</Title>
      </a>
    </Link>
  </TitleWrapper>
)
export default LogoTitle
