import { all, fork, put, takeEvery, call } from 'redux-saga/effects'
import { SagaIterator } from '@redux-saga/core'
import { actions } from './slice'
import * as api from './api'
import { formatErrorMessage } from '../../utils/helpers'

function* fetchNewslettersSaga(action: {
  payload: { page: number; status: string }
}): SagaIterator {
  try {
    const { data, meta } = yield call(
      api.fetchNewsletters,
      action.payload.page,
      action.payload.status,
    )
    yield put(actions.fetchSuccess({ data, meta }))
  } catch (error) {
    console.error('Fetch newsletters failed', error)
    yield put(actions.fetchFailure(formatErrorMessage(error)))
  }
}

export function* watchNewslettersSaga(): SagaIterator {
  yield takeEvery(actions.fetch, fetchNewslettersSaga)
}

export default function* watchNewsletters(): SagaIterator {
  yield all([fork(watchNewslettersSaga)])
}
