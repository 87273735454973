import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { NewslettersItemType, NewslettersMetaType } from './types'

export type InitialStateType = {
  items: NewslettersItemType[]
  meta: NewslettersMetaType | null
  isFetching: boolean
  error: string | null
}

export type NewslettersPayload = { page: number; status: 'active' | 'inactive' }
export type NewslettersSuccessPayload = {
  data: NewslettersItemType[]
  meta: NewslettersMetaType
}
export type NewslettersFailurePayload = string | null

const initialState: InitialStateType = {
  items: [],
  meta: null,
  isFetching: false,
  error: null,
}

export const newsletters = createSlice({
  name: 'newsletters',
  initialState,
  reducers: {
    fetch(
      state: InitialStateType,
      _action: PayloadAction<NewslettersPayload>,
    ): void {
      state.isFetching = true
    },
    fetchSuccess(
      state: InitialStateType,
      action: PayloadAction<NewslettersSuccessPayload>,
    ): void {
      state.items = action.payload.data
      state.meta = action.payload.meta
      state.isFetching = false
    },
    fetchFailure(
      state: InitialStateType,
      action: PayloadAction<NewslettersFailurePayload>,
    ): void {
      state.isFetching = false
      state.error = action.payload
    },
  },
})

export const actions = newsletters.actions

export default newsletters.reducer
