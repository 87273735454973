import { all, fork, put, takeEvery, call } from 'redux-saga/effects'
import { SagaIterator } from '@redux-saga/core'
import { actions } from './slice'
import * as api from './api'
import { formatErrorMessage } from '../../utils/helpers'

function* fetchFranchiseesSaga(action: { payload: number }): SagaIterator {
  try {
    const { data, meta } = yield call(api.fetchFranchisees, action.payload)
    yield put(actions.fetchSuccess({ data, meta }))
  } catch (error) {
    console.error('Fetch franchisees failed', error)
    yield put(actions.fetchFailure(formatErrorMessage(error)))
  }
}

export function* watchFetchFranchiseesSaga(): SagaIterator {
  yield takeEvery(actions.fetch, fetchFranchiseesSaga)
}

export default function* watchFranchisee(): SagaIterator {
  yield all([fork(watchFetchFranchiseesSaga)])
}
