import { all, fork, put, takeLatest, call } from 'redux-saga/effects'
import { SagaIterator } from '@redux-saga/core'
import { PayloadAction } from '@reduxjs/toolkit'
import {
  actions,
  FetchAreaSelectorSuccessPayload,
  FetchAreaSelectorPayload,
} from './slice'
import * as api from './api'
import { formatErrorMessage } from '../../utils/helpers'

function* fetchAreaSelectorSaga(
  action: PayloadAction<FetchAreaSelectorPayload>,
): SagaIterator {
  try {
    const areas: FetchAreaSelectorSuccessPayload = yield call(
      api.fetchAreas,
      action.payload.search,
    )
    yield put(actions.fetchSuccess(areas))
  } catch (error) {
    console.error('Fetch areas failed', error)
    yield put(actions.fetchFailure(formatErrorMessage(error)))
  }
}

export function* watchAreaSelectorSaga(): SagaIterator {
  yield takeLatest(actions.fetch, fetchAreaSelectorSaga)
}

export default function* watchAreaSelector(): SagaIterator {
  yield all([fork(watchAreaSelectorSaga)])
}
