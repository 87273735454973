import { all, fork, put, takeEvery, call } from 'redux-saga/effects'
import { PayloadAction } from '@reduxjs/toolkit'
import { SagaIterator } from 'redux-saga'
import {
  actions,
  FetchFranchiseesListSelectorPayload,
  FetchFranchiseesListSelectorSuccessPayload,
  FetchFranchiseeSelectorPayload,
  FetchFranchiseeSelectorSuccessPayload,
} from './slice'
import * as api from './api'
import { formatErrorMessage } from '../../utils/helpers'

function* fetchFranchiseesListSelectorSaga(
  action: PayloadAction<FetchFranchiseesListSelectorPayload>,
): SagaIterator {
  try {
    const franchisees: FetchFranchiseesListSelectorSuccessPayload = yield call(
      api.fetchFranchiseesList,
      action.payload.search,
    )
    yield put(actions.fetchSuccess(franchisees))
  } catch (error) {
    console.error('Fetch franchisees failed', error)
    yield put(actions.fetchFailure(formatErrorMessage(error)))
  }
}

function* watchFranchiseesListSelectorSaga(): SagaIterator {
  yield takeEvery(actions.fetch, fetchFranchiseesListSelectorSaga)
}

function* fetchFranchiseeSelectorSaga(action: {
  payload: FetchFranchiseeSelectorPayload
}): SagaIterator {
  try {
    const franchisee: FetchFranchiseeSelectorSuccessPayload = yield call(
      api.fetchFranchisee,
      action.payload,
    )
    yield put(actions.fetchFranchiseeSuccess(franchisee))
  } catch (error) {
    console.error('Fetch franchisee failed', error)
    yield put(actions.fetchFranchiseeFailure(formatErrorMessage(error)))
  }
}

function* watchFranchiseSelectorSaga(): SagaIterator {
  yield takeEvery(actions.fetchFranchisee, fetchFranchiseeSelectorSaga)
}

export default function* watchFranchiseesSelector(): SagaIterator {
  yield all([
    fork(watchFranchiseesListSelectorSaga),
    fork(watchFranchiseSelectorSaga),
  ])
}
