/* eslint-disable @typescript-eslint/camelcase */
import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import {
  Owner,
  InitialState,
  CompanyFull,
  CompanyStatus,
  PropertyOptionItemType,
} from './types'

const initialState: InitialState = {
  company: null,
  owner: {
    id: '',
    user_id: '',
    last_name: '',
    first_name: '',
  },
  isFetching: true,
  isProcessProperty: false,
  propertyError: '',
}

type InitialStateType = InitialState

const companyMainInfo = createSlice({
  name: 'companyMainInfo',
  initialState,
  reducers: {
    fetchCurrentCompanyWithDetails(
      state: InitialStateType,
      _action: PayloadAction<string>,
    ): void {
      state.isFetching = true
    },
    setCurrentCompanySuccess(
      state: InitialStateType,
      action: PayloadAction<CompanyFull>,
    ): void {
      const data = action.payload

      state.company = data

      state.isFetching = false

      if (!data.businessAccount?.user) {
        return
      }

      const { user_id, last_name, first_name } = data.businessAccount.user

      state.owner = {
        id: user_id,
        user_id: user_id,
        last_name: last_name,
        first_name: first_name,
      }
      state.isFetching = false
    },
    updateOwner(_, _action: PayloadAction<any>): void {
      //
    },
    updateOwnerSuccess(
      state: InitialStateType,
      action: PayloadAction<Owner>,
    ): void {
      const newOwner = action.payload

      state.owner.id = newOwner.user_id
      state.owner.user_id = newOwner.user_id
      state.owner.first_name = newOwner.first_name
      state.owner.last_name = newOwner.last_name
    },
    updateCheckedByMamado(_, _action: PayloadAction<boolean>): void {
      //
    },
    updateCheckedByMamadoSuccess(
      state: InitialStateType,
      action: PayloadAction<boolean>,
    ): void {
      if (state.company) {
        state.company.checked_by_mamado = action.payload
      }
    },
    updatePublicityStatus(_, _action: PayloadAction<boolean>): void {
      //
    },
    updatePublicityStatusSuccess(
      state: InitialStateType,
      action: PayloadAction<boolean>,
    ): void {
      if (state.company) {
        state.company.published = action.payload
      }
    },
    updateStatus(_, _action: PayloadAction<string>): void {
      //
    },
    updateStatusSuccess(
      state: InitialStateType,
      action: PayloadAction<string>,
    ): void {
      if (state.company) {
        state.company.status = action.payload as CompanyStatus
      }
    },
    fetchCompanyContacts(): void {
      //
    },
    fetchCompanyContactsSuccess(
      state: InitialStateType,
      action: PayloadAction<any>,
    ): void {
      if (state.company) {
        state.company.companyContacts = action.payload
      }
    },
    updateCategories(
      _state: InitialStateType,
      _action: PayloadAction<string[]>,
    ): void {
      //
    },
    updateCategoriesSuccess(
      state: InitialStateType,
      action: PayloadAction<any>,
    ): void {
      if (state.company) {
        state.company.companyCategories = action.payload
      }
    },
    updateFeatures(
      _state: InitialStateType,
      _action: PayloadAction<string[]>,
    ): void {
      //
    },
    updateFeaturesSuccess(
      state: InitialStateType,
      action: PayloadAction<any>,
    ): void {
      if (state.company) {
        state.company.companyFeatures = action.payload
      }
    },
    createCompanyProperty(
      state: InitialStateType,
      _action: PayloadAction<{
        company_id: string
        company_property_id: string
        value: string
      }>,
    ): void {
      state.isProcessProperty = true
    },
    createCompanyPropertySuccess(
      state: InitialStateType,
      action: PayloadAction<PropertyOptionItemType>,
    ): void {
      state.isProcessProperty = false
      if (state.company) {
        state.company.companyPropertyOptionValues.push(action.payload)
      }
    },
    createCompanyPropertyFailure(
      state: InitialStateType,
      action: PayloadAction<string>,
    ): void {
      state.isProcessProperty = false
      state.propertyError = action.payload
    },
    updateCompanyProperty(
      state: InitialStateType,
      _action: PayloadAction<{
        company_id: string
        company_property_option_value_id: string
        company_property_id: string
        value: string
      }>,
    ): void {
      state.isProcessProperty = true
    },
    updateCompanyPropertySuccess(
      state: InitialStateType,
      action: PayloadAction<PropertyOptionItemType>,
    ): void {
      state.isProcessProperty = false
      if (state.company) {
        state.company.companyPropertyOptionValues = state.company.companyPropertyOptionValues.map(
          (i) => {
            if (
              i.company_property_option_value_id ===
              action.payload.company_property_option_value_id
            ) {
              return action.payload
            }
            return i
          },
        )
      }
    },
    updateCompanyPropertyFailure(
      state: InitialStateType,
      action: PayloadAction<string>,
    ): void {
      state.isProcessProperty = false
      state.propertyError = action.payload
    },
    removeCompanyProperty(
      state: InitialStateType,
      _action: PayloadAction<{
        company_id: string
        company_property_option_value_id: string
      }>,
    ): void {
      state.isProcessProperty = true
    },
    removeCompanyPropertySuccess(
      state: InitialStateType,
      action: PayloadAction<string>,
    ): void {
      state.isProcessProperty = false
      if (state.company) {
        state.company.companyPropertyOptionValues = state.company.companyPropertyOptionValues.filter(
          (i) => i.company_property_option_value_id !== action.payload,
        )
      }
    },
    removeCompanyPropertyFailure(
      state: InitialStateType,
      action: PayloadAction<string>,
    ): void {
      state.isProcessProperty = false
      state.propertyError = action.payload
    },
    refreshCompany(
      _state: InitialStateType,
      _action: PayloadAction<string>,
    ): void {},
  },
})

export const actions = companyMainInfo.actions

export default companyMainInfo.reducer
