import { AxiosResponse } from 'axios'
import { get, post, patch, del } from '../../infrastructure/api'
import { EditFormType, AddVideoPayload, FetchContentPayload } from './types'

export const fetchContent = (
  pageId: FetchContentPayload,
): Promise<AxiosResponse> =>
  get(`/admin/pages/${pageId}`, {
    include: 'image,gallery,videos,targetPage,targetCategory',
  })

export const editContent = (
  pageId: string,
  data: EditFormType,
): Promise<AxiosResponse> => patch(`/admin/pages/${pageId}`, {}, data)

export const sendContentVideo = (
  data: AddVideoPayload,
): Promise<AxiosResponse> =>
  post(`/admin/pages/${data.pageId}/videos`, {}, { videos: [data.video] })

export const deleteMedia = (
  pageId: string,
  mediaId: number,
  type: 'videos' | 'gallery',
): Promise<AxiosResponse> => del(`/admin/pages/${pageId}/${type}/${mediaId}`)

export const reOrderMedia = (
  pageId: string,
  mediaIds: number[],
  type: 'gallery' | 'videos',
): Promise<AxiosResponse> =>
  patch(`/admin/pages/${pageId}/${type}`, {}, { ids: mediaIds })
