/* eslint-disable @typescript-eslint/camelcase */
import * as DB from '../../infrastructure/DB'
import { CompanyFull } from './types'

const includeArray: string[] = [
  'businessAccount.user',
  'gallery',
  'videos',
  'events',
  'eventsCount',
  'parent',
  'children',
  'siblings',
  'subwayStation',
  'companyTimetables',
  'companyCategories',
  'companyFeatures',
  'companyContacts',
  'area',
  'logo',
  'companyAreas',
  'activePlanSubscription',
  'companyPropertyOptionValues',
  'companyPropertyOptionValues.companyProperty',
]

const include = includeArray.join(',')

export const fetchCompanyContacts = async (companyId: string): Promise<any> => {
  const url = `/admin/companies/${companyId}/contacts`

  return await DB.get(url)
}

export const fetchCompanyWithDetails = async (
  companyId: string,
): Promise<CompanyFull> => {
  const url = `/admin/companies/${companyId}`
  const params = { include }

  return await DB.get(url, params)
}

export const updateOwner = async (
  companyId: string,
  ownerId: string | null,
): Promise<void> => {
  const url = `/admin/companies/${companyId}/owner`
  const data = {
    user_id: ownerId,
  }

  const successMessage = 'Владелец обнавлён'
  const errorMessage = 'Не удалось обновить владельца'

  await DB.patch(url, data, successMessage, errorMessage)
}

export const updateStatus = async (
  companyId: string,
  value: string,
): Promise<void> => {
  const url = `/admin/companies/${companyId}`
  const data = {
    status: value,
  }

  const successMessage = 'Статус публикации обновлен'
  const errorMessage = 'Не удалось обновить статус публикации'

  await DB.patch(url, data, successMessage, errorMessage)
}

export const updateCategories = async (
  companyId: string,
  ids: string[],
): Promise<void> => {
  const url = `/admin/companies/${companyId}/categories`
  const data = {
    ids,
  }

  return DB.post(url, data, undefined, 'Не удалось обновить категории')
}

export const updateFeatures = async (
  companyId: string,
  ids: string[],
): Promise<void> => {
  const url = `/admin/companies/${companyId}/features`
  const data = {
    ids,
  }

  return DB.post(url, data, undefined, 'Не удалось обновить особенности')
}

export const updatePublicityStatus = async (
  companyId: string,
  value: boolean,
): Promise<void> => {
  const url = `/admin/companies/${companyId}`
  const data = {
    published: value,
  }

  const successMessage = 'Статус публикации обновлен'
  const errorMessage = 'Не удалось обновить статус публикации'

  await DB.patch(url, data, successMessage, errorMessage)
}

export const updateCheckedByMamado = async (
  companyId: string,
  value: boolean,
): Promise<void> => {
  const url = `/admin/companies/${companyId}`
  const data = {
    checked_by_mamado: value,
  }

  const successMessage = 'Статус проверки обновлен'
  const errorMessage = 'Не удалось обновить статус проверки'

  await DB.patch(url, data, successMessage, errorMessage)
}

export const createCompanyProperty = async (
  companyId: string,
  companyPropertyId: string,
  selectedValue: string,
): Promise<void> => {
  const url = `/admin/companies/${companyId}/properties`
  const data = {
    company_property_id: companyPropertyId,
    selected_value: selectedValue,
  }

  const successMessage = 'Параметр компании создан'
  const errorMessage = 'Не удалось создать параметр компании'

  return await DB.post(url, data, successMessage, errorMessage)
}

export const updateCompanyProperty = async (
  companyId: string,
  companyPropertyOptionValueId: string,
  companyPropertyId: string,
  selectedValue: string,
): Promise<void> => {
  const url = `/admin/companies/${companyId}/properties/${companyPropertyOptionValueId}`
  const data = {
    company_property_id: companyPropertyId,
    selected_value: selectedValue,
  }

  const successMessage = 'Параметр компании изменен'
  const errorMessage = 'Не удалось изменить параметр компании'

  return await DB.patch(url, data, successMessage, errorMessage)
}

export const removeCompanyProperty = async (
  companyId: string,
  companyPropertyOptionValueId: string,
): Promise<void> => {
  const url = `/admin/companies/${companyId}/properties/${companyPropertyOptionValueId}`

  const successMessage = 'Параметр компании удален'
  const errorMessage = 'Не удалось удалить параметр компании'

  await DB.remove(url, undefined, successMessage, errorMessage)
}
