import { get } from '../../infrastructure/api'
import { IAnalytics } from './types'

export const fetchAnalytics = (
  companyId: string,
  date_start: string,
  date_end: string,
): Promise<IAnalytics[]> =>
  get('/admin/analytics/company', {
    ids: companyId,
    date_start,
    date_end,
  })
