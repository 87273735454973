import { all, fork, put, takeEvery, call, select } from 'redux-saga/effects'
import { SagaIterator } from '@redux-saga/core'
import { actions } from './slice'
import * as api from './api'
import { formatErrorMessage } from '../../utils/helpers'
import { InitialStateType, PageItemType } from './types'

const getPageSelector = (state: { content: InitialStateType }): number =>
  state.content.meta ? state.content.meta.currentPage : 1

const getContentsSelector = (state: {
  content: InitialStateType
}): PageItemType[] => state.content.pages

function* fetchPagesSaga(action: { payload: number }): SagaIterator {
  try {
    const pages = yield call(api.fetchPages, action.payload)
    yield put(actions.fetchSuccess(pages))
  } catch (error) {
    console.error('Fetch pages failed', error)
    yield put(actions.fetchFailure(formatErrorMessage(error)))
  }
}

function* removeContentSaga(action: { payload: string }): SagaIterator {
  try {
    yield call(api.removeContent, action.payload)
    const page = yield select(getPageSelector)
    const contents = yield select(getContentsSelector)
    if (contents.length === 1 && page > 1) {
      yield put(actions.fetch(page - 1))
    } else {
      yield put(actions.fetch(page))
    }
    yield put(actions.removeSuccess())
  } catch (error) {
    console.error('Remove page failed', error)
    yield put(actions.removeFailure(formatErrorMessage(error)))
  }
}

export function* watchFetchPagesSaga(): SagaIterator {
  yield takeEvery(actions.fetch, fetchPagesSaga)
}

export function* watchRemoveContentSaga(): SagaIterator {
  yield takeEvery(actions.remove, removeContentSaga)
}

export default function* watchPages(): SagaIterator {
  yield all([fork(watchFetchPagesSaga), fork(watchRemoveContentSaga)])
}
