/* eslint-disable @typescript-eslint/camelcase */
import { Moment } from 'moment'
import { User } from 'src/types/user'
import { DaDataAddress, DaDataSuggestion } from 'react-dadata'
import { ProfileType } from '../profile/types'
import { PropertySectorItem } from '../companyPropertySelector/types'
import { InitialStateType as PlansInitialStateType } from '../plans/types'

export type SearchInput = {
  value: string
  label: string
}

export type Name = {
  label: string
  value?: string | null
}

export type AreaSearch = {
  label: string
  value: SearchInput | null
}

export type Owner = {
  label: string
  value: null | SearchInput
}

export type BusinessAccount = {
  label: string
  value: null | SearchInput
}

export type Franchisee = {
  label: string
  value: null | SearchInput
}

type SelectorOptionType = {
  label: string
  value: boolean | string | null
}

export type ValueSelector = {
  label: string
  value: string | null
  options: SelectorOptionType[]
}

export type DateRangePicker = {
  label: string
  formatToDisplay: string
  value: {
    dateStart: Moment
    dateEnd: Moment
  } | null
}

export type DispatchPayload = {
  formField: FormFields
  value:
    | string
    | DatePickerPayload
    | DaDataSuggestion<DaDataAddress>
    | User
    | null
}

export type SearchParamsToSend = PaginationParams & FormFields

export type FetchUserSelectorPayload = {
  pagination: PaginationParams
  filters: FiltersType
}

export type PaginationParams = {
  total: number
  perPage: number
  lastPage: number
  currentPage: number
}

export enum FormFields {
  area_id = 'area_id',
  owner_id = 'owner_id',
  business_account_id = 'business_account_id',
  has_owner = 'has_owner',
  published = 'published',
  checked = 'checked',
  type = 'type',
  has_events = 'has_events',
  empty_about = 'empty_about',
  has_premium = 'has_premium',
  created = 'created',
  name = 'name',
  franchisee_id = 'franchisee_id',
  check_status = 'check_status',
  formalization_status = 'formalization_status',
  subscription_range = 'subscription_range',
  subscription_active = 'subscription_active',
  plan_subscription_id = 'plan_subscription_id',
}

export type MapStateToPropsType = {
  fields: InitialStateType
  profile?: ProfileType
  companyPropertySelector: PropertySectorItem[]
  plans?: PlansInitialStateType
}

export type MapDispatchToPropsType = {
  setField: (data: DispatchPayload) => void
  searchCompanies: () => void
  setThePagination: (data: any) => void
}

type FiltersType = {
  [FormFields.name]: Name
  [FormFields.owner_id]: Owner
  [FormFields.business_account_id]: BusinessAccount
  [FormFields.has_owner]: ValueSelector
  [FormFields.published]: ValueSelector
  [FormFields.has_premium]: ValueSelector
  [FormFields.area_id]: AreaSearch
  [FormFields.has_events]: ValueSelector
  [FormFields.empty_about]: ValueSelector
  [FormFields.created]: DateRangePicker
  [FormFields.checked]: ValueSelector
  [FormFields.type]: ValueSelector
  [FormFields.franchisee_id]: Franchisee
  [FormFields.check_status]: Franchisee
  [FormFields.formalization_status]: Franchisee
  [FormFields.subscription_range]: DateRangePicker
  [FormFields.subscription_active]: DateRangePicker
  [FormFields.plan_subscription_id]: ValueSelector
}

export type InitialStateType = {
  [FormFields.name]: Name
  [FormFields.owner_id]: Owner
  [FormFields.business_account_id]: BusinessAccount
  [FormFields.has_owner]: ValueSelector
  [FormFields.published]: ValueSelector
  [FormFields.has_premium]: ValueSelector
  [FormFields.area_id]: AreaSearch
  [FormFields.has_events]: ValueSelector
  [FormFields.empty_about]: ValueSelector
  [FormFields.created]: DateRangePicker
  [FormFields.checked]: ValueSelector
  [FormFields.type]: ValueSelector
  [FormFields.franchisee_id]: Franchisee
  [FormFields.check_status]: Franchisee
  [FormFields.formalization_status]: Franchisee
  [FormFields.subscription_range]: DateRangePicker
  [FormFields.subscription_active]: DateRangePicker
  [FormFields.plan_subscription_id]: ValueSelector
}

export type DatePickerPayload = {
  dateRange: {
    dateStart: string
    dateEnd: string
  }
}

export type SelectorPayload = {
  input: string
}

export type ComponentPropTypes = MapStateToPropsType & MapDispatchToPropsType
