import { get } from '../../infrastructure/api'
import { FetchEventPropertySelectorPayload } from './slice'

export const fetchEventPropertySelector = (): Promise<FetchEventPropertySelectorPayload> => {
  return get('/admin/events/properties', {
    includes:
      'companyPropertyOptionValues,companyPropertyOptionValues.companyProperty',
  })
}

export const fetchOptions = (
  id: string,
): Promise<FetchEventPropertySelectorPayload> => {
  return get(`/admin/events/properties/${id}/options`)
}
