import { PayloadAction } from '@reduxjs/toolkit'
import { all, fork, put, call, select, takeEvery } from 'redux-saga/effects'
import { RootState } from 'src/store/reducers'

import * as api from './api'
import { actions } from './slice'
import { CompanyFull } from './types'
import { formatErrorMessage } from '../../utils/helpers'
import { SagaIterator } from '@redux-saga/core'

const getCurrentCompanyId = (state: RootState): string | undefined =>
  state.companyMainInfo.company?.company_id

function* handleUpdateOwner(action: any): any {
  try {
    const { companyId, owner } = action.payload

    yield call(api.updateOwner, companyId, owner?.user_id)
    yield put(actions.updateOwnerSuccess(owner))
  } catch (error) {
    //
  }
}

function* handleUpdateCheckedByMamado(action: PayloadAction<boolean>): any {
  try {
    const companyId = yield select(getCurrentCompanyId)
    const value = action.payload

    yield call(api.updateCheckedByMamado, companyId, value)
    yield put(actions.updateCheckedByMamadoSuccess(value))
  } catch (error) {
    //
  }
}

function* handleUpdatePublicityStatus(action: PayloadAction<boolean>): any {
  try {
    const companyId = yield select(getCurrentCompanyId)
    const value = action.payload

    yield call(api.updatePublicityStatus, companyId, value)
    yield put(actions.updatePublicityStatusSuccess(value))
  } catch (error) {
    //
  }
}

function* handleUpdateStatus(action: PayloadAction<string>): any {
  try {
    const companyId = yield select(getCurrentCompanyId)
    const value = action.payload

    yield call(api.updateStatus, companyId, value)
    yield put(actions.updateStatusSuccess(value))
  } catch (error) {
    //
  }
}

function* handleUpdateCategories(action: PayloadAction<string[]>): any {
  try {
    const companyId = yield select(getCurrentCompanyId)

    const company = yield call(api.updateCategories, companyId, action.payload)
    yield put(actions.updateCategoriesSuccess(company.companyCategories))
    yield put(actions.updateFeaturesSuccess(company.companyFeatures))
  } catch (error) {
    //
  }
}

function* handleUpdateFeatures(action: PayloadAction<string[]>): any {
  try {
    const companyId = yield select(getCurrentCompanyId)

    const company = yield call(api.updateFeatures, companyId, action.payload)
    yield put(actions.updateFeaturesSuccess(company.companyFeatures))
  } catch (error) {
    //
  }
}

function* handleFetchCompanyWithDetails(action: PayloadAction<string>): any {
  try {
    const companyId = action.payload

    const companyWithDetails: CompanyFull = yield call(
      api.fetchCompanyWithDetails,
      companyId,
    )

    if (companyWithDetails)
      yield put(actions.setCurrentCompanySuccess(companyWithDetails))
  } catch (error) {
    //
  }
}

function* handleFetchCompanyContacts(): any {
  try {
    const companyId = yield select(getCurrentCompanyId)

    const companyContacts = yield call(api.fetchCompanyContacts, companyId)

    if (companyContacts)
      yield put(actions.fetchCompanyContactsSuccess(companyContacts.reverse()))
  } catch (error) {
    console.error('handleFetchCompanyContacts error', error)
  }
}

function* handleCreateCompanyProperty(action: {
  payload: { company_id: string; company_property_id: string; value: string }
}): SagaIterator {
  try {
    const { company_id, company_property_id, value } = action.payload

    const companyProperty = yield call(
      api.createCompanyProperty,
      company_id,
      company_property_id,
      value,
    )
    if (companyProperty) {
      yield put(actions.createCompanyPropertySuccess(companyProperty))
    }
  } catch (error) {
    console.error('handleCreateCompanyProperty error', error)
    yield put(actions.createCompanyPropertyFailure(formatErrorMessage(error)))
  }
}

function* handleUpdateCompanyProperty(action: {
  payload: {
    company_id: string
    company_property_option_value_id: string
    company_property_id: string
    value: string
  }
}): SagaIterator {
  try {
    const {
      company_id,
      company_property_option_value_id,
      company_property_id,
      value,
    } = action.payload

    const companyProperty = yield call(
      api.updateCompanyProperty,
      company_id,
      company_property_option_value_id,
      company_property_id,
      value,
    )

    if (companyProperty) {
      yield put(actions.updateCompanyPropertySuccess(companyProperty))
    }
  } catch (error) {
    console.error('handleUpdateCompanyProperty error', error)
    yield put(actions.updateCompanyPropertyFailure(formatErrorMessage(error)))
  }
}

function* handleRemoveCompanyProperty(action: {
  payload: { company_id: string; company_property_option_value_id: string }
}): SagaIterator {
  try {
    const { company_id, company_property_option_value_id } = action.payload

    yield call(
      api.removeCompanyProperty,
      company_id,
      company_property_option_value_id,
    )

    yield put(
      actions.removeCompanyPropertySuccess(
        action.payload.company_property_option_value_id,
      ),
    )
  } catch (error) {
    console.error('handleRemoveCompanyProperty error', error)
    yield put(actions.removeCompanyPropertyFailure(formatErrorMessage(error)))
  }
}

function* watchFetchCompanyWithDetails(): any {
  yield takeEvery(
    actions.fetchCurrentCompanyWithDetails,
    handleFetchCompanyWithDetails,
  )
}

function* watchFetchCompanyContacts(): any {
  yield takeEvery(actions.fetchCompanyContacts, handleFetchCompanyContacts)
}

function* watchUpdateStatus(): any {
  yield takeEvery(actions.updateStatus, handleUpdateStatus)
}

function* watchUpdatePublicityStatus(): any {
  yield takeEvery(actions.updatePublicityStatus, handleUpdatePublicityStatus)
}

function* watchUpdateCompanyMainInfo(): any {
  yield takeEvery(actions.updateOwner, handleUpdateOwner)
}

function* watchUpateCheckedByMamado(): any {
  yield takeEvery(actions.updateCheckedByMamado, handleUpdateCheckedByMamado)
}

function* watchUpdateCategories(): any {
  yield takeEvery(actions.updateCategories, handleUpdateCategories)
}

function* watchUpdateFeatures(): any {
  yield takeEvery(actions.updateFeatures, handleUpdateFeatures)
}

function* watchRefreshCompanyWithDetails(): any {
  yield takeEvery(actions.refreshCompany, handleFetchCompanyWithDetails)
}

function* watchCreateCompanyProperty(): any {
  yield takeEvery(actions.createCompanyProperty, handleCreateCompanyProperty)
}

function* watchUpdateCompanyProperty(): any {
  yield takeEvery(actions.updateCompanyProperty, handleUpdateCompanyProperty)
}

function* watchRemoveCompanyProperty(): any {
  yield takeEvery(actions.removeCompanyProperty, handleRemoveCompanyProperty)
}

export default function* watchUserSelector(): any {
  yield all([
    fork(watchUpdateStatus),
    fork(watchUpateCheckedByMamado),
    fork(watchUpdateCompanyMainInfo),
    fork(watchUpdatePublicityStatus),
    fork(watchUpdateCategories),
    fork(watchUpdateFeatures),
    fork(watchFetchCompanyWithDetails),
    fork(watchFetchCompanyContacts),
    fork(watchRefreshCompanyWithDetails),
    fork(watchCreateCompanyProperty),
    fork(watchUpdateCompanyProperty),
    fork(watchRemoveCompanyProperty),
  ])
}
