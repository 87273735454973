import { all, fork, put, takeEvery, call } from 'redux-saga/effects'
import { SagaIterator } from '@redux-saga/core'
import { actions } from './slice'
import * as api from './api'
import { formatErrorMessage } from '../../utils/helpers'
import {
  CreateNotificationPayload,
  EditNotificationPayload,
  FetchNotificationPayload,
  FetchCountUsersPayload,
  UploadImageNotificationPayload,
} from './types'
import { PayloadAction } from '@reduxjs/toolkit'
import { Router } from '../../routes'
import { notification } from 'antd'

function* createNotificationSaga(
  action: PayloadAction<CreateNotificationPayload>,
): SagaIterator {
  try {
    const notification = yield call(api.createNotification, action.payload.data)
    yield put(actions.createSuccess())
    if (action.payload.imageFile) {
      yield put(
        actions.uploadImage({
          notificationId: notification.notification_task_id,
          image: action.payload.imageFile,
          status: notification.status,
        }),
      )
    } else {
      Router.pushRoute(`/newsletters/${notification.status}`)
    }
  } catch (error) {
    console.error('Create notification failed', error)
    yield put(
      actions.createFailure({
        error: 'Произошла ошибка',
        fields: error.response.data?.errors,
      }),
    )
  }
}

function* editNotificationSaga(
  action: PayloadAction<EditNotificationPayload>,
): SagaIterator {
  try {
    const notification = yield call(
      api.editNotification,
      action.payload.notificationId,
      action.payload.data,
    )
    yield put(actions.editSuccess())
    if (action.payload.imageFile) {
      yield put(
        actions.uploadImage({
          notificationId: notification.notification_task_id,
          image: action.payload.imageFile,
          status: notification.status,
        }),
      )
    } else {
      Router.pushRoute(`/newsletters/${notification.status}`)
    }
  } catch (error) {
    console.error('Edit notification failed', error)
    yield put(
      actions.editFailure({
        error: 'Произошла ошибка',
        fields: error.response.data?.errors,
      }),
    )
  }
}

function* uploadImageNotificationSaga(
  action: PayloadAction<UploadImageNotificationPayload>,
): SagaIterator {
  try {
    yield call(
      api.uploadImageNotification,
      action.payload.notificationId,
      action.payload.image,
    )
    yield put(actions.uploadImageSuccess())
    Router.pushRoute(`/newsletters/${action.payload.status}`)
  } catch (error) {
    console.error('Upload image notification failed', error)
    yield put(actions.uploadImageFailure(formatErrorMessage(error)))
  }
}

function* fetchCompanyCategoriesSaga(): SagaIterator {
  try {
    const categories = yield call(api.fetchCompanyCategories)
    yield put(actions.fetchCompanyCategoriesSuccess(categories))
  } catch (error) {
    console.error('Fetch company categories failed', error)
    yield put(actions.fetchCompanyCategoriesFailure(formatErrorMessage(error)))
  }
}

function* fetchNotificationSaga(
  action: PayloadAction<FetchNotificationPayload>,
): SagaIterator {
  try {
    const notification = yield call(api.fetchNotification, action.payload)
    yield put(actions.fetchSuccess(notification))
  } catch (error) {
    console.error('Fetch notification failed', error)
    yield put(actions.fetchFailure(formatErrorMessage(error)))
  }
}

function* fetchCountUsersSaga(
  action: PayloadAction<FetchCountUsersPayload>,
): SagaIterator {
  try {
    const countUsers = yield call(api.getCountUsers, action.payload)
    yield put(actions.fetchCountUsersSuccess(countUsers))
  } catch (error) {
    console.error('Get count users failed', error)
    yield put(
      actions.fetchCountUsersFailure({
        error: 'Произошла ошибка при подсчете пользователей',
        fields: error.response.data?.errors,
      }),
    )
  }
}

function* executeMomentTaskSaga(action: PayloadAction<string>): SagaIterator {
  try {
    yield call(api.executeMomentTask, action.payload)
    yield put(actions.executeMomentTaskSuccess())
    notification.success({
      message: 'Отправка рассылки выполнена',
    })
  } catch (error) {
    console.error('Execute moment task failed', error)
    const formatError = formatErrorMessage(error)
    notification.warning({
      message: 'Произошла ошибка, при отправке рассылки',
      description: formatError,
    })
    yield put(actions.executeMomentTaskFailure(formatError))
  }
}

function* fetchNotificationInstances(action: {
  payload: { notification_id: string; page: number }
}): SagaIterator {
  try {
    const { data, meta } = yield call(
      api.fetchNotificationInstances,
      action.payload.notification_id,
      action.payload.page,
    )
    yield put(actions.fetchNotificationInstancesSuccess({ data, meta }))
  } catch (error) {
    console.error('Fetch notification instances failed', error)
    const formatError = formatErrorMessage(error)
    notification.warning({
      message: 'Произошла ошибка, при запросе истории отправок',
      description: formatError,
    })
    yield put(actions.fetchNotificationInstancesFailure(formatError))
  }
}

export function* watchCreateNotificationSaga(): SagaIterator {
  yield takeEvery(actions.create, createNotificationSaga)
}

export function* watchEditNotificationSaga(): SagaIterator {
  yield takeEvery(actions.edit, editNotificationSaga)
}

export function* watchUploadImageNotificationSaga(): SagaIterator {
  yield takeEvery(actions.uploadImage, uploadImageNotificationSaga)
}

export function* watchFetchNotificationSaga(): SagaIterator {
  yield takeEvery(actions.fetch, fetchNotificationSaga)
}

export function* watchFetchCompanyCategoriesSaga(): SagaIterator {
  yield takeEvery(actions.fetchCompanyCategories, fetchCompanyCategoriesSaga)
}

export function* watchFetchCountUsersSaga(): SagaIterator {
  yield takeEvery(actions.fetchCountUsers, fetchCountUsersSaga)
}

export function* watchExecuteMomentTaskSaga(): SagaIterator {
  yield takeEvery(actions.executeMomentTask, executeMomentTaskSaga)
}

export function* watchFetchNotificationInstancesSaga(): SagaIterator {
  yield takeEvery(
    actions.fetchNotificationInstances,
    fetchNotificationInstances,
  )
}

export default function* watchNewsletterWizard(): SagaIterator {
  yield all([
    fork(watchCreateNotificationSaga),
    fork(watchEditNotificationSaga),
    fork(watchUploadImageNotificationSaga),
    fork(watchFetchCompanyCategoriesSaga),
    fork(watchFetchNotificationSaga),
    fork(watchFetchCountUsersSaga),
    fork(watchExecuteMomentTaskSaga),
    fork(watchFetchNotificationInstancesSaga),
  ])
}
