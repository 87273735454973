import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { NotificationInstanceType } from '../newsletterWizard/types'
import { NewslettersMetaType } from '../newsletters/types'
import {
  FetchTasksFailurePayload,
  FetchTasksPayload,
  FetchTasksSuccessPayload,
} from './types'

export type InitialStateType = {
  tasks: NotificationInstanceType[]
  isFetching: boolean
  meta: NewslettersMetaType | null
  error: string | null
}

const initialState: InitialStateType = {
  tasks: [],
  isFetching: false,
  meta: null,
  error: null,
}

export const newsletterTasksSlice = createSlice({
  name: 'newsletterTasks',
  initialState,
  reducers: {
    fetchTasks(
      state: InitialStateType,
      _action: PayloadAction<FetchTasksPayload>,
    ): void {
      state.isFetching = true
    },
    fetchTasksSuccess(
      state: InitialStateType,
      action: PayloadAction<FetchTasksSuccessPayload>,
    ): void {
      state.tasks = action.payload.data
      state.meta = action.payload.meta
      state.isFetching = false
    },
    fetchTasksFailure(
      state: InitialStateType,
      action: PayloadAction<FetchTasksFailurePayload>,
    ): void {
      state.isFetching = false
      state.error = action.payload
    },
  },
})

export const actions = newsletterTasksSlice.actions

export default newsletterTasksSlice.reducer
