import { AxiosResponse } from 'axios'
import { get } from '../../infrastructure/api'

export const fetchNewsletters = (
  page: number,
  status: string,
): Promise<AxiosResponse> => {
  return get('/admin/notification-tasks', {
    include: ['creator'],
    'filter[status]': status,
    page,
    page_size: 20,
  })
}
