import * as DB from '../../infrastructure/DB'
import { AxiosResponse } from 'axios'

export const getPayments = async (
  companyId: string,
  page: number,
  perPage: number,
): Promise<AxiosResponse> => {
  const url = `/admin/order-products`
  const params = {
    'filter[company_id]': companyId,
    page: page,
    count: perPage,
  }

  return DB.get(url, params)
}
