import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import {
  IntegrationsType,
  IntegrationKeys,
  SendAuthorizationType,
} from './types'
import Services from './services'

export type InitialStateType = {
  integrations: IntegrationsType
}

export type FetchIntegrationPayload = IntegrationKeys
export type FetchIntegrationSuccessPayload = {
  key: IntegrationKeys
  status: boolean
}
export type FetchIntegrationFailurePayload = {
  key: IntegrationKeys
  error: null | string
}

export type SendAuthorizationPayload = SendAuthorizationType
export type SendAuthorizationSuccessPayload = {
  key: IntegrationKeys
}
export type SendAuthorizationFailurePayload = {
  key: IntegrationKeys
  error: null | string
}

const initialState: InitialStateType = {
  integrations: Services,
}

export const integrationsSlice = createSlice({
  name: 'integrations',
  initialState,
  reducers: {
    fetch(
      state: InitialStateType,
      action: PayloadAction<FetchIntegrationPayload>,
    ): void {
      state.integrations[action.payload].error = ''
      state.integrations[action.payload].isFetching = true
    },
    fetchSuccess(
      state: InitialStateType,
      action: PayloadAction<FetchIntegrationSuccessPayload>,
    ): void {
      state.integrations[action.payload.key].isFetching = false
      state.integrations[action.payload.key].isIntegrated =
        action.payload.status
    },
    fetchFailure(
      state: InitialStateType,
      action: PayloadAction<FetchIntegrationFailurePayload>,
    ): void {
      state.integrations[action.payload.key].isFetching = false
      state.integrations[action.payload.key].error = action.payload.error
    },
    sendAuthorization(
      state: InitialStateType,
      action: PayloadAction<SendAuthorizationPayload>,
    ): void {
      state.integrations[action.payload.key].isFetching = true
    },
    sendAuthorizationSuccess(
      state: InitialStateType,
      action: PayloadAction<SendAuthorizationSuccessPayload>,
    ): void {
      state.integrations[action.payload.key].isFetching = false
    },
    sendAuthorizationFailure(
      state: InitialStateType,
      action: PayloadAction<SendAuthorizationFailurePayload>,
    ): void {
      state.integrations[action.payload.key].isFetching = false
      state.integrations[action.payload.key].error = action.payload.error
    },
    fetchAll(): void {},
  },
})

export const actions = integrationsSlice.actions

export default integrationsSlice.reducer
