import { AxiosResponse } from 'axios'
import { get, post, patch, del } from '../../infrastructure/api'
import { FormItemsType } from './types'

export const fetchBlocks = (page: number): Promise<AxiosResponse> =>
  get('/admin/blocks', {
    page,
    include: 'banners',
  })

export const fetchBanners = (): Promise<AxiosResponse> =>
  get('/admin/banners', { page_size: 10000 })

export const createBlock = (data: FormItemsType): Promise<AxiosResponse> =>
  post('/admin/blocks', {}, data)

export const editBlock = (
  blockId: string,
  data: FormItemsType,
): Promise<AxiosResponse> => patch(`/admin/blocks/${blockId}`, {}, data)

export const removeBlock = (blockId: string): Promise<AxiosResponse> =>
  del(`/admin/blocks/${blockId}`)
