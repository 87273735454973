import { notification } from 'antd'
import { AxiosError } from 'axios'
import { IconType } from 'antd/lib/notification'

import Axios from './axios'
import _ from 'lodash'

export const patch = async (
  url: string,
  data?: any,
  showSuccessMessage?: string,
  showErrorMessage?: string,
): Promise<any> => {
  try {
    await Axios.patch(url, data)
    handleStatusNotifications('success', showSuccessMessage)
  } catch (err) {
    handleStatusNotifications(
      'error',
      formatMessageFromError(showErrorMessage, err),
    )
  }
}

export const get = async (
  url: string,
  params?: any,
  showSuccessMessage?: string,
  showErrorMessage?: string,
): Promise<any> => {
  try {
    const response = await Axios.get(url, { params })

    handleStatusNotifications('success', showSuccessMessage)

    return response.data
  } catch (err) {
    handleStatusNotifications(
      'error',
      formatMessageFromError(showErrorMessage, err),
    )
  }
}

export const post = async (
  url: string,
  data?: any,
  showSuccessMessage?: string,
  showErrorMessage?: string,
): Promise<any> => {
  try {
    const response = await Axios.post(url, data)

    handleStatusNotifications('success', showSuccessMessage)

    return response.data
  } catch (err) {
    handleStatusNotifications(
      'error',
      formatMessageFromError(showErrorMessage, err),
    )
  }
}

export const remove = async (
  url: string,
  data?: any,
  showSuccessMessage?: string,
  showErrorMessage?: string,
): Promise<any> => {
  try {
    const config = { data }
    const response = await Axios.delete(url, config)

    handleStatusNotifications('success', showSuccessMessage)

    return response.data
  } catch (err) {
    handleStatusNotifications(
      'error',
      formatMessageFromError(showErrorMessage, err),
    )
  }
}

const handleStatusNotifications = (type: IconType, message?: string): void => {
  if (message) {
    notification.open({
      type,
      message,
      duration: 10,
    })
  }
}

const formatMessageFromError = (
  message: string | undefined,
  error: AxiosError,
): string | undefined => {
  if (!message) {
    return
  }
  let result = message
  if (error.response && error.response.data && error.response.data.errors) {
    if (_.isArray(error.response.data.errors.content)) {
      result += `: ${error.response.data.errors.content.join('; ')}`
    } else {
      result += `: ${Object.values(error.response.data.errors)}`
    }
  }
  return result
}
