import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import {
  CreateNotificationPayload,
  CreateNotificationSuccessPayload,
  CreateNotificationFailurePayload,
  FetchNotificationPayload,
  FetchNotificationSuccessPayload,
  FetchNotificationFailurePayload,
  EditNotificationPayload,
  EditNotificationSuccessPayload,
  EditNotificationFailurePayload,
  NewsletterWizardDestroyPayload,
  NotificationItemType,
  UploadImageNotificationPayload,
  UploadImageNotificationSuccessPayload,
  UploadImageNotificationFailurePayload,
  CompanyCategoryItemType,
  FetchCountUsersPayload,
  FetchCountUsersSuccessPayload,
  FetchCountUsersFailurePayload,
  NotificationInstanceType,
  NotificationInstancesPayload,
  NotificationInstancesSuccessPayload,
  NotificationInstancesFailurePayload,
} from './types'
import { NewslettersMetaType } from '../newsletters/types'

export type InitialStateType = {
  currentNotification: NotificationItemType | null
  companyCategories: CompanyCategoryItemType[]
  isFetching: boolean
  isFetchingCompanyCategories: boolean
  isCreating: boolean
  isUploadImage: boolean
  wasLoaded: boolean
  countUsers: number
  isFetchingCountUsers: boolean
  isFetchingExecuteMomentTask: boolean
  isModalMomentum: boolean
  notificationInstances: NotificationInstanceType[]
  notificationInstancesIsFetching: boolean
  notificationInstancesMeta: null | NewslettersMetaType
  error: string | null
  errorFields: string[]
}

const initialState: InitialStateType = {
  currentNotification: null,
  companyCategories: [],
  isFetching: false,
  isFetchingCompanyCategories: false,
  isCreating: false,
  isUploadImage: false,
  wasLoaded: false,
  countUsers: 0,
  isFetchingCountUsers: false,
  isFetchingExecuteMomentTask: false,
  isModalMomentum: false,
  notificationInstances: [],
  notificationInstancesIsFetching: false,
  notificationInstancesMeta: null,
  error: null,
  errorFields: [],
}

export const newsletterWizardSlice = createSlice({
  name: 'newsletterWizard',
  initialState,
  reducers: {
    create(
      state: InitialStateType,
      _action: PayloadAction<CreateNotificationPayload>,
    ): void {
      state.isCreating = true
      state.error = initialState.error
      state.errorFields = []
    },
    createSuccess(
      state: InitialStateType,
      _action: PayloadAction<CreateNotificationSuccessPayload>,
    ): void {
      state.isCreating = false
    },
    createFailure(
      state: InitialStateType,
      action: PayloadAction<CreateNotificationFailurePayload>,
    ): void {
      state.isCreating = false
      state.error = action.payload.error
      const errorFields = []
      if (action.payload.fields) {
        for (const error in action.payload.fields) {
          errorFields.push(action.payload.fields[error][0])
        }
      }
      state.errorFields = errorFields
    },
    edit(
      state: InitialStateType,
      _action: PayloadAction<EditNotificationPayload>,
    ): void {
      state.isCreating = true
      state.error = initialState.error
      state.errorFields = []
    },
    editSuccess(
      state: InitialStateType,
      _action: PayloadAction<EditNotificationSuccessPayload>,
    ): void {
      state.isCreating = false
    },
    editFailure(
      state: InitialStateType,
      action: PayloadAction<EditNotificationFailurePayload>,
    ): void {
      state.isCreating = false
      state.error = action.payload.error
      const errorFields = []
      if (action.payload.fields) {
        for (const error in action.payload.fields) {
          errorFields.push(action.payload.fields[error][0])
        }
      }
      state.errorFields = errorFields
    },
    uploadImage(
      state: InitialStateType,
      _action: PayloadAction<UploadImageNotificationPayload>,
    ): void {
      state.isUploadImage = true
      state.error = initialState.error
      state.errorFields = []
    },
    uploadImageSuccess(
      state: InitialStateType,
      _action: PayloadAction<UploadImageNotificationSuccessPayload>,
    ): void {
      state.isUploadImage = false
    },
    uploadImageFailure(
      state: InitialStateType,
      action: PayloadAction<UploadImageNotificationFailurePayload>,
    ): void {
      state.isUploadImage = false
      state.errorFields.push(action.payload)
    },
    fetch(
      state: InitialStateType,
      _action: PayloadAction<FetchNotificationPayload>,
    ): void {
      state.isFetching = true
      state.error = initialState.error
      state.errorFields = []
    },
    fetchSuccess(
      state: InitialStateType,
      action: PayloadAction<FetchNotificationSuccessPayload>,
    ): void {
      state.currentNotification = action.payload
      state.isFetching = false
      state.wasLoaded = true
    },
    fetchFailure(
      state: InitialStateType,
      action: PayloadAction<FetchNotificationFailurePayload>,
    ): void {
      state.isFetching = false
      state.error = action.payload
    },
    fetchCompanyCategories(
      state: InitialStateType,
      _action: PayloadAction<undefined>,
    ): void {
      state.isFetchingCompanyCategories = true
      state.error = initialState.error
      state.errorFields = []
    },
    fetchCompanyCategoriesSuccess(
      state: InitialStateType,
      action: PayloadAction<CompanyCategoryItemType[]>,
    ): void {
      state.companyCategories = action.payload
      state.isFetchingCompanyCategories = false
    },
    fetchCompanyCategoriesFailure(
      state: InitialStateType,
      action: PayloadAction<string>,
    ): void {
      state.isFetchingCompanyCategories = false
      state.error = action.payload
    },
    destroy(
      _state: InitialStateType,
      _action: PayloadAction<NewsletterWizardDestroyPayload>,
    ): InitialStateType {
      return initialState
    },
    fetchCountUsers(
      state: InitialStateType,
      _action: PayloadAction<FetchCountUsersPayload>,
    ): void {
      state.isFetchingCountUsers = true
    },
    fetchCountUsersSuccess(
      state: InitialStateType,
      action: PayloadAction<FetchCountUsersSuccessPayload>,
    ): void {
      state.isFetchingCountUsers = false
      state.countUsers =
        action.payload.countEnabled - action.payload.countDisabled
    },
    fetchCountUsersFailure(
      state: InitialStateType,
      action: PayloadAction<FetchCountUsersFailurePayload>,
    ): void {
      state.isFetchingCountUsers = false
      state.countUsers = 0
      state.error = action.payload.error
      const errorFields = []
      if (action.payload.fields) {
        for (const error in action.payload.fields) {
          errorFields.push(action.payload.fields[error][0])
        }
      }
      state.errorFields = errorFields
    },
    executeMomentTask(
      state: InitialStateType,
      _action: PayloadAction<string>,
    ): void {
      state.isFetchingExecuteMomentTask = true
    },
    executeMomentTaskSuccess(
      state: InitialStateType,
      _action: PayloadAction<undefined>,
    ): void {
      state.isFetchingExecuteMomentTask = false
      state.isModalMomentum = false
    },
    executeMomentTaskFailure(
      state: InitialStateType,
      action: PayloadAction<string>,
    ): void {
      state.isFetchingExecuteMomentTask = false
      state.error = action.payload
    },
    toggleModal(state: InitialStateType, action: PayloadAction<boolean>): void {
      state.isModalMomentum = action.payload
    },
    fieldErrorsShowed(
      state: InitialStateType,
      _action: PayloadAction<undefined>,
    ): void {
      state.errorFields = []
    },
    fetchNotificationInstances(
      state: InitialStateType,
      _action: PayloadAction<NotificationInstancesPayload>,
    ): void {
      state.isFetching = true
    },
    fetchNotificationInstancesSuccess(
      state: InitialStateType,
      action: PayloadAction<NotificationInstancesSuccessPayload>,
    ): void {
      state.notificationInstances = action.payload.data
      state.notificationInstancesMeta = action.payload.meta
      state.isFetching = false
    },
    fetchNotificationInstancesFailure(
      state: InitialStateType,
      action: PayloadAction<NotificationInstancesFailurePayload>,
    ): void {
      state.isFetching = false
      state.error = action.payload
    },
  },
})

export const actions = newsletterWizardSlice.actions

export default newsletterWizardSlice.reducer
