import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { Feature } from '../../types/feature'

export type InitialStateType = {
  isFetching: boolean
  items: Feature[]
  error: string | null
}

export type FetchFeaturesSelectorPayload = Record<string, any>
export type FetchFeaturesSelectorSuccessPayload = Feature[]
export type FetchFeaturesSelectorFailurePayload = string | null

const initialState: InitialStateType = {
  isFetching: false,
  items: [],
  error: null,
}

export const featuresSelectorSlice = createSlice({
  name: 'featuresSelector',
  initialState,
  reducers: {
    fetch(
      state: InitialStateType,
      _action: PayloadAction<FetchFeaturesSelectorPayload>,
    ): void {
      state.isFetching = true
    },
    fetchSuccess(
      state: InitialStateType,
      action: PayloadAction<FetchFeaturesSelectorSuccessPayload>,
    ): void {
      state.isFetching = false
      state.items = action.payload
    },
    fetchFailure(
      state: InitialStateType,
      action: PayloadAction<FetchFeaturesSelectorFailurePayload>,
    ): void {
      state.isFetching = false
      state.error = action.payload
    },
  },
})

export const actions = featuresSelectorSlice.actions

export default featuresSelectorSlice.reducer
