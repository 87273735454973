import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import {
  InitialState,
  FetchPayments,
  FetchPaymentsFailure,
  FetchPaymentsPayload,
} from './types'

const initialState: InitialState = {
  items: [],
  error: null,
  isFetching: false,
  pagination: {
    total: 0,
    perPage: 0,
    lastPage: 0,
    currentPage: 0,
  },
}

export const companyPaymentsSlice = createSlice({
  name: 'companyPayments',
  initialState,
  reducers: {
    fetchPayments(
      state: InitialState,
      _action: PayloadAction<FetchPayments>,
    ): void {
      state.isFetching = true
    },
    fetchPaymentsSuccess(
      state: InitialState,
      action: PayloadAction<FetchPaymentsPayload>,
    ): void {
      state.isFetching = false
      state.items = action.payload.data
      state.pagination = action.payload.meta
    },
    fetchPaymentsFailure(
      state: InitialState,
      action: PayloadAction<FetchPaymentsFailure>,
    ): void {
      state.isFetching = false
      state.error = action.payload
    },
  },
})

export const actions = companyPaymentsSlice.actions

export default companyPaymentsSlice.reducer
