import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import {
  InitialStateType,
  CreateContentPayload,
  CreateContentSuccessPayload,
  CreateContentFailurePayload,
  SendImagePayload,
  SendImageSuccessPayload,
  SendImageFailurePayload,
  SendGalleryPayload,
  SendGallerySuccessPayload,
  SendGalleryFailurePayload,
  SendVideosPayload,
  SendVideosSuccessPayload,
  SendVideosFailurePayload,
} from './types'
import { buildFieldErrors } from '../../utils/helpers'

const initialState: InitialStateType = {
  isCreating: false,
  isSendImage: false,
  isSendGallery: false,
  isSendVideos: false,
  errorForm: null,
  error: [],
}

export const createContentSlice = createSlice({
  name: 'createContent',
  initialState,
  reducers: {
    create(
      state: InitialStateType,
      _action: PayloadAction<CreateContentPayload>,
    ): void {
      state.isCreating = true
      state.errorForm = null
      state.error = []
    },
    createSuccess(
      state: InitialStateType,
      _action: PayloadAction<CreateContentSuccessPayload>,
    ): void {
      state.isCreating = false
    },
    createFailure(
      state: InitialStateType,
      action: PayloadAction<CreateContentFailurePayload>,
    ): void {
      state.isCreating = false
      state.errorForm = buildFieldErrors(action.payload)
    },
    sendImage(
      state: InitialStateType,
      _action: PayloadAction<SendImagePayload>,
    ): void {
      state.isSendImage = true
    },
    sendImageSuccess(
      state: InitialStateType,
      _action: PayloadAction<SendImageSuccessPayload>,
    ): void {
      state.isSendImage = false
    },
    sendImageFailure(
      state: InitialStateType,
      action: PayloadAction<SendImageFailurePayload>,
    ): void {
      state.isSendImage = false
      state.isCreating = false
      state.error = [...state.error, action.payload]
    },
    sendGallery(
      state: InitialStateType,
      _action: PayloadAction<SendGalleryPayload>,
    ): void {
      state.isSendGallery = true
    },
    sendGallerySuccess(
      state: InitialStateType,
      _action: PayloadAction<SendGallerySuccessPayload>,
    ): void {
      state.isSendGallery = false
    },
    sendGalleryFailure(
      state: InitialStateType,
      action: PayloadAction<SendGalleryFailurePayload>,
    ): void {
      state.isSendGallery = false
      state.isCreating = false
      state.error = [...state.error, action.payload]
    },
    sendVideos(
      state: InitialStateType,
      _action: PayloadAction<SendVideosPayload>,
    ): void {
      state.isSendVideos = true
    },
    sendVideosSuccess(
      state: InitialStateType,
      _action: PayloadAction<SendVideosSuccessPayload>,
    ): void {
      state.isSendVideos = false
    },
    sendVideosFailure(
      state: InitialStateType,
      action: PayloadAction<SendVideosFailurePayload>,
    ): void {
      state.isSendVideos = false
      state.isCreating = false
      state.error = [...state.error, action.payload]
    },
    destroy(
      _state: InitialStateType,
      _action: PayloadAction<undefined>,
    ): InitialStateType {
      return initialState
    },
  },
})

export const actions = createContentSlice.actions

export default createContentSlice.reducer
