import { all, call, fork, put, takeEvery } from 'redux-saga/effects'
import { SagaIterator } from 'redux-saga'
import { actions } from './slice'
import * as api from './api'
import { formatErrorMessage } from '../../utils/helpers'

function* handleFetchSaga(): SagaIterator {
  try {
    const plans = yield call(api.fetchPlans)
    yield put(actions.fetchSuccess({ data: plans.data }))
  } catch (error) {
    console.error('Fetch plans failed', error)
    yield put(actions.fetchFailure({ error: formatErrorMessage(error) }))
  }
}

export function* watchFetchSaga(): SagaIterator {
  yield takeEvery(actions.fetch, handleFetchSaga)
}

export default function* watchPlans(): SagaIterator {
  yield all([fork(watchFetchSaga)])
}
