import { all, fork, put, takeEvery, call } from 'redux-saga/effects'
import { SagaIterator } from '@redux-saga/core'
import { actions, FetchCategoriesSelectorSuccessPayload } from './slice'
import * as api from './api'
import { formatErrorMessage } from '../../utils/helpers'

function* fetchCategoriesSelectorSaga(): SagaIterator {
  try {
    const categories: FetchCategoriesSelectorSuccessPayload = yield call(
      api.fetchCategories,
    )
    yield put(actions.fetchSuccess(categories))
  } catch (error) {
    console.error('Fetch categories failed', error)
    yield put(actions.fetchFailure(formatErrorMessage(error)))
  }
}

export function* watchCategoriesSelectorSaga(): SagaIterator {
  yield takeEvery(actions.fetch, fetchCategoriesSelectorSaga)
}

export default function* watchCategoriesSelector(): SagaIterator {
  yield all([fork(watchCategoriesSelectorSaga)])
}
