import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit'
import { persistStore, persistReducer } from 'redux-persist'
import createSagaMiddleware from 'redux-saga'
import logger from 'redux-logger'
import storage from 'redux-persist/lib/storage'
import rootReducer from './reducers'
import rootSaga from './sagas'
import { persistMigrate, persistVersion } from './persistMigration'
import * as process from 'process'

const persistConfig = {
  key: 'root',
  storage,
  blacklist: [
    'companyFormFilter',
    'userSelector',
    'businessAccountSelector',
    'areaSelector',
    'categoriesSelector',
    'featuresSelector',
    'franchiseeSelector',
    'editFranchisee',
    'integrations',
    'companySubscriptions',
    'companyAnalytics',
    'plans',
  ],
  version: persistVersion,
  migrate: persistMigrate,
}

const persistedReducer = persistReducer<any>(persistConfig, rootReducer)

const devMode = process.env.NODE_ENV === 'development'

const sagaMiddleware = createSagaMiddleware()

const middlewares = [
  ...getDefaultMiddleware({
    thunk: true,
    serializableCheck: false,
    immutableCheck: false,
  }),
  sagaMiddleware,
]

if (devMode) {
  middlewares.push(logger)
}

export const store = configureStore({
  reducer: persistedReducer,
  preloadedState: {},
  devTools: devMode,
  middleware: middlewares,
})

export const persistor = persistStore(store)

sagaMiddleware.run(rootSaga)
