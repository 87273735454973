import { all, fork, put, takeEvery, call } from 'redux-saga/effects'
import { SagaIterator } from '@redux-saga/core'
import { PayloadAction } from '@reduxjs/toolkit'
import {
  actions,
  FetchUserSelectorSuccessPayload,
  FetchUserSelectorPayload,
} from './slice'
import * as api from './api'
import { formatErrorMessage } from '../../utils/helpers'

function* fetchUserSelectorSaga(
  action: PayloadAction<FetchUserSelectorPayload>,
): SagaIterator {
  try {
    const users: FetchUserSelectorSuccessPayload = yield call(
      api.fetchUsers,
      action.payload.search,
    )
    yield put(actions.fetchSuccess(users))
  } catch (error) {
    console.error('Fetch users failed', error)
    yield put(actions.fetchFailure(formatErrorMessage(error)))
  }
}

function* watchUserSelectorSaga(): SagaIterator {
  yield takeEvery(actions.fetch, fetchUserSelectorSaga)
}

export default function* watchUserSelector(): SagaIterator {
  yield all([fork(watchUserSelectorSaga)])
}
