import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import {
  InitialStateType,
  EditContentPayload,
  EditContentSuccessPayload,
  EditContentFailurePayload,
  FetchContentPayload,
  FetchContentSuccessPayload,
  FetchContentFailurePayload,
  AddVideoPayload,
  AddVideoSuccessPayload,
  AddVideoFailurePayload,
  DeleteMediaPayload,
  DeleteMediaSuccessPayload,
  DeleteMediaFailurePayload,
  ReOrderMediaPayload,
  ReOrderMediaSuccessPayload,
  ReOrderMediaFailurePayload,
} from './types'
import { buildFieldErrors } from '../../utils/helpers'

const initialState: InitialStateType = {
  content: null,
  isFetching: false,
  isUpdating: false,
  isModalAddVideo: false,
  isSendVideo: false,
  isDeleteMedia: false,
  isReOrderMedia: false,
  errorForm: null,
  errorAddVideo: null,
  error: [],
}

export const editContentSlice = createSlice({
  name: 'editContent',
  initialState,
  reducers: {
    fetch(
      state: InitialStateType,
      _action: PayloadAction<FetchContentPayload>,
    ): void {
      state.content = null
      state.isFetching = true
      state.errorForm = null
      state.error = []
    },
    fetchSuccess(
      state: InitialStateType,
      action: PayloadAction<FetchContentSuccessPayload>,
    ): void {
      state.content = action.payload
      state.isFetching = false
    },
    fetchFailure(
      state: InitialStateType,
      action: PayloadAction<FetchContentFailurePayload>,
    ): void {
      state.isFetching = false
      state.error = [action.payload]
    },
    edit(
      state: InitialStateType,
      _action: PayloadAction<EditContentPayload>,
    ): void {
      state.isUpdating = true
      state.errorForm = null
      state.error = []
    },
    editSuccess(
      state: InitialStateType,
      _action: PayloadAction<EditContentSuccessPayload>,
    ): void {
      state.isUpdating = false
    },
    editFailure(
      state: InitialStateType,
      action: PayloadAction<EditContentFailurePayload>,
    ): void {
      state.isUpdating = false
      state.errorForm = buildFieldErrors(action.payload)
    },
    showModal(state: InitialStateType, action: PayloadAction<boolean>): void {
      state.isModalAddVideo = action.payload
      state.errorAddVideo = null
    },
    sendVideo(
      state: InitialStateType,
      _action: PayloadAction<AddVideoPayload>,
    ): void {
      state.isSendVideo = true
      state.errorAddVideo = null
    },
    sendVideoSuccess(
      state: InitialStateType,
      action: PayloadAction<AddVideoSuccessPayload>,
    ): void {
      if (state.content) {
        state.content.videos = action.payload
      }
      state.isSendVideo = false
      state.isModalAddVideo = false
    },
    sendVideoFailure(
      state: InitialStateType,
      action: PayloadAction<AddVideoFailurePayload>,
    ): void {
      state.isSendVideo = false
      state.errorAddVideo = action.payload
    },
    deleteMedia(
      state: InitialStateType,
      _action: PayloadAction<DeleteMediaPayload>,
    ): void {
      state.isDeleteMedia = true
      state.error = []
    },
    deleteMediaSuccess(
      state: InitialStateType,
      _action: PayloadAction<DeleteMediaSuccessPayload>,
    ): void {
      state.isDeleteMedia = false
    },
    deleteMediaFailure(
      state: InitialStateType,
      action: PayloadAction<DeleteMediaFailurePayload>,
    ): void {
      state.isDeleteMedia = false
      state.error = [action.payload]
    },
    reOrderMedia(
      state: InitialStateType,
      _action: PayloadAction<ReOrderMediaPayload>,
    ): void {
      state.isReOrderMedia = true
      state.error = []
    },
    reOrderMediaSuccess(
      state: InitialStateType,
      _action: PayloadAction<ReOrderMediaSuccessPayload>,
    ): void {
      state.isReOrderMedia = false
    },
    reOrderMediaFailure(
      state: InitialStateType,
      action: PayloadAction<ReOrderMediaFailurePayload>,
    ): void {
      state.isReOrderMedia = false
      state.error = [action.payload]
    },
    destroy(
      _state: InitialStateType,
      _action: PayloadAction<undefined>,
    ): InitialStateType {
      return initialState
    },
  },
})

export const actions = editContentSlice.actions

export default editContentSlice.reducer
