import { notification } from 'antd'
import { RootState } from 'src/store/reducers'
import { SagaIterator } from '@redux-saga/core'
import { all, fork, put, takeEvery, call, select } from 'redux-saga/effects'

import { Router } from 'src/routes'
import * as api from './api'
import { actions } from './slice'
import { ICompany } from './types'
import { deepClone, formatErrorMessage } from 'src/utils/helpers'
import moment from 'moment'

const getFormFilters = (state: RootState): any => ({
  filters: state.companyFormFilter,
  pagination: state.companiesTable.pagination,
})

type Companies = ICompany[]

const getSelectedCompanies = (state: RootState): string[] =>
  state.companiesTable.selectedCompanies

const getCompanies = (state: RootState): Companies =>
  state.companiesTable.companies

function* handleFetchCompanies(): any {
  try {
    const getDataForSearch = yield select(getFormFilters)
    const urlParams = yield new URL(window.location.href)
    const stringBoolean = ['true', 'false']

    for (const itemParam in getDataForSearch.filters) {
      if (
        getDataForSearch.filters[itemParam].value ||
        stringBoolean.includes(
          getDataForSearch.filters[itemParam].value?.toString(),
        )
      ) {
        const isDate = getDataForSearch.filters[itemParam]?.hasOwnProperty(
          'formatToDisplay',
        )
        const value = getDataForSearch.filters[itemParam].value
        const isValueObject = typeof value === 'object'
        const valueSearchParam = isValueObject
          ? `${value.label}{d}${value.value}`
          : value
        if (isDate) {
          const dateTextFormat =
            itemParam === 'subscription_range' ? 'DD.MM.YYYY' : 'YYYYMMDD'
          const pred = itemParam === 'subscription_range' ? '-' : ','

          const formatDate =
            itemParam === 'subscription_active'
              ? moment(value).format('YYYY-MM-DD')
              : `${
                  value.dateStart
                    ? moment(value.dateStart).format(dateTextFormat)
                    : ''
                }${pred}${
                  value.dateEnd
                    ? moment(value.dateEnd).format(dateTextFormat)
                    : ''
                }`
          yield urlParams.searchParams.set(itemParam, formatDate)
          getDataForSearch.filters = {
            ...getDataForSearch.filters,
            [itemParam]: {
              ...getDataForSearch.filters[itemParam],
              value: formatDate,
            },
          }
        } else {
          yield urlParams.searchParams.set(
            itemParam,
            valueSearchParam.toString(),
          )
        }
      } else {
        yield urlParams.searchParams.delete(itemParam)
      }
    }

    Router.replaceRoute(urlParams.toString())

    const { data: companies, meta } = yield call(api.get, getDataForSearch)

    yield put(actions.fetchCompaniesSuccess({ companies, meta }))
  } catch (error) {
    console.error('handleFetchCompanies error', error)
    notification['error']({
      message: 'Произошла ошибка => поиск',
    })
  }
}

function* publishCompanies(action: any): SagaIterator {
  try {
    const selectedCompanies = yield select(getSelectedCompanies)
    yield call(api.publish, selectedCompanies, action.payload)

    const companies = yield select(getCompanies)

    const updatedCompanies = deepClone(companies)
    selectedCompanies.map((selectedCompany: ICompany) => {
      const index = updatedCompanies.findIndex(
        (updatedCompany: any) => selectedCompany === updatedCompany.company_id,
      )

      if (index >= 0)
        updatedCompanies[index] = {
          ...updatedCompanies[index],
          published: action.payload,
        }
    })

    yield put(actions.publishCompaniesSuccess(updatedCompanies))
  } catch (error) {
    notification['error']({
      message: 'Произошла ошибка при публикаии компаний',
    })
  }
}

function* deleteCompanies(): SagaIterator {
  try {
    const getDataForSearch = yield select(getFormFilters)
    const selectedCompanies = yield select(getSelectedCompanies)

    yield call(api.remove, selectedCompanies)
    const { data: companies, meta } = yield call(api.get, getDataForSearch)

    yield put(actions.fetchCompaniesSuccess({ companies, meta }))
  } catch (err) {
    notification['error']({
      message: 'Произошла ошибка при удалении компаний',
    })
  }
}

function* uploadExcelFileSaga(action: {
  payload: { file: File }
}): SagaIterator {
  try {
    yield call(api.uploadExcelFile, action.payload.file)
    yield put(actions.uploadExcelFileSuccess())
    notification.open({
      message: 'Успешно!',
      description: 'Задача поставлена в очередь.',
    })
  } catch (error) {
    console.error('Upload excel file failed', error)
    const message = formatErrorMessage(error)
    notification['error']({ message })
    yield put(actions.uploadExcelFileFailure(message))
  }
}

export function* watchCompaniesDelete(): SagaIterator {
  yield takeEvery(actions.deleteCompanies, deleteCompanies)
}

export function* watchCompaniesPublish(): SagaIterator {
  yield takeEvery(actions.publishCompanies, publishCompanies)
}

export function* watchCompaniesFetch(): SagaIterator {
  yield takeEvery(actions.fetchCompanies, handleFetchCompanies)
}

export function* watchUploadExcelFileSaga(): SagaIterator {
  yield takeEvery(actions.uploadExcelFile, uploadExcelFileSaga)
}

export default function* watchCompaniesUpdate(): SagaIterator {
  yield all([
    fork(watchCompaniesFetch),
    fork(watchCompaniesDelete),
    fork(watchCompaniesPublish),
    fork(watchUploadExcelFileSaga),
  ])
}
