import { put, call, takeEvery, all, fork, select } from 'redux-saga/effects'
import { SagaIterator } from '@redux-saga/core'
import { actions } from './slice'
import {
  CreateVersionPayload,
  EditVersionPayload,
  FetchAppVersionsPayload,
  InitialStateType,
  RemoveVersionPayload,
  VersionItemType,
} from './types'
import * as api from './api'
import { formatErrorMessage } from '../../utils/helpers'

const getPageSelector = (state: { appVersions: InitialStateType }): number =>
  state.appVersions.meta ? state.appVersions.meta.currentPage : 1

const getVersionsSelector = (state: {
  appVersions: InitialStateType
}): VersionItemType[] => state.appVersions.versions

function* fetchVersionsSaga(action: {
  payload: FetchAppVersionsPayload
}): SagaIterator {
  try {
    const versions = yield call(api.fetchVersions, action.payload)
    yield put(
      actions.fetchSuccess({ data: versions.data, meta: versions.meta }),
    )
  } catch (error) {
    console.error('Fetch app versions failed', error)
    yield put(actions.fetchFailure(formatErrorMessage(error)))
  }
}

function* createVersionSaga(action: {
  payload: CreateVersionPayload
}): SagaIterator {
  try {
    yield call(api.createVersion, action.payload)
    yield put(actions.createSuccess())
    const currentPage = yield select(getPageSelector)
    yield put(actions.fetch(currentPage))
  } catch (error) {
    console.error('Create app version failed', error)
    yield put(
      actions.createFailure({
        message: formatErrorMessage(error),
        fields: error.response.data?.errors,
      }),
    )
  }
}

function* editVersionSaga(action: {
  payload: EditVersionPayload
}): SagaIterator {
  try {
    const { versionId, data } = action.payload
    yield call(api.editVersion, versionId, data)
    yield put(actions.editSuccess())
    const currentPage = yield select(getPageSelector)
    yield put(actions.fetch(currentPage))
  } catch (error) {
    console.error('Edit app version failed', error)
    yield put(
      actions.editFailure({
        message: formatErrorMessage(error),
        fields: error.response.data?.errors,
      }),
    )
  }
}

function* removeVersionSaga(action: {
  payload: RemoveVersionPayload
}): SagaIterator {
  try {
    yield call(api.removeVersion, action.payload)
    yield put(actions.removeSuccess())
    const currentPage = yield select(getPageSelector)
    const versions = yield select(getVersionsSelector)
    if (versions.length === 1 && currentPage > 1) {
      yield put(actions.fetch(currentPage - 1))
    } else {
      yield put(actions.fetch(currentPage))
    }
  } catch (error) {
    console.error('Remove app version failed', error)
    yield put(actions.removeFailure(formatErrorMessage(error)))
  }
}

export function* watchFetchVersionsSaga(): SagaIterator {
  yield takeEvery(actions.fetch, fetchVersionsSaga)
}

export function* watchCreateVersionSaga(): SagaIterator {
  yield takeEvery(actions.create, createVersionSaga)
}

export function* watchEditVersionSaga(): SagaIterator {
  yield takeEvery(actions.edit, editVersionSaga)
}

export function* watchRemoveVersionSaga(): SagaIterator {
  yield takeEvery(actions.remove, removeVersionSaga)
}

export default function* watchAppVersions(): SagaIterator {
  yield all([
    fork(watchFetchVersionsSaga),
    fork(watchCreateVersionSaga),
    fork(watchEditVersionSaga),
    fork(watchRemoveVersionSaga),
  ])
}
