import { all, fork, put, takeEvery, call } from 'redux-saga/effects'
import { SagaIterator } from '@redux-saga/core'
import { actions, FetchFranchiseeDashboardPayload } from './slice'
import * as api from './api'
import { formatErrorMessage } from '../../utils/helpers'

function* fetchFranchiseeDashboardSaga(action: {
  payload: FetchFranchiseeDashboardPayload
}): SagaIterator {
  try {
    const data = yield call(api.fetchFranchisee, action.payload)
    const orders = yield call(api.fetchOrdersSummary, action.payload)
    yield put(actions.fetchSuccess({ ...data, ...orders }))
  } catch (error) {
    console.error('Fetch franchisees dashboard failed', error)
    yield put(actions.fetchFailure(formatErrorMessage(error)))
  }
}

export function* watchFetchFranchiseeDashboardSaga(): SagaIterator {
  yield takeEvery(actions.fetch, fetchFranchiseeDashboardSaga)
}

export default function* watchFranchiseeDashboard(): SagaIterator {
  yield all([fork(watchFetchFranchiseeDashboardSaga)])
}
