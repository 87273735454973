import {
  ActionType,
  ChangeInputPayload,
  CLEAR_STATE,
  HANDLE_CHANGE_USER_INPUT,
} from '../actions/createCompany'

type InitialStateType = {
  main: {
    address: string
    comments: string
    description: string
    email: string
    name: string
    phone: string
    post: boolean
    verifiedByMamado: boolean
    website: string
  }
  requisites: {
    accountNumber: string
    bankCity: string
    bankName: string
    bik: string
    fullName: string
    inn: string
    juridialAddress: string
    kpp: string
    ogrn: string
    ownershipType: string
    pc: string
    shortName: string
  }
}
const initialState: InitialStateType = {
  main: {
    address: '',
    comments: '',
    description: '',
    email: '',
    name: '',
    phone: '',
    post: true,
    verifiedByMamado: true,
    website: '',
  },
  requisites: {
    accountNumber: '',
    bankCity: '',
    bankName: '',
    bik: '',
    fullName: '',
    inn: '',
    juridialAddress: '',
    kpp: '',
    ogrn: '',
    ownershipType: '',
    pc: '',
    shortName: '',
  },
}

type PossibleActionTypes = ActionType<ChangeInputPayload> | ActionType<void>

export default (
  state: InitialStateType = initialState,
  action: PossibleActionTypes,
): InitialStateType => {
  switch (action.type) {
    case CLEAR_STATE: {
      return initialState
    }
    case HANDLE_CHANGE_USER_INPUT: {
      const { key, page, value } = action.payload as ChangeInputPayload
      return {
        ...state,
        [page]: {
          ...state[page],
          [key]: value,
        },
      }
    }
    default:
      return state
  }
}
