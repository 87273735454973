import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { BusinessAccount } from '../../types/business-account'

export type InitialStateType = {
  isFetching: boolean
  items: BusinessAccount[]
  error: string | null
}

export type FetchBusinessAccountSelectorPayload = {
  search: string
}

export type FetchBusinessAccountSelectorSuccessPayload = {
  data: BusinessAccount[]
}
export type FetchBusinessAccountSelectorFailurePayload = string | null

const initialState: InitialStateType = {
  isFetching: false,
  items: [],
  error: null,
}

export const businessAccountSelectorSlice = createSlice({
  name: 'businessAccountSelector',
  initialState,
  reducers: {
    fetch(
      state: InitialStateType,
      _action: PayloadAction<FetchBusinessAccountSelectorPayload>,
    ): void {
      state.isFetching = true
    },
    fetchSuccess(
      state: InitialStateType,
      action: PayloadAction<FetchBusinessAccountSelectorSuccessPayload>,
    ): void {
      state.isFetching = false
      state.items = action.payload.data
    },
    fetchFailure(
      state: InitialStateType,
      action: PayloadAction<FetchBusinessAccountSelectorFailurePayload>,
    ): void {
      state.isFetching = false
      state.error = action.payload
    },
    destroy(): InitialStateType {
      return initialState
    },
  },
})

export const actions = businessAccountSelectorSlice.actions

export default businessAccountSelectorSlice.reducer
