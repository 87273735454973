import { all, fork, put, takeEvery, call, select } from 'redux-saga/effects'
import { SagaIterator } from '@redux-saga/core'
import { actions } from './slice'
import { formatErrorMessage } from '../../utils/helpers'
import {
  fetchPromocodes,
  createPromocode,
  editPromocode,
  removePromocode,
} from './api'
import {
  InitialStateType,
  FetchPromocodesPayload,
  CreatePromocodePayload,
  EditPromocodePayload,
  PromocodeItemType,
  RemovePromocodePayload,
} from './types'

const getPageSelector = (state: { promocodes: InitialStateType }): number =>
  state.promocodes.meta ? state.promocodes.meta.currentPage : 1

const getPromocodeSelector = (state: {
  promocodes: InitialStateType
}): PromocodeItemType[] => state.promocodes.promocodes

function* fetchPromocodesSaga(action: {
  payload: FetchPromocodesPayload
}): SagaIterator {
  try {
    const promocodes = yield call(fetchPromocodes, action.payload)
    yield put(actions.fetchSuccess(promocodes))
  } catch (error) {
    console.error('Fetch promocodes failed', error)
    yield put(actions.fetchFailure(formatErrorMessage(error)))
  }
}

function* createPromocodeSaga(action: {
  payload: CreatePromocodePayload
}): SagaIterator {
  try {
    yield call(createPromocode, action.payload)
    yield put(actions.createSuccess())
    const currentPage = yield select(getPageSelector)
    yield put(actions.fetch(currentPage))
  } catch (error) {
    console.error('Create promocode failed', error)
    yield put(
      actions.createFailure({
        message: formatErrorMessage(error),
        fields: error.response.data?.errors,
      }),
    )
  }
}

function* editPromocodeSaga(action: {
  payload: EditPromocodePayload
}): SagaIterator {
  try {
    const { promocodeId, data } = action.payload
    yield call(editPromocode, promocodeId, data)
    yield put(actions.editSuccess())
    const currentPage = yield select(getPageSelector)
    yield put(actions.fetch(currentPage))
  } catch (error) {
    console.error('Edit promocode failed', error)
    yield put(
      actions.editFailure({
        message: formatErrorMessage(error),
        fields: error.response.data?.errors,
      }),
    )
  }
}

function* removePromocodeSaga(action: {
  payload: RemovePromocodePayload
}): SagaIterator {
  try {
    yield call(removePromocode, action.payload)
    yield put(actions.removeSuccess())
    const currentPage = yield select(getPageSelector)
    const versions = yield select(getPromocodeSelector)
    if (versions.length === 1 && currentPage > 1) {
      yield put(actions.fetch(currentPage - 1))
    } else {
      yield put(actions.fetch(currentPage))
    }
  } catch (error) {
    console.error('Remove promocode failed', error)
    yield put(actions.removeFailure(formatErrorMessage(error)))
  }
}

export function* watchFetchPromocodesSaga(): SagaIterator {
  yield takeEvery(actions.fetch, fetchPromocodesSaga)
}

export function* watchCreatePromocodeSaga(): SagaIterator {
  yield takeEvery(actions.create, createPromocodeSaga)
}

export function* watchEditPromocodeSaga(): SagaIterator {
  yield takeEvery(actions.edit, editPromocodeSaga)
}

export function* watchRemovePromocodeSaga(): SagaIterator {
  yield takeEvery(actions.remove, removePromocodeSaga)
}

export default function* watchPromocodes(): SagaIterator {
  yield all([
    fork(watchFetchPromocodesSaga),
    fork(watchCreatePromocodeSaga),
    fork(watchEditPromocodeSaga),
    fork(watchRemovePromocodeSaga),
  ])
}
