import { AxiosResponse } from 'axios'
import { get, post, patch, del } from '../../infrastructure/api'
import { FormItemsType } from './types'

export const fetchVersions = (page: number): Promise<AxiosResponse> =>
  get('/admin/app-versions', { page, sort: '-version' })

export const createVersion = (data: FormItemsType): Promise<AxiosResponse> =>
  post('/admin/app-versions', {}, data)

export const editVersion = (
  versionId: string,
  data: FormItemsType,
): Promise<AxiosResponse> => patch(`/admin/app-versions/${versionId}`, {}, data)

export const removeVersion = (versionId: string): Promise<AxiosResponse> =>
  del(`/admin/app-versions/${versionId}`)
