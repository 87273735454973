import { AxiosResponse } from 'axios'
import { get } from '../../infrastructure/api'

export const fetchFranchisees = (page: number): Promise<AxiosResponse> => {
  return get('/admin/franchisees', {
    include: [
      'user',
      'userCount',
      'areas',
      'areasCount',
      'companiesCount',
      'premiumCompaniesCount',
    ],
    page,
  })
}
