import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { ICompany, IPagination } from './types'

export type InitialStateType = {
  pagination: IPagination
  selectedCompanies: string[]
  companies: ICompany[]
  isFetching: boolean
  excelUploadingIsProcess: boolean
  error: string
}

type SetPaginationPayload = IPagination | null

const initialState: InitialStateType = {
  pagination: {
    total: 0,
    perPage: 10,
    lastPage: 0,
    currentPage: 1,
  },
  companies: [],
  selectedCompanies: [],
  isFetching: false,
  excelUploadingIsProcess: false,
  error: '',
}

type SetCompaniesSuccess = {
  companies: ICompany[]
  meta: IPagination
}

type CompaniesPayload = ICompany[]

type SetSelectedCompaniesIds = string[]

const companiesTable = createSlice({
  name: 'companiesTable',
  initialState,
  reducers: {
    deleteCompanies(state: InitialStateType): void {
      state.isFetching = true
    },
    publishCompanies(state: InitialStateType): void {
      state.isFetching = true
    },
    publishCompaniesSuccess(
      state: InitialStateType,
      action: PayloadAction<CompaniesPayload>,
    ): void {
      state.companies = action.payload
      state.selectedCompanies = []
      state.isFetching = false
    },
    setSelectedCompanies(
      state: InitialStateType,
      action: PayloadAction<SetSelectedCompaniesIds>,
    ): void {
      state.selectedCompanies = action.payload
    },
    fetchCompanies(state: InitialStateType): void {
      state.isFetching = true
    },
    fetchCompaniesSuccess(
      state: InitialStateType,
      action: PayloadAction<SetCompaniesSuccess>,
    ): void {
      const { meta, companies } = action.payload

      state.pagination.currentPage = meta.currentPage
      state.pagination.lastPage = meta.lastPage
      state.pagination.perPage = meta.perPage
      state.pagination.total = meta.total
      state.companies = companies
      state.isFetching = false
    },
    setCompanies(
      state: InitialStateType,
      action: PayloadAction<CompaniesPayload>,
    ): void {
      state.companies = action.payload
      state.isFetching = false
    },
    setPagination(
      state: InitialStateType,
      action: PayloadAction<SetPaginationPayload>,
    ): void {
      state.pagination = { ...state.pagination, ...action.payload }
    },
    uploadExcelFile(
      state: InitialStateType,
      _action: PayloadAction<{ file: File }>,
    ): void {
      state.error = initialState.error
      state.excelUploadingIsProcess = true
    },
    uploadExcelFileSuccess(
      state: InitialStateType,
      _action: PayloadAction<void>,
    ): void {
      state.excelUploadingIsProcess = false
    },
    uploadExcelFileFailure(
      state: InitialStateType,
      action: PayloadAction<string>,
    ): void {
      state.excelUploadingIsProcess = false
      state.error = action.payload
    },
  },
})

export const actions = companiesTable.actions

export default companiesTable.reducer
