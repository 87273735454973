import { get } from '../../infrastructure/api'
import { FetchUserSelectorSuccessPayload } from './slice'

export const fetchUsers = (
  search?: string,
): Promise<FetchUserSelectorSuccessPayload> => {
  return get('/admin/users', {
    'filter[search]': search ?? '',
    count: 10,
  })
}
