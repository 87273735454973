import { all } from 'redux-saga/effects'
import watchProfile from '../../containers/profile/saga'
import watchUserSelector from '../../containers/userSelector/saga'
import watchBusinessAccountSelector from '../../containers/businessAccountSelector/saga'
import watchFetchCompanies from '../../containers/companies/saga'
import watchAreaSelector from '../../containers/areaSelector/saga'
import watchFeaturesSelector from '../../containers/featuresSelector/saga'
import watchCategoriesSelector from '../../containers/categoriesSelector/saga'
import watchUpdateCompanyMainInfo from '../../containers/companyMainInfo/saga'
import watchFranchiseesSelector from '../../containers/franchiseeSelector/saga'
import payments from '../../containers/payment/saga'
import watchFranchisee from '../../containers/franchisee/saga'
import watchCreateFranchisee from '../../containers/createFranchisee/saga'
import watchEditFranchisee from '../../containers/editFranchisee/saga'
import watchFranchiseeReport from '../../containers/franchiseeReport/saga'
import watchFranchiseeDashboard from '../../containers/dashboardFranchisee/saga'
import watchIntegrations from '../../containers/integrations/saga'
import watchCompanySubscriptions from '../../containers/companySubscriptions/saga'
import watchCompanyAnalytics from '../../containers/companyAnalytics/saga'
import watchPages from '../../containers/content/saga'
import watchCreateContent from '../../containers/createContent/saga'
import watchEditContent from '../../containers/editContent/saga'
import watchBanners from '../../containers/banners/saga'
import watchBlocks from '../../containers/blocks/saga'
import watchAppVersions from '../../containers/appVersions/saga'
import watchPromocodes from '../../containers/promocodes/saga'
import watchCompanyPropertySelector from '../../containers/companyPropertySelector/saga'
import watchEventPropertySelector from '../../containers/eventPropertySelector/saga'
import watchNewsletters from '../../containers/newsletters/saga'
import watchNewsletterWizard from '../../containers/newsletterWizard/saga'
import watchNewsletterTasks from '../../containers/newslettersTasks/saga'
import watchAreaTreeSelector from '../../containers/areaSelectorTree/saga'
import watchNewAreaTreeSelector from '../../containers/newAreaSelectorTree/saga'
import watchPlans from '../../containers/plans/saga'

export default function* rootSaga(): Generator {
  yield all([
    watchProfile(),
    watchFetchCompanies(),
    watchAreaSelector(),
    watchCategoriesSelector(),
    watchFeaturesSelector(),
    watchUserSelector(),
    watchBusinessAccountSelector(),
    watchUpdateCompanyMainInfo(),
    watchFranchiseesSelector(),
    payments(),
    watchFranchisee(),
    watchCreateFranchisee(),
    watchEditFranchisee(),
    watchFranchiseeReport(),
    watchFranchiseeDashboard(),
    watchIntegrations(),
    watchCompanySubscriptions(),
    watchCompanyAnalytics(),
    watchPages(),
    watchCreateContent(),
    watchEditContent(),
    watchBanners(),
    watchBlocks(),
    watchAppVersions(),
    watchPromocodes(),
    watchCompanyPropertySelector(),
    watchEventPropertySelector(),
    watchNewsletters(),
    watchNewsletterWizard(),
    watchNewsletterTasks(),
    watchAreaTreeSelector(),
    watchNewAreaTreeSelector(),
    watchPlans(),
  ])
}
