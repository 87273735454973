import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import {
  InitialStateType,
  FetchBlocksPayload,
  FetchBlocksSuccessPayload,
  FetchBlocksFailurePayload,
  FetchBannersPayload,
  FetchBannersSuccessPayload,
  FetchBannersFailurePayload,
  ShowModalPayload,
  CloseModalPayload,
  CreateBlockPayload,
  CreateBlockSuccessPayload,
  CreateBlockFailurePayload,
  EditBlockPayload,
  EditBlockSuccessPayload,
  EditBlockFailurePayload,
  RemoveBlockPayload,
  RemoveBlockSuccessPayload,
  RemoveBlockFailurePayload,
} from './types'
import { buildFieldErrors } from '../../utils/helpers'

const initialState: InitialStateType = {
  blocks: [],
  meta: null,
  banners: [],
  isFetching: false,
  isFetchingBanners: false,
  isModalWizard: false,
  selectedBlock: null,
  isProcessCreate: false,
  isProcessEdit: false,
  isProcessRemove: false,
  error: null,
  errorForm: null,
}

export const blocksSlice = createSlice({
  name: 'blocks',
  initialState,
  reducers: {
    fetch(
      state: InitialStateType,
      _action: PayloadAction<FetchBlocksPayload>,
    ): void {
      state.isFetching = true
      state.isProcessCreate = false
      state.isProcessEdit = false
      state.isModalWizard = false
    },
    fetchSuccess(
      state: InitialStateType,
      action: PayloadAction<FetchBlocksSuccessPayload>,
    ): void {
      state.blocks = action.payload.data
      state.meta = action.payload.meta
      state.isFetching = false
    },
    fetchFailure(
      state: InitialStateType,
      action: PayloadAction<FetchBlocksFailurePayload>,
    ): void {
      state.isFetching = false
      state.error = action.payload
    },
    fetchBanners(
      state: InitialStateType,
      _action: PayloadAction<FetchBannersPayload>,
    ): void {
      state.isFetchingBanners = true
    },
    fetchBannersSuccess(
      state: InitialStateType,
      action: PayloadAction<FetchBannersSuccessPayload>,
    ): void {
      state.banners = action.payload
      state.isFetchingBanners = false
    },
    fetchBannersFailure(
      state: InitialStateType,
      action: PayloadAction<FetchBannersFailurePayload>,
    ): void {
      state.isFetchingBanners = false
      state.error = action.payload
    },
    showModal(
      state: InitialStateType,
      action: PayloadAction<ShowModalPayload>,
    ): void {
      if (action.payload) {
        state.selectedBlock = action.payload
      }
      state.isModalWizard = true
      state.errorForm = null
    },
    closeModal(
      state: InitialStateType,
      _action: PayloadAction<CloseModalPayload>,
    ): void {
      state.isModalWizard = false
      state.selectedBlock = null
    },
    create(
      state: InitialStateType,
      _action: PayloadAction<CreateBlockPayload>,
    ): void {
      state.isProcessCreate = true
      state.errorForm = null
    },
    createSuccess(
      state: InitialStateType,
      _action: PayloadAction<CreateBlockSuccessPayload>,
    ): void {
      state.isProcessCreate = false
      state.selectedBlock = null
    },
    createFailure(
      state: InitialStateType,
      action: PayloadAction<CreateBlockFailurePayload>,
    ): void {
      state.isProcessCreate = false
      state.errorForm = buildFieldErrors(action.payload)
    },
    edit(
      state: InitialStateType,
      _action: PayloadAction<EditBlockPayload>,
    ): void {
      state.isProcessEdit = true
      state.errorForm = null
    },
    editSuccess(
      state: InitialStateType,
      _action: PayloadAction<EditBlockSuccessPayload>,
    ): void {
      state.isProcessEdit = false
      state.selectedBlock = null
    },
    editFailure(
      state: InitialStateType,
      action: PayloadAction<EditBlockFailurePayload>,
    ): void {
      state.isProcessEdit = false
      state.errorForm = buildFieldErrors(action.payload)
    },
    remove(
      state: InitialStateType,
      _action: PayloadAction<RemoveBlockPayload>,
    ): void {
      state.isProcessRemove = true
    },
    removeSuccess(
      state: InitialStateType,
      _action: PayloadAction<RemoveBlockSuccessPayload>,
    ): void {
      state.isProcessRemove = false
    },
    removeFailure(
      state: InitialStateType,
      action: PayloadAction<RemoveBlockFailurePayload>,
    ): void {
      state.isProcessRemove = false
      state.error = action.payload
    },
  },
})

export const actions = blocksSlice.actions

export default blocksSlice.reducer
