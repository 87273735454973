import React from 'react'
import { Link } from 'src/routes'
import { Layout } from 'antd'
import styled from 'styled-components'
import HeaderContent from '../header'

const { Header } = Layout

const TriggerBlock = styled.div`
  display: inline-block;
  height: 100%;
`

const StyledImageBlock = styled(TriggerBlock)`
  @media (min-width: 576px) {
    display: none !important;
  }
  padding-left: 24px;
`

const HeaderWrapper = () => {
  return (
    <Header
      style={{
        background: '#fff',
        padding: 0,
        boxShadow: '0 1px 4px rgba(0,21,41,.08)',
        display: 'flex',
      }}
    >
      <Link route="/companies">
        <a>
          <StyledImageBlock>`M`</StyledImageBlock>
        </a>
      </Link>
      <HeaderContent />
    </Header>
  )
}

export default HeaderWrapper
