/* eslint-disable @typescript-eslint/camelcase */
import { deepClone } from 'src/utils/helpers'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { DispatchPayload, InitialStateType } from './types'

const initialState: InitialStateType = {
  name: {
    value: undefined,
    label: 'Название',
  },
  owner_id: {
    value: null,
    label: 'Пользователь',
  },
  business_account_id: {
    value: null,
    label: 'Бизнес аккаунт',
  },
  area_id: {
    label: 'Регион',
    value: null,
  },
  has_owner: {
    value: null,
    label: 'Есть владелец',
    options: [
      { label: 'Да', value: true },
      { label: 'Нет', value: false },
    ],
  },
  published: {
    value: null,
    label: 'Статус публикации',
    options: [
      { label: 'Опубликован', value: true },
      { label: 'Снят с публикации', value: false },
    ],
  },
  has_premium: {
    value: null,
    label: 'Премиум статус',
    options: [
      { label: 'Премиум', value: true },
      { label: 'Обычный', value: false },
    ],
  },
  has_events: {
    value: null,
    label: 'Есть события',
    options: [
      { label: 'Да', value: true },
      { label: 'Нет', value: false },
    ],
  },
  empty_about: {
    value: null,
    label: 'Есть описание',
    options: [
      { label: 'Да', value: false },
      { label: 'Нет', value: true },
    ],
  },
  created: {
    value: null,
    formatToDisplay: 'DD/MM/YYYY',
    label: 'Дата создания компании',
  },
  franchisee_id: {
    value: null,
    label: 'Франчайзи',
  },
  checked: {
    value: null,
    label: 'Проверено мамаду',
    options: [
      { label: 'Да', value: true },
      { label: 'Нет', value: false },
    ],
  },
  type: {
    value: null,
    label: 'Вид компании',
    options: [
      { label: 'Онлайн', value: 'online' },
      { label: 'Оффлайн', value: 'offline' },
    ],
  },
  check_status: {
    value: null,
    label: 'Статус обработки менеджером',
  },
  formalization_status: {
    value: null,
    label: 'Статус формализации',
  },
  subscription_range: {
    value: null,
    formatToDisplay: 'DD/MM/YYYY',
    label: 'Период подключения тарифа',
  },
  subscription_active: {
    value: null,
    formatToDisplay: 'DD/MM/YYYY',
    label: 'Тариф активен на дату',
  },
  plan_subscription_id: {
    value: null,
    label: 'Активный тариф',
    options: [],
  },
}

export const companyFormFilter = createSlice({
  name: 'companyFormFilter',
  initialState,
  reducers: {
    set(
      state: InitialStateType,
      _action: PayloadAction<DispatchPayload>,
    ): void {
      const { payload } = _action
      const { value, formField } = payload
      const field = deepClone(state[formField])

      state[formField] = {
        ...field,
        value,
      }
    },
    destroy(): InitialStateType {
      return initialState
    },
  },
})

export const actions = companyFormFilter.actions

export default companyFormFilter.reducer
