import { PayloadAction } from '@reduxjs/toolkit'
import { all, fork, put, takeEvery, call } from 'redux-saga/effects'
import { SagaIterator } from 'redux-saga'
import { notification } from 'antd'
import * as api from './api'
import { actions } from './slice'
import { actions as actionsCompanyPropertySelector } from '../companyPropertySelector/slice'
import { actions as actionsEventPropertySelector } from '../eventPropertySelector/slice'
import { actions as actionsAreaSelectorTree } from '../areaSelectorTree/slice'
import { actions as actionsPlans } from '../plans/slice'
import { LoginDataType } from './types'
import { Router } from 'src/routes'
import { saveToken, removeToken } from '../../services/Token.service'
import { formatErrorMessage } from '../../utils/helpers'

function* initialAfterFetchProfile(): SagaIterator {
  try {
    yield put(actionsCompanyPropertySelector.fetch())
    yield put(actionsEventPropertySelector.fetch())
    yield put(actionsAreaSelectorTree.fetch())
    yield put(actionsPlans.fetch())
  } catch (error) {
    console.warn('Cannot initial after fetch profile', error)
  }
}

function* login(data: PayloadAction<LoginDataType>): SagaIterator {
  try {
    const result = yield call(api.login, data.payload)
    if (result.data.jwt) {
      yield call(saveToken, result.data.jwt)
      Router.pushRoute('/')
    }
    yield put(actions.loginSuccess())
    yield put(actions.fetchProfile())
  } catch (error) {
    yield put(actions.loginFailure(formatErrorMessage(error)))
  }
}

function* fetchProfile(): SagaIterator {
  try {
    const allowedRoles = ['admin', 'admin_ro', 'franchisee']
    const profile = yield call(api.fetchProfile)
    if (profile.roles.some((role: string) => allowedRoles.includes(role))) {
      yield put(actions.fetchProfileSuccess(profile))
    } else {
      notification.error({
        message: 'Доступ запрещен!',
        description: 'Доступ для вашей учетной записи запрещен.',
      })
      yield put(actions.signOut())
    }
  } catch (error) {
    if (error.response?.status === 401) {
      removeToken()
      Router.replace('/login')
      yield put(actions.signOutSuccess())
    } else {
      yield put(actions.fetchProfileFailure(formatErrorMessage(error)))
    }
  }
}

function* signOut(): SagaIterator {
  try {
    yield call(api.signOut)
    removeToken()
    Router.replace('/login')
    yield put(actions.signOutSuccess())
  } catch (error) {
    yield put(actions.signOutFailure(formatErrorMessage(error)))
  }
}

export function* watchLoginSaga(): SagaIterator {
  yield takeEvery(actions.login, login)
}

export function* watchFetchProfileSaga(): SagaIterator {
  yield takeEvery(actions.fetchProfile, fetchProfile)
}

export function* watchSignOut(): SagaIterator {
  yield takeEvery(actions.signOut, signOut)
}

function* watchInitialAfterFetchProfile(): SagaIterator {
  yield takeEvery(actions.fetchProfileSuccess, initialAfterFetchProfile)
}

export default function* watchProfile(): SagaIterator {
  yield all([
    fork(watchLoginSaga),
    fork(watchFetchProfileSaga),
    fork(watchSignOut),
    fork(watchInitialAfterFetchProfile),
  ])
}
