import { AxiosResponse } from 'axios'
import { get, patch, del, post } from '../../infrastructure/api'

export const fetchFranchisee = (id: string): Promise<AxiosResponse> =>
  get(`/admin/franchisees/${id}`, {
    include: [
      'user',
      'userCount',
      'areas',
      'areasCount',
      'companiesCount',
      'premiumCompaniesCount',
    ],
  })

export const editFranchisee = (
  id: string,
  fields: { [key: string]: string },
): Promise<AxiosResponse> => patch(`/admin/franchisees/${id}`, fields)

export const deleteFranchisee = (id: string): Promise<AxiosResponse> =>
  del(`/admin/franchisees/${id}`)

export const updateCompanies = (id: string): Promise<AxiosResponse> =>
  post(`/admin/franchisees/${id}/distribute`)
