import { PayloadAction } from '@reduxjs/toolkit'
import { all, fork, put, takeEvery, call } from 'redux-saga/effects'
import { SagaIterator } from '@redux-saga/core'

import * as api from './api'
import { actions } from './slice'
import { FetchPayments } from './types'

function* fetchPayments(data: PayloadAction<FetchPayments>): SagaIterator {
  try {
    const { companyId, currentPage, perPage } = data.payload

    const incomingData = yield call(
      api.getPayments,
      companyId,
      currentPage,
      perPage,
    )

    yield put(actions.fetchPaymentsSuccess(incomingData))
  } catch (error) {
    // Sentry.captureException(error);
  }
}

export function* watchFeaturesSelectorSaga(): SagaIterator {
  yield takeEvery(actions.fetchPayments, fetchPayments)
}

export default function* watchFeaturesSelector(): SagaIterator {
  yield all([fork(watchFeaturesSelectorSaga)])
}
