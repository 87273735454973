import { all, fork, put, takeEvery, call } from 'redux-saga/effects'
import { SagaIterator } from '@redux-saga/core'
import { PayloadAction } from '@reduxjs/toolkit'
import {
  actions,
  FetchBusinessAccountSelectorSuccessPayload,
  FetchBusinessAccountSelectorPayload,
} from './slice'
import * as api from './api'
import { formatErrorMessage } from '../../utils/helpers'

function* fetchBusinessAccountSelectorSaga(
  action: PayloadAction<FetchBusinessAccountSelectorPayload>,
): SagaIterator {
  try {
    const businessAccount: FetchBusinessAccountSelectorSuccessPayload = yield call(
      api.fetchBusinessAccount,
      action.payload.search,
    )
    yield put(actions.fetchSuccess(businessAccount))
  } catch (error) {
    console.error('Fetch users failed', error)
    yield put(actions.fetchFailure(formatErrorMessage(error)))
  }
}

function* watchBusinessAccountSelectorSaga(): SagaIterator {
  yield takeEvery(actions.fetch, fetchBusinessAccountSelectorSaga)
}

export default function* watchBusinessAccountSelector(): SagaIterator {
  yield all([fork(watchBusinessAccountSelectorSaga)])
}
