import { AxiosResponse } from 'axios'
import { post, get, patch } from '../../infrastructure/api'
import { NotificationFormType } from './types'

export const createNotification = (
  body: NotificationFormType,
): Promise<AxiosResponse> => post('/admin/notification-tasks', {}, body)

export const editNotification = (
  notificationId: string,
  body: NotificationFormType,
): Promise<AxiosResponse> =>
  patch(`/admin/notification-tasks/${notificationId}`, {}, body)

export const uploadImageNotification = (
  notificationId: string,
  file: File,
): Promise<AxiosResponse> =>
  post(`admin/notification-tasks/${notificationId}/image`, {}, {}, [
    {
      field: 'image',
      item: file,
    },
  ])

export const fetchCompanyCategories = (): Promise<AxiosResponse> =>
  get('/admin/companies/categories')

export const fetchNotification = (
  notificationId: string,
): Promise<AxiosResponse> =>
  get(`/admin/notification-tasks/${notificationId}`, {
    include:
      'users,businessAccounts,userNoticeCategory,businessAccountNoticeCategory,creator,areas,companyCategories,notificationImage',
  })

export const getCountUsers = (
  body: NotificationFormType,
): Promise<AxiosResponse> =>
  post('admin/notification-tasks/user-count', {}, body)

export const executeMomentTask = (
  notificationId: string,
): Promise<AxiosResponse> =>
  post(`admin/notification-tasks/${notificationId}/execute`)

export const fetchNotificationInstances = (
  notificationId: string,
  page: number,
): Promise<AxiosResponse> =>
  get(`/admin/notification-task-instances`, {
    'filter[notification_task_id]': notificationId,
    page,
    page_size: 10,
    sort: '-created_at',
  })
