import { get } from '../../infrastructure/api'
import { FetchFranchiseeSelectorPayload } from './slice'
import { AxiosResponse } from 'axios'

export const fetchFranchiseesList = (
  search?: string,
): Promise<FetchFranchiseeSelectorPayload> => {
  return get('/admin/franchisees', {
    'filter[name]': search ?? '',
    count: 10,
  })
}

export const fetchFranchisee = (id: string): Promise<AxiosResponse> =>
  get(`/admin/franchisees/${id}`)
