import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { Area } from '../../types/area'
import { IMeta } from '../../types/mamado.types'

export type InitialStateType = {
  isFetching: boolean
  items: Area[]
  meta: IMeta | null
  error: string | null
}

export type FetchAreaSelectorPayload = {
  search: string
}

export type FetchAreaSelectorSuccessPayload = {
  data: Area[]
  meta: IMeta
}
export type FetchAreaSelectorFailurePayload = string | null

const initialState: InitialStateType = {
  isFetching: false,
  items: [],
  meta: null,
  error: null,
}

export const areaSelectorSlice = createSlice({
  name: 'areaSelector',
  initialState,
  reducers: {
    fetch(
      state: InitialStateType,
      _action: PayloadAction<FetchAreaSelectorPayload>,
    ): void {
      state.isFetching = true
    },
    fetchSuccess(
      state: InitialStateType,
      action: PayloadAction<FetchAreaSelectorSuccessPayload>,
    ): void {
      state.isFetching = false
      state.items = action.payload.data
      state.meta = action.payload.meta
    },
    fetchFailure(
      state: InitialStateType,
      action: PayloadAction<FetchAreaSelectorFailurePayload>,
    ): void {
      state.isFetching = false
      state.error = action.payload
    },
    destroy(): InitialStateType {
      return initialState
    },
  },
})

export const actions = areaSelectorSlice.actions

export default areaSelectorSlice.reducer
