import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import {
  InitialState,
  FetchSubscriptionsPayload,
  FetchSubscriptionsSuccessPayload,
  FetchSubscriptionsFailurePayload,
  RemoveSubscriptionPayload,
  RemoveSubscriptionSuccessPayload,
  RemoveSubscriptionFailurePayload,
  FetchPlansPayload,
  FetchPlansSuccessPayload,
  FetchPlansFailurePayload,
  CreateSubscriptionPayload,
  CreateSubscriptionSuccessPayload,
  CreateSubscriptionFailurePayload,
  ErrorFieldsType,
  SetErrorFieldsPayload,
} from './types'

const initialState: InitialState = {
  subscriptions: [],
  meta: null,
  isFetching: false,
  isRemoving: false,
  isFetchingPlans: false,
  isCreating: false,
  plans: [],
  error: null,
  errorFields: null,
  errorCreate: null,
}

export const companySubscriptions = createSlice({
  name: 'companySubscriptions',
  initialState,
  reducers: {
    fetch(
      state: InitialState,
      _action: PayloadAction<FetchSubscriptionsPayload>,
    ): void {
      state.isFetching = true
      state.errorFields = null
      state.error = null
    },
    fetchSuccess(
      state: InitialState,
      action: PayloadAction<FetchSubscriptionsSuccessPayload>,
    ): void {
      state.subscriptions = action.payload.data
      state.meta = action.payload.meta
      state.isFetching = false
    },
    fetchFailure(
      state: InitialState,
      action: PayloadAction<FetchSubscriptionsFailurePayload>,
    ): void {
      state.isFetching = false
      state.error = action.payload
    },
    remove(
      state: InitialState,
      _action: PayloadAction<RemoveSubscriptionPayload>,
    ): void {
      state.isRemoving = true
      state.error = null
    },
    removeSuccess(
      state: InitialState,
      _action: PayloadAction<RemoveSubscriptionSuccessPayload>,
    ): void {
      state.isRemoving = false
    },
    removeFailure(
      state: InitialState,
      action: PayloadAction<RemoveSubscriptionFailurePayload>,
    ): void {
      state.isRemoving = false
      state.error = action.payload
    },
    fetchPlans(
      state: InitialState,
      _action: PayloadAction<FetchPlansPayload>,
    ): void {
      state.isFetchingPlans = true
      state.errorFields = null
      state.errorCreate = null
    },
    fetchPlansSuccess(
      state: InitialState,
      action: PayloadAction<FetchPlansSuccessPayload>,
    ): void {
      state.plans = action.payload
      state.isFetchingPlans = false
    },
    fetchPlansFailure(
      state: InitialState,
      action: PayloadAction<FetchPlansFailurePayload>,
    ): void {
      state.isFetchingPlans = false
      state.errorCreate = action.payload
    },
    create(
      state: InitialState,
      _action: PayloadAction<CreateSubscriptionPayload>,
    ): void {
      state.isCreating = true
      state.errorFields = null
      state.errorCreate = null
    },
    createSuccess(
      state: InitialState,
      _action: PayloadAction<CreateSubscriptionSuccessPayload>,
    ): void {
      state.isCreating = false
    },
    createFailure(
      state: InitialState,
      action: PayloadAction<CreateSubscriptionFailurePayload>,
    ): void {
      state.isCreating = false
      state.errorCreate = action.payload
    },
    setErrorFields(
      state: InitialState,
      action: PayloadAction<SetErrorFieldsPayload>,
    ): void {
      const result: ErrorFieldsType[] = []

      for (const item in action.payload) {
        result.push({
          name: item,
          errors: action.payload[item],
        })
      }

      state.errorFields = result
    },
  },
})

export const actions = companySubscriptions.actions

export default companySubscriptions.reducer
