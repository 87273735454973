import { all, fork, put, takeLatest, call } from 'redux-saga/effects'
import { SagaIterator } from '@redux-saga/core'
import { actions, FetchAreaTreeSelectorSuccessPayload } from './slice'
import * as api from './api'
import { formatErrorMessage } from '../../utils/helpers'

function* fetchAreaTreeSelectorSaga(): SagaIterator {
  try {
    const areas: FetchAreaTreeSelectorSuccessPayload = yield call(
      api.fetchAreasTree,
    )
    yield put(actions.fetchSuccess(areas[0]?.children || []))
  } catch (error) {
    console.error('Fetch areas tree failed', error)
    yield put(actions.fetchFailure(formatErrorMessage(error)))
  }
}

export function* watchAreaTreeSelectorSaga(): SagaIterator {
  yield takeLatest(actions.fetch, fetchAreaTreeSelectorSaga)
}

export default function* watchAreaTreeSelector(): SagaIterator {
  yield all([fork(watchAreaTreeSelectorSaga)])
}
