import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import {
  ChangeInputType,
  CreateFranchiseePayloadType,
  ErrorItemType,
} from './types'

export type InitialStateType = {
  fields: CreateFranchiseePayloadType
  isCreating: boolean
  error: string | null
}

export type CreateFranchiseeSuccessPayload = undefined
export type CreateFranchiseeFailurePayload = undefined | ErrorItemType
export type CreateFranchiseeDestroyPayload = undefined

const initialState: InitialStateType = {
  fields: {
    name: {
      label: 'Название',
      value: '',
      error: '',
    },
    userId: {
      label: 'Пользователь',
      value: '',
      error: '',
    },
    area_ids: {
      label: 'Зона действия',
      value: '',
      error: '',
    },
    royalty: {
      label: 'Роялти',
      value: '',
      error: '',
    },
    externalShopId: {
      label: 'Идентификатор магазина',
      value: '',
      error: '',
    },
    externalShopSecret: {
      label: 'Секретный ключ магазина',
      value: '',
      error: '',
    },
  },
  isCreating: false,
  error: null,
}

export const createFranchiseeSlice = createSlice({
  name: 'createFranchisee',
  initialState,
  reducers: {
    changeInput(
      state: InitialStateType,
      action: PayloadAction<ChangeInputType>,
    ): void {
      state.fields[action.payload.key].value = action.payload.value
    },
    create(
      state: InitialStateType,
      _action: PayloadAction<CreateFranchiseePayloadType>,
    ): void {
      state.isCreating = true
      for (const item in state.fields) {
        state.fields[item].error = ''
      }
      state.error = initialState.error
    },
    createSuccess(
      state: InitialStateType,
      _action: PayloadAction<CreateFranchiseeSuccessPayload>,
    ): void {
      state.isCreating = false
    },
    createFailure(
      state: InitialStateType,
      action: PayloadAction<CreateFranchiseeFailurePayload>,
    ): void {
      state.isCreating = false
      if (typeof action.payload === 'object') {
        for (const item in action.payload) {
          state.fields[item].error = action.payload[item][0]
        }
      } else {
        state.error = 'Ошибка создания франчайзи'
      }
    },
    destroy(
      _state: InitialStateType,
      _action: PayloadAction<CreateFranchiseeDestroyPayload>,
    ): InitialStateType {
      return initialState
    },
  },
})

export const actions = createFranchiseeSlice.actions

export default createFranchiseeSlice.reducer
