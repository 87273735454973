import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { DateFilterType, FranchiseeItemType, FranchiseeMetaType } from './types'

export type InitialStateType = {
  items: FranchiseeItemType[]
  meta: FranchiseeMetaType | null
  isFetching: boolean
  error: string | null
}

export type FetchFranchiseePayload = {
  page: number
  dateFilter: DateFilterType | null
}
export type FetchFranchiseeSuccessPayload = {
  data: FranchiseeItemType[]
  meta: FranchiseeMetaType
}
export type FetchFranchiseeFailurePayload = string | null

const initialState: InitialStateType = {
  items: [],
  meta: null,
  isFetching: false,
  error: null,
}

export const franchiseeReportSlice = createSlice({
  name: 'franchiseeReport',
  initialState,
  reducers: {
    fetch(
      state: InitialStateType,
      _action: PayloadAction<FetchFranchiseePayload>,
    ): void {
      state.isFetching = true
    },
    fetchSuccess(
      state: InitialStateType,
      action: PayloadAction<FetchFranchiseeSuccessPayload>,
    ): void {
      state.items = action.payload.data
      state.meta = action.payload.meta
      state.isFetching = false
    },
    fetchFailure(
      state: InitialStateType,
      action: PayloadAction<FetchFranchiseeFailurePayload>,
    ): void {
      state.isFetching = false
      state.error = action.payload
    },
  },
})

export const actions = franchiseeReportSlice.actions

export default franchiseeReportSlice.reducer
