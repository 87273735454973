import { all, fork, put, takeEvery, call } from 'redux-saga/effects'
import { SagaIterator } from '@redux-saga/core'
import { actions } from './slice'
import * as api from './api'
import { FetchAnalyticsPayload } from './types'
import { PayloadAction } from '@reduxjs/toolkit'
import { formatErrorMessage } from '../../utils/helpers'

function* fetchAnalyticsSaga(
  action: PayloadAction<FetchAnalyticsPayload>,
): SagaIterator {
  try {
    const { companyId, dateStart, dateEnd } = action.payload
    const analytics = yield call(
      api.fetchAnalytics,
      companyId,
      dateStart,
      dateEnd,
    )
    yield put(actions.fetchSuccess({ data: analytics }))
  } catch (error) {
    console.error('Fetch analytics failed', error)
    yield put(actions.fetchFailure({ error: formatErrorMessage(error) }))
  }
}

export function* watchFetchAnalyticsSaga(): SagaIterator {
  yield takeEvery(actions.fetch, fetchAnalyticsSaga)
}

export default function* watchCompanyAnalytics(): SagaIterator {
  yield all([fork(watchFetchAnalyticsSaga)])
}
