import React from 'react'
import { Avatar, Row, Popover } from 'antd'
import { BiLogOut } from 'react-icons/bi'
import './index.css'
import { useDispatch, useSelector } from 'react-redux'
import { actions, InitialStateType } from '../../containers/profile/slice'

const UserAvatar: React.FC = () => {
  const dispatch = useDispatch()

  const { profile } = useSelector(
    (state: { profile: InitialStateType }) => state.profile,
  )

  const handleSignOut = (): void => {
    dispatch(actions.signOut())
  }

  const roleName: { [key: string]: string } = {
    admin: 'Администратор',
    // eslint-disable-next-line @typescript-eslint/camelcase
    admin_ro: 'Администратор (RO)',
    franchisee: 'Франчайзи',
  }

  if (!profile) return null

  const username = profile.full_name || profile.email || profile.phone

  return (
    <div className="header-content-avatar-wrapper" role="button">
      <Avatar src={profile.photo_thumbnail} size={45} />
      <div className="header-content-avatar-main">
        <Popover content={username}>
          <div className="header-content-avatar-main-user-name">{username}</div>
        </Popover>
        <div className="header-content-avatar-main-user-type">
          {
            roleName[
              profile.roles.find(
                (role) =>
                  role === 'admin' ||
                  role === 'admin_ro' ||
                  role === 'franchisee',
              ) || 'admin'
            ]
          }
        </div>
      </div>
      <BiLogOut
        onClick={handleSignOut}
        className="header-content-avatar-chevron"
      />
    </div>
  )
}

const Header: React.FC = () => {
  return (
    <Row className="header-content-container">
      <Row className="header-content-container-centered">
        {/*
        <Badge count={120} className="header-content-container-icon">
          <BsFillBellFill />
        </Badge>
        <Badge count={2} className="header-content-container-icon">
          <BsFillChatFill />
        </Badge>
        */}
      </Row>
      <Row justify="end" className="header-content-container-centered">
        <UserAvatar />
      </Row>
    </Row>
  )
}

export default Header
