import { all, fork, put, takeEvery, call } from 'redux-saga/effects'
import { SagaIterator } from '@redux-saga/core'
import { actions } from './slice'
import * as api from './api'
import { CreateFranchiseePayloadType } from './types'
import { PayloadAction } from '@reduxjs/toolkit'
import { Router } from '../../routes'

function* createFranchiseeSaga(
  action: PayloadAction<CreateFranchiseePayloadType>,
): SagaIterator {
  try {
    const {
      name,
      userId,
      area_ids,
      royalty,
      externalShopId,
      externalShopSecret,
    } = action.payload
    const franchisee = yield call(api.createFranchisee, {
      name: name.value,
      userId: userId.value,
      area_ids:
        Array.isArray(area_ids.value) && area_ids.value.length
          ? area_ids.value.map((i: { value: string }) => i.value)
          : [],
      royalty: royalty.value,
      externalShopId: externalShopId.value,
      externalShopSecret: externalShopSecret.value,
    })
    yield put(actions.createSuccess())
    Router.pushRoute(`/franchisee/${franchisee.franchisee_id}`)
  } catch (error) {
    console.error('Create franchisee failed', error)
    // Sentry.captureException(error);
    yield put(actions.createFailure(error?.response?.data?.errors))
  }
}

export function* watchCreateFranchiseeSaga(): SagaIterator {
  yield takeEvery(actions.create, createFranchiseeSaga)
}

export default function* watchCreateFranchisee(): SagaIterator {
  yield all([fork(watchCreateFranchiseeSaga)])
}
