import { get } from './../../infrastructure/api'
import { FetchFeaturesSelectorSuccessPayload } from './slice'

export const fetchFeatures = (
  params: Record<string, any>,
): Promise<FetchFeaturesSelectorSuccessPayload> => {
  const { categories = [] } = params
  return get('/admin/companies/features', {
    'filter[categories]': categories.join(','),
  })
}
