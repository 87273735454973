import { all, fork, put, takeEvery, call } from 'redux-saga/effects'
import { SagaIterator } from '@redux-saga/core'
import { actions } from './slice'
import * as api from './api'
import { formatErrorMessage } from '../../utils/helpers'
import {
  EditContentPayload,
  AddVideoPayload,
  FetchContentPayload,
  DeleteMediaPayload,
  ReOrderMediaPayload,
} from './types'
import { Router } from '../../routes'

function* fetchContentSaga(action: {
  payload: FetchContentPayload
}): SagaIterator {
  try {
    const content = yield call(api.fetchContent, action.payload)
    yield put(actions.fetchSuccess(content))
  } catch (error) {
    console.error('Cannot fetch content image failed', error)
    yield put(actions.fetchFailure(formatErrorMessage(error)))
  }
}

function* editContentSaga(action: {
  payload: EditContentPayload
}): SagaIterator {
  try {
    const { pageId, data } = action.payload
    yield call(api.editContent, pageId, data)
    yield put(actions.editSuccess())
    Router.pushRoute(`/content`)
  } catch (error) {
    console.error('Edit page failed', error)
    yield put(
      actions.editFailure({
        message: formatErrorMessage(error),
        fields: error.response.data?.errors,
      }),
    )
  }
}

function* sendContentVideoSaga(action: {
  payload: AddVideoPayload
}): SagaIterator {
  try {
    const content = yield call(api.sendContentVideo, action.payload)
    yield put(actions.sendVideoSuccess(content.videos))
  } catch (error) {
    console.error('Cannot send content video failed', error)
    yield put(actions.sendVideoFailure(formatErrorMessage(error)))
  }
}

function* deleteMediaSaga(action: {
  payload: DeleteMediaPayload
}): SagaIterator {
  try {
    const { pageId, mediaId, type } = action.payload
    yield call(api.deleteMedia, pageId, mediaId, type)
    yield put(actions.deleteMediaSuccess())
  } catch (error) {
    console.error('Cannot delete media failed', error)
    yield put(actions.deleteMediaFailure(formatErrorMessage(error)))
  }
}

function* reOrderMediaSaga(action: {
  payload: ReOrderMediaPayload
}): SagaIterator {
  try {
    const { pageId, mediaIds, type } = action.payload
    yield call(api.reOrderMedia, pageId, mediaIds, type)
    yield put(actions.reOrderMediaSuccess())
  } catch (error) {
    console.error('Cannot reorder media failed', error)
    yield put(actions.reOrderMediaFailure(formatErrorMessage(error)))
  }
}

export function* watchFetchContentSaga(): SagaIterator {
  yield takeEvery(actions.fetch, fetchContentSaga)
}

export function* watchEditContentSaga(): SagaIterator {
  yield takeEvery(actions.edit, editContentSaga)
}

export function* watchSendContentVideoSaga(): SagaIterator {
  yield takeEvery(actions.sendVideo, sendContentVideoSaga)
}

export function* watchDeleteMediaSaga(): SagaIterator {
  yield takeEvery(actions.deleteMedia, deleteMediaSaga)
}

export function* watchReOrderMediaSaga(): SagaIterator {
  yield takeEvery(actions.reOrderMedia, reOrderMediaSaga)
}

export default function* watchEditContent(): SagaIterator {
  yield all([
    fork(watchFetchContentSaga),
    fork(watchEditContentSaga),
    fork(watchSendContentVideoSaga),
    fork(watchDeleteMediaSaga),
    fork(watchReOrderMediaSaga),
  ])
}
