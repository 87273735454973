import { AxiosResponse } from 'axios'
import humps from 'lodash-humps-ts'
import { get, post } from '../../infrastructure/api'

export const fetchStatusIntegration = (url: string): Promise<AxiosResponse> =>
  get(url)

export const sendAuthorization = (data: {
  [key: string]: string
}): Promise<AxiosResponse> => post('/admin/amo/authorize', {}, humps(data))
