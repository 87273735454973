import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import {
  InitialStateType,
  FetchPlansPayload,
  FetchPlansSuccessPayload,
  FetchPlansFailurePayload,
} from './types'

const initialState: InitialStateType = {
  plans: [],
  isFetching: false,
  error: null,
}

export const plansSlice = createSlice({
  name: 'plans',
  initialState,
  reducers: {
    fetch(
      state: InitialStateType,
      _action: PayloadAction<FetchPlansPayload>,
    ): void {
      state.isFetching = true
      state.error = null
    },
    fetchSuccess(
      state: InitialStateType,
      action: PayloadAction<FetchPlansSuccessPayload>,
    ): void {
      state.plans = action.payload.data
      state.isFetching = false
    },
    fetchFailure(
      state: InitialStateType,
      action: PayloadAction<FetchPlansFailurePayload>,
    ): void {
      state.isFetching = false
      state.error = action.payload.error
    },
  },
})

export const actions = plansSlice.actions

export default plansSlice.reducer
