import { all, fork, put, takeEvery, call } from 'redux-saga/effects'
import { SagaIterator } from '@redux-saga/core'
import {
  actions,
  FetchFeaturesSelectorSuccessPayload,
  FetchFeaturesSelectorPayload,
} from './slice'
import * as api from './api'
import { PayloadAction } from '@reduxjs/toolkit'
import { formatErrorMessage } from '../../utils/helpers'

function* fetchFeaturesSelectorSaga(
  action: PayloadAction<FetchFeaturesSelectorPayload>,
): SagaIterator {
  try {
    const features: FetchFeaturesSelectorSuccessPayload = yield call(
      api.fetchFeatures,
      action.payload,
    )
    yield put(actions.fetchSuccess(features))
  } catch (error) {
    console.error('Fetch categories failed', error)
    yield put(actions.fetchFailure(formatErrorMessage(error)))
  }
}

export function* watchFeaturesSelectorSaga(): SagaIterator {
  yield takeEvery(actions.fetch, fetchFeaturesSelectorSaga)
}

export default function* watchFeaturesSelector(): SagaIterator {
  yield all([fork(watchFeaturesSelectorSaga)])
}
