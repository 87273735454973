import { AxiosResponse } from 'axios'
import { get } from '../../infrastructure/api'

export const fetchFranchisees = (
  page: number,
  dateStart?: string,
  dateEnd?: string,
): Promise<AxiosResponse> =>
  get('/admin/reports/franchisee', {
    include: ['companiesCount', 'premiumCompaniesCount'],
    page,
    ...(dateStart || dateEnd
      ? { 'filter[from]': dateStart || '', 'filter[to]': dateEnd || '' }
      : {}),
  })
