import { AxiosResponse } from 'axios'
import { get, post, patch, del } from '../../infrastructure/api'
import { FormItemsType } from './types'

export const fetchBanners = (): Promise<AxiosResponse> =>
  get('/admin/banners', { page_size: 1000 })

export const fetchBlocks = (page: number): Promise<AxiosResponse> =>
  get('/admin/blocks', {
    page,
    include: 'banners,banners.image,banners.category,banners.page',
  })

export const fetchAllBlocks = (): Promise<AxiosResponse> =>
  get('/admin/blocks', {
    page_size: 10000,
  })

export const fetchCategories = (): Promise<AxiosResponse> =>
  get('/v2/categories', { show_invisible: true })

export const fetchPages = (): Promise<AxiosResponse> =>
  get('/admin/pages', { page_size: 10000, sort: '-created_at' })

export const createBanner = (data: FormItemsType): Promise<AxiosResponse> =>
  post('/admin/banners', {}, data)

export const editBanner = (
  bannerId: string,
  data: FormItemsType,
): Promise<AxiosResponse> => patch(`/admin/banners/${bannerId}`, {}, data)

export const removeBanner = (blockId: string): Promise<AxiosResponse> =>
  del(`/admin/banners/${blockId}`)

export const sendImage = (
  bannerId: string,
  image: File,
): Promise<AxiosResponse> =>
  post(`/admin/banners/${bannerId}/image`, {}, {}, [
    {
      field: 'image',
      item: image,
    },
  ])

export const removeImage = (bannerId: string): Promise<AxiosResponse> =>
  del(`/admin/banners/${bannerId}/image`)
