import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { PropertySectorItem, OptionItemType } from './types'

export type InitialStateType = {
  isFetching: boolean
  items: PropertySectorItem[]
  options: { [key: string]: OptionItemType[] }
  error: string | null
}

export type FetchCompanyPropertySelectorPayload = undefined

export type FetchCompanyPropertySelectorSuccessPayload = PropertySectorItem[]

export type FetchCompanyPropertySelectorFailurePayload = string | null

export type FetchOptionsPayload = string

export type FetchOptionsSuccessPayload = { key: string; data: OptionItemType[] }

export type FetchOptionsFailurePayload = string | null

const initialState: InitialStateType = {
  isFetching: false,
  items: [],
  options: {},
  error: null,
}

export const companyPropertySelector = createSlice({
  name: 'companyPropertySelector',
  initialState,
  reducers: {
    fetch(
      state: InitialStateType,
      _action: PayloadAction<FetchCompanyPropertySelectorPayload>,
    ): void {
      state.isFetching = true
    },
    fetchSuccess(
      state: InitialStateType,
      action: PayloadAction<FetchCompanyPropertySelectorSuccessPayload>,
    ): void {
      state.isFetching = false
      state.items = action.payload
    },
    fetchFailure(
      state: InitialStateType,
      action: PayloadAction<FetchCompanyPropertySelectorFailurePayload>,
    ): void {
      state.items = []
      state.isFetching = false
      state.error = action.payload
    },
    fetchOptions(
      state: InitialStateType,
      _action: PayloadAction<FetchOptionsPayload>,
    ): void {
      state.isFetching = true
    },
    fetchOptionsSuccess(
      state: InitialStateType,
      action: PayloadAction<FetchOptionsSuccessPayload>,
    ): void {
      state.isFetching = false
      state.options = {
        ...state.options,
        [action.payload.key]: action.payload.data,
      }
    },
    fetchOptionsFailure(
      state: InitialStateType,
      action: PayloadAction<FetchOptionsFailurePayload>,
    ): void {
      state.isFetching = false
      state.error = action.payload
    },
    destroy(): InitialStateType {
      return initialState
    },
  },
})

export const actions = companyPropertySelector.actions

export default companyPropertySelector.reducer
