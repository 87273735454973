import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import {
  InitialStateType,
  FetchBannersPayload,
  FetchBannersSuccessPayload,
  FetchBannersFailurePayload,
  FetchCategoriesPayload,
  FetchCategoriesSuccessPayload,
  FetchCategoriesFailurePayload,
  FetchPagesPayload,
  FetchPagesSuccessPayload,
  FetchPagesFailurePayload,
  ShowModalPayload,
  CloseModalPayload,
  CreateBannerPayload,
  CreateBannerSuccessPayload,
  CreateBannerFailurePayload,
  EditBannerPayload,
  EditBannerSuccessPayload,
  EditBannerFailurePayload,
  RemoveBannerPayload,
  RemoveBannerSuccessPayload,
  RemoveBannerFailurePayload,
  FetchBlocksPayload,
  FetchBlocksSuccessPayload,
  FetchBlocksFailurePayload,
  SendImagePayload,
  SendImageSuccessPayload,
  SendImageFailurePayload,
  RemoveImagePayload,
  RemoveImageSuccessPayload,
  RemoveImageFailurePayload,
  FetchAllBlocksPayload,
  FetchAllBlocksSuccessPayload,
  FetchAllBlocksFailurePayload,
} from './types'
import { buildFieldErrors } from '../../utils/helpers'

const initialState: InitialStateType = {
  blocks: [],
  allBlocks: [],
  meta: null,
  banners: [],
  categories: [],
  pages: [],
  isFetching: false,
  isFetchingBlocks: false,
  isFetchingCategories: false,
  isFetchingPages: false,
  isModalWizard: false,
  selectedBanner: null,
  isProcessCreate: false,
  isProcessEdit: false,
  isProcessRemove: false,
  isProcessImage: false,
  isRemoveImage: false,
  error: null,
  errorForm: null,
  errorImage: null,
}

export const bannersSlice = createSlice({
  name: 'banners',
  initialState,
  reducers: {
    fetch(
      state: InitialStateType,
      _action: PayloadAction<FetchBannersPayload>,
    ): void {
      state.isFetching = true
      state.isProcessCreate = false
      state.isProcessEdit = false
      state.isModalWizard = false
      state.error = null
    },
    fetchSuccess(
      state: InitialStateType,
      action: PayloadAction<FetchBannersSuccessPayload>,
    ): void {
      state.banners = action.payload
      state.isFetching = false
    },
    fetchFailure(
      state: InitialStateType,
      action: PayloadAction<FetchBannersFailurePayload>,
    ): void {
      state.isFetching = false
      state.error = action.payload
    },
    fetchBlocks(
      state: InitialStateType,
      _action: PayloadAction<FetchBlocksPayload>,
    ): void {
      state.isFetchingBlocks = true
      state.error = null
    },
    fetchBlocksSuccess(
      state: InitialStateType,
      action: PayloadAction<FetchBlocksSuccessPayload>,
    ): void {
      state.blocks = action.payload.data
      state.meta = action.payload.meta
      state.isFetchingBlocks = false
    },
    fetchBlocksFailure(
      state: InitialStateType,
      action: PayloadAction<FetchBlocksFailurePayload>,
    ): void {
      state.isFetchingBlocks = false
      state.error = action.payload
    },
    fetchAllBlocks(
      state: InitialStateType,
      _action: PayloadAction<FetchAllBlocksPayload>,
    ): void {
      state.isFetchingBlocks = true
      state.error = null
    },
    fetchAllBlocksSuccess(
      state: InitialStateType,
      action: PayloadAction<FetchAllBlocksSuccessPayload>,
    ): void {
      state.allBlocks = action.payload
      state.isFetchingBlocks = false
    },
    fetchAllBlocksFailure(
      state: InitialStateType,
      action: PayloadAction<FetchAllBlocksFailurePayload>,
    ): void {
      state.isFetchingBlocks = false
      state.error = action.payload
    },
    fetchCategories(
      state: InitialStateType,
      _action: PayloadAction<FetchCategoriesPayload>,
    ): void {
      state.isFetchingCategories = true
    },
    fetchCategoriesSuccess(
      state: InitialStateType,
      action: PayloadAction<FetchCategoriesSuccessPayload>,
    ): void {
      state.categories = action.payload
      state.isFetchingCategories = false
    },
    fetchCategoriesFailure(
      state: InitialStateType,
      action: PayloadAction<FetchCategoriesFailurePayload>,
    ): void {
      state.isFetchingCategories = false
      state.error = action.payload
    },
    fetchPages(
      state: InitialStateType,
      _action: PayloadAction<FetchPagesPayload>,
    ): void {
      state.isFetchingPages = true
    },
    fetchPagesSuccess(
      state: InitialStateType,
      action: PayloadAction<FetchPagesSuccessPayload>,
    ): void {
      state.pages = action.payload
      state.isFetchingPages = false
    },
    fetchPagesFailure(
      state: InitialStateType,
      action: PayloadAction<FetchPagesFailurePayload>,
    ): void {
      state.isFetchingPages = false
      state.error = action.payload
    },
    showModal(
      state: InitialStateType,
      action: PayloadAction<ShowModalPayload>,
    ): void {
      if (action.payload) {
        state.selectedBanner = action.payload
      }
      state.isModalWizard = true
      state.errorForm = null
      state.errorImage = null
    },
    closeModal(
      state: InitialStateType,
      _action: PayloadAction<CloseModalPayload>,
    ): void {
      state.isModalWizard = false
      state.selectedBanner = null
    },
    create(
      state: InitialStateType,
      _action: PayloadAction<CreateBannerPayload>,
    ): void {
      state.isProcessCreate = true
      state.errorForm = null
    },
    createSuccess(
      state: InitialStateType,
      _action: PayloadAction<CreateBannerSuccessPayload>,
    ): void {
      state.isProcessCreate = false
      state.selectedBanner = null
    },
    createFailure(
      state: InitialStateType,
      action: PayloadAction<CreateBannerFailurePayload>,
    ): void {
      state.isProcessCreate = false
      state.errorForm = buildFieldErrors(action.payload)
    },
    edit(
      state: InitialStateType,
      _action: PayloadAction<EditBannerPayload>,
    ): void {
      state.isProcessEdit = true
      state.errorForm = null
    },
    editSuccess(
      state: InitialStateType,
      _action: PayloadAction<EditBannerSuccessPayload>,
    ): void {
      state.isProcessEdit = false
      state.selectedBanner = null
    },
    editFailure(
      state: InitialStateType,
      action: PayloadAction<EditBannerFailurePayload>,
    ): void {
      state.isProcessEdit = false
      state.errorForm = buildFieldErrors(action.payload)
    },
    remove(
      state: InitialStateType,
      _action: PayloadAction<RemoveBannerPayload>,
    ): void {
      state.isProcessRemove = true
    },
    removeSuccess(
      state: InitialStateType,
      _action: PayloadAction<RemoveBannerSuccessPayload>,
    ): void {
      state.isProcessRemove = false
    },
    removeFailure(
      state: InitialStateType,
      action: PayloadAction<RemoveBannerFailurePayload>,
    ): void {
      state.isProcessRemove = false
      state.error = action.payload
    },
    sendImage(
      state: InitialStateType,
      _action: PayloadAction<SendImagePayload>,
    ): void {
      state.isProcessImage = true
      state.errorImage = null
    },
    sendImageSuccess(
      state: InitialStateType,
      _action: PayloadAction<SendImageSuccessPayload>,
    ): void {
      state.isProcessImage = false
    },
    sendImageFailure(
      state: InitialStateType,
      action: PayloadAction<SendImageFailurePayload>,
    ): void {
      state.isProcessImage = false
      state.errorImage = action.payload
    },
    removeImage(
      state: InitialStateType,
      _action: PayloadAction<RemoveImagePayload>,
    ): void {
      state.isProcessImage = true
      state.errorImage = null
    },
    removeImageSuccess(
      state: InitialStateType,
      _action: PayloadAction<RemoveImageSuccessPayload>,
    ): void {
      state.isProcessImage = false
    },
    removeImageFailure(
      state: InitialStateType,
      action: PayloadAction<RemoveImageFailurePayload>,
    ): void {
      state.isProcessImage = false
      state.errorImage = action.payload
    },
  },
})

export const actions = bannersSlice.actions

export default bannersSlice.reducer
