import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import {
  ChangeInputType,
  FranchiseeFieldsType,
  FranchiseeType,
  FranchiseeValueType,
} from './types'
import { ErrorItemType } from '../createFranchisee/types'

export type InitialStateType = {
  franchiseeId: string
  savedName: string
  fields: FranchiseeFieldsType
  companiesCount: number
  premiumCompaniesCount: number
  isFetching: boolean
  isProcessEdit: boolean
  isProcessUpdateCompanies: boolean
  isProcessDelete: boolean
  wasLoaded: boolean
  error: string | null
}

const mapToFields = (franchisee: FranchiseeType): FranchiseeFieldsType => {
  return {
    name: {
      label: 'Название',
      value: franchisee.name,
      error: '',
    },
    userId: {
      label: franchisee.user.full_name
        ? franchisee.user.full_name.toString()
        : franchisee.user.phone || franchisee.user.email,
      value: franchisee.user_id,
      error: '',
    },
    area_ids: {
      label: '',
      value: franchisee.areas.map((i) => ({
        label: i.name,
        value: i.area_id,
      })) as any,
      error: '',
    },
    royalty: {
      label: 'Роялти',
      value: franchisee.royalty.toString(),
      error: '',
    },
    externalShopId: {
      label: 'Идентификатор магазина',
      value: franchisee.external_shop_id,
      error: '',
    },
    externalShopSecret: {
      label: 'Секретный ключ магазина',
      value: franchisee.external_shop_secret,
      error: '',
    },
  }
}

export type FetchFranchiseePayload = string
export type FetchFranchiseeSuccessPayload = FranchiseeType
export type FetchFranchiseeFailurePayload = string | null

export type EditFranchiseeSuccessPayload = undefined
export type EditFranchiseeFailurePayload = undefined | ErrorItemType

export type UpdateCompaniesPayload = string
export type UpdateCompaniesFailurePayload = string | null

export type DeleteFranchiseePayload = string
export type DeleteFranchiseeFailurePayload = string | null

const initialState: InitialStateType = {
  franchiseeId: '',
  savedName: '',
  fields: {
    name: {
      label: 'Название',
      value: '',
      error: '',
    },
    userId: {
      label: 'Пользователь',
      value: '',
      error: '',
    },
    area_ids: {
      label: 'Зона действия',
      value: '',
      error: '',
    },
    royalty: {
      label: 'Роялти',
      value: '',
      error: '',
    },
    externalShopId: {
      label: 'Идентификатор магазина',
      value: '',
      error: '',
    },
    externalShopSecret: {
      label: 'Секретный ключ магазина',
      value: '',
      error: '',
    },
  },
  companiesCount: 0,
  premiumCompaniesCount: 0,
  isFetching: false,
  isProcessEdit: false,
  isProcessUpdateCompanies: false,
  isProcessDelete: false,
  wasLoaded: false,
  error: null,
}

export const editFranchiseeSlice = createSlice({
  name: 'editFranchisee',
  initialState,
  reducers: {
    changeInput(
      state: InitialStateType,
      action: PayloadAction<ChangeInputType>,
    ): void {
      state.fields[action.payload.key].value = action.payload.value
      state.fields[action.payload.key].error = ''
    },
    fetch(
      state: InitialStateType,
      _action: PayloadAction<FetchFranchiseePayload>,
    ): void {
      state.isFetching = true
    },
    fetchSuccess(
      state: InitialStateType,
      action: PayloadAction<FetchFranchiseeSuccessPayload>,
    ): void {
      state.franchiseeId = action.payload.franchisee_id
      state.savedName = action.payload.name
      state.fields = mapToFields(action.payload)
      state.companiesCount = action.payload.companies_count || 0
      state.premiumCompaniesCount = action.payload.premiumCompanies_count || 0
      state.isFetching = false
      state.wasLoaded = true
    },
    fetchFailure(
      state: InitialStateType,
      _action: PayloadAction<FetchFranchiseeFailurePayload>,
    ): void {
      state.isFetching = false
    },
    edit(
      state: InitialStateType,
      _action: PayloadAction<{ id: string; fields: FranchiseeValueType }>,
    ): void {
      state.isProcessEdit = true
      for (const item in state.fields) {
        state.fields[item].error = ''
      }
      state.error = initialState.error
    },
    editSuccess(
      state: InitialStateType,
      _action: PayloadAction<EditFranchiseeSuccessPayload>,
    ): void {
      state.isProcessEdit = false
    },
    editFailure(
      state: InitialStateType,
      action: PayloadAction<EditFranchiseeFailurePayload>,
    ): void {
      state.isProcessEdit = false
      if (typeof action.payload === 'object') {
        for (const item in action.payload) {
          state.fields[item].error = action.payload[item][0]
        }
      } else {
        state.error = 'Ошибка редактирования франчайзи'
      }
    },
    setError(
      state: InitialStateType,
      action: PayloadAction<{ key: string; error: string }>,
    ): void {
      state.fields[action.payload.key].error = action.payload.error
    },
    delete(
      state: InitialStateType,
      _action: PayloadAction<DeleteFranchiseePayload>,
    ): void {
      state.isProcessDelete = true
    },
    deleteSuccess(state: InitialStateType): void {
      state.isProcessDelete = false
    },
    deleteFailure(
      state: InitialStateType,
      action: PayloadAction<DeleteFranchiseeFailurePayload>,
    ): void {
      state.isProcessDelete = false
      state.error = action.payload
    },
    updateCompanies(
      state: InitialStateType,
      _action: PayloadAction<UpdateCompaniesPayload>,
    ): void {
      state.isProcessUpdateCompanies = true
    },
    updateCompaniesSuccess(state: InitialStateType): void {
      state.isProcessUpdateCompanies = false
    },
    updateCompaniesFailure(
      state: InitialStateType,
      action: PayloadAction<UpdateCompaniesFailurePayload>,
    ): void {
      state.isProcessUpdateCompanies = false
      state.error = action.payload
    },
    destroy(state: InitialStateType): void {
      state.fields = initialState.fields
      state.wasLoaded = initialState.wasLoaded
      state.error = initialState.error
    },
  },
})

export const actions = editFranchiseeSlice.actions

export default editFranchiseeSlice.reducer
