import { all, fork, put, takeEvery, call } from 'redux-saga/effects'
import { SagaIterator } from '@redux-saga/core'
import { actions, FetchFranchiseePayload } from './slice'
import * as api from './api'
import { formatErrorMessage } from '../../utils/helpers'
import moment from 'moment'

function* fetchFranchiseesSaga(action: {
  payload: FetchFranchiseePayload
}): SagaIterator {
  try {
    const { page, dateFilter } = action.payload
    const dateStart =
      dateFilter && moment(dateFilter.dateStart).isValid()
        ? moment(dateFilter.dateStart).format('YYYYMMDD')
        : ''
    const dateEnd =
      dateFilter && moment(dateFilter.dateEnd).isValid()
        ? moment(dateFilter.dateEnd).format('YYYYMMDD')
        : ''
    const franchisess = yield call(
      api.fetchFranchisees,
      page,
      dateStart,
      dateEnd,
    )
    yield put(
      actions.fetchSuccess({
        data: franchisess.data,
        meta: {
          currentPage: franchisess.current_page,
          perPage: franchisess.per_page,
          total: franchisess.total,
        },
      }),
    )
  } catch (error) {
    console.error('Fetch franchisees failed', error)
    yield put(actions.fetchFailure(formatErrorMessage(error)))
  }
}

export function* watchFetchFranchiseesSaga(): SagaIterator {
  yield takeEvery(actions.fetch, fetchFranchiseesSaga)
}

export default function* watchFranchiseeReport(): SagaIterator {
  yield all([fork(watchFetchFranchiseesSaga)])
}
