import { all, fork, put, takeEvery, call, select } from 'redux-saga/effects'
import { SagaIterator } from '@redux-saga/core'
import { actions, FetchEventPropertySelectorSuccessPayload } from './slice'
import * as api from './api'
import { formatErrorMessage } from '../../utils/helpers'
import { PropertySectorItem } from './types'

const getProperties = (state: {
  eventPropertySelector: { items: PropertySectorItem[] }
}): PropertySectorItem[] => state.eventPropertySelector.items

function* fetchEventPropertySelectorSaga(): SagaIterator {
  try {
    const eventProperties: FetchEventPropertySelectorSuccessPayload = yield call(
      api.fetchEventPropertySelector,
    )
    yield put(actions.fetchSuccess(eventProperties))
  } catch (error) {
    console.error('Fetch event properties failed', error)
    yield put(actions.fetchFailure(formatErrorMessage(error)))
  }
}

function* fetchOptionsSaga(action: { payload: string }): SagaIterator {
  try {
    const properties = yield select(getProperties)
    const option: PropertySectorItem = properties.find(
      (o: PropertySectorItem) => o.key === action.payload,
    )
    if (option) {
      const eventOptions = yield call(
        api.fetchOptions,
        option.event_property_id,
      )
      yield put(
        actions.fetchOptionsSuccess({
          key: action.payload,
          data: eventOptions,
        }),
      )
    }
  } catch (error) {
    console.error('Fetch event property selector options failed', error)
    yield put(actions.fetchOptionsFailure(formatErrorMessage(error)))
  }
}

function* watchEventPropertySelectorSaga(): SagaIterator {
  yield takeEvery(actions.fetch, fetchEventPropertySelectorSaga)
}

function* watchFetchOptionsSaga(): SagaIterator {
  yield takeEvery(actions.fetchOptions, fetchOptionsSaga)
}

export default function* watchEventPropertySelector(): SagaIterator {
  yield all([fork(watchEventPropertySelectorSaga), fork(watchFetchOptionsSaga)])
}
